import { useTranslation } from 'react-i18next';
import useGlobalVariables from '../../hooks/useGlobalVariables';
import { CSSTransition } from 'react-transition-group';
import { useRef } from 'react';

export default function CodeSubmission({ code }: { code: string }) {
	const { t } = useTranslation();
	const ref = useRef<HTMLDivElement>(null);
	const {
		productSkus,
	} = useGlobalVariables();

	const product = Object.entries(productSkus).find(([, skus]) => skus.includes(code))?.[0] || '';

	return <CSSTransition in={true} nodeRef={ref} timeout={600} appear>
		<div className="code-submission" ref={ref}>
			<div className="panel">
				<div className="subtitle">{t('barcode_scanner.code_submission.subtitle')}</div>
				{product && <div className="subtitle">{product}</div>}
				<div className="title">{t('barcode_scanner.code_submission.title')}</div>
			</div>
		</div>
	</CSSTransition>;
}