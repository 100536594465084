import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_LANG, STORE_NAME } from '../Constants';
import { authPlayfab } from './playfab';

export enum LoginMethods {
	CUSTOM_ID = 'custom_id',
}

export const incrementErrors = createAction('env/error');

function getLSLang() {
	const lang = localStorage.getItem('lang');
	if (lang) {
		return lang;
	}
	return DEFAULT_LANG;
}

function setLSLang(lang: string) {
	localStorage.setItem('lang', lang);
}

export const APP_STATES = {
	SPLASH_SCREEN: 'splash_screen',
	AUTH: 'auth',
	LOGIN_WITH_TICKET: 'login_with_ticket',
	RUNNING: 'running',
} as const;
export type AppState = typeof APP_STATES[keyof typeof APP_STATES];

const initialState = {
	Lang: getLSLang(),
	Errors: [] as any[],
	isLoggingIn: false,
	loggingMethod: null as LoginMethods,
	appState: APP_STATES.SPLASH_SCREEN as AppState,
	accessToken: null as string,
};

const env = createSlice({
	name: 'env',
	reducers: {
		setIsLoggingIn: (state, action:PayloadAction<boolean>) => {
			state.isLoggingIn = action.payload;
		},
		setLoggingMethod: (state, action:PayloadAction<LoginMethods>) => { state.loggingMethod = action.payload; },
		setLang: (state, action:PayloadAction<string>) => {
			state.Lang = action.payload;
			setLSLang(action.payload);
		},
		setAppState: (state, action:PayloadAction<AppState>) => {
			state.appState = action.payload;
		},
		setAccessToken: (state, action:PayloadAction<string>) => {
			state.accessToken = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(incrementErrors, (state) => {
			state.Errors = [
				...state.Errors,
				Date.now(),
			];
		});
		builder.addCase(authPlayfab.actions.fulfilled, (state) => {
			state.isLoggingIn = false;
		});
		builder.addCase(authPlayfab.actions.rejected, (state) => {
			state.isLoggingIn = false;
		});
	},
	initialState,
});

export default env;

export const { setLang, setIsLoggingIn, setLoggingMethod, setAppState, setAccessToken } = env.actions;
