import { useEffect, useRef } from 'react';

const observer = new IntersectionObserver((entries) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting) {
			const video = entry.target as HTMLVideoElement;
			video.play().catch(() => {});
		} else {
			const video = entry.target as HTMLVideoElement;
			video.pause();
		}
	});
});

export default function MaskedVideo({ mp4, webm, paused = false }: { mp4: string, webm: string, paused?: boolean }) {
	const videoRef = useRef<HTMLVideoElement>(null);
	const once = useRef(false);

	useEffect(() => {
		observer.observe(videoRef.current);

		return () => {
			if (videoRef.current) {
				observer.unobserve(videoRef.current);
			}
		};
	}, []);

	function onCanPlayThrough() {
		if (videoRef.current && !once.current) {
			videoRef.current.currentTime = Math.random() * videoRef.current.duration;
			once.current = true;
		}
	}

	const props:Record<string, boolean> = {};

	if (!paused) {
		props.autoPlay = true;
	}

	return <>
		<video ref={videoRef} muted playsInline {...props} loop onCanPlayThrough={onCanPlayThrough}>
			<source src={mp4} type="video/mp4;codecs=hvc1" />
			<source src={webm} type="video/webm" />
		</video>
	</>;
}