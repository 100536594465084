import { CSSProperties, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import useRegion from '../../hooks/useRegion';
import Button from '../ui/Button';
import { SubRegion } from '../../redux/globalVariables';
import { useAppDispatch } from '../../redux/config/store';
import { writePlayerEvent } from '../../redux/playfab';
import { PLAYER_EVENTS } from '../../Constants';
import { useTranslation } from 'react-i18next';

export default function SubRegionSelect() {
	const {
		currentRegion,
	} = useRegion();

	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	
	const dispatch = useAppDispatch();

	const ref = useRef<HTMLDivElement>(null);

	async function onSelectSubRegion(id:string) {
		setLoading(true);
		await dispatch(writePlayerEvent({
			EventName: PLAYER_EVENTS.PLAYER_SET_SUBREGION,
			Body: {
				subregion: id,
			},
		}));
	}

	if (loading) return <div className="sub-region-select">Loading...</div>;

	return <CSSTransition nodeRef={ref} in={Boolean(currentRegion)} timeout={2400} appear mountOnEnter unmountOnExit>
		<div ref={ref} className='sub-region-select'>
			<div className="panel top">
				<div className="title">{t('subregion_select.title')}</div>

				{Object.entries(currentRegion.subRegions).map(([id, subregion]:[string, SubRegion], index) => {
					return <div className="panel sub-region" style={{ '--i': index + 1 } as CSSProperties} key={id}>
						<div className="title">{subregion.name}</div>
						<div className="text">{subregion.description}</div>
						<Button className="full-width thin" label={t('subregion_select.button_label')} onClick={() => onSelectSubRegion(id)} />
					</div>;
				})}
			</div>
		</div>
	</CSSTransition>;
}