import { useAppSelector } from '../redux/config/store';
import useHeartbeat from './useHeartbeat';
import useMissions from './useMissions';
import usePlayFab from './usePlayFab';
import usePlayerData from './usePlayerData';
import useRealtime from './useRealtime';
import useScreenTime from './useScreenTime';
import useSurveys from './useSurveys';
import useGAM from './useGAM';
import { useEffect } from 'react';
import useLogin from './useLogin';
import useAppState from './useAppState';
import { APP_STATES } from '../redux/env';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRouting, getTaxonomy } from '../AppRoutes';
import useTelemetry, { TelemetryEvents } from './useTelemetry';
import useAutoFirstMission from './useAutoFirstMission';
import useStats from './useStats';
import useGlobalVariables from './useGlobalVariables';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export default function useApp() {
	const {
		playerId,
		AvatarUrl,
		isLoaded,
	} = usePlayFab();
	const { auth, isLoggingIn } = useLogin();

	const { appState, setAppState } = useAppState();

	usePlayerData();
	useMissions();
	useRealtime();
	useSurveys();
	useScreenTime();
	useHeartbeat();
	useAutoFirstMission();

	const { token, inProgress, acquiringSilentToken } = useGAM(true);

	const lang = useAppSelector((state) => state.env.Lang);

	const navigate = useNavigate();
	const location = useLocation();

	const { userType } = useStats();
	const isDev = userType === 99;

	const {
		logEvent,
	} = useTelemetry();

	useEffect(() => {
		logEvent(TelemetryEvents.appStateChanged({ appState }));
	 }, [appState, logEvent]);

	useEffect(() => {
		let taxonomy = appState !== APP_STATES.RUNNING ? 'splashscreen' : 'homepage';
		if (location.pathname !== '/') {
			taxonomy = getTaxonomy(location.pathname);
		}
		window.tccc_analytics.site_country = taxonomy;
		logEvent(TelemetryEvents.pageView({ location: taxonomy }));
	}, [location, logEvent, appState]);

	useEffect(() => {
		if (inProgress !== 'none' || acquiringSilentToken) return;

		switch (appState) {
			case APP_STATES.SPLASH_SCREEN:
				if (!token) {
					setAppState(APP_STATES.AUTH);
				} else if (token) {
					if (playerId) {
						setAppState(APP_STATES.RUNNING);
					} else {
						setAppState(APP_STATES.LOGIN_WITH_TICKET);
					}
				}
				break;
			case APP_STATES.AUTH:
				if (playerId && AvatarUrl) {
					setAppState(APP_STATES.RUNNING);
				} else if (token) {
					setAppState(APP_STATES.LOGIN_WITH_TICKET);
				}
				break;
			case APP_STATES.LOGIN_WITH_TICKET:
				if (!playerId && !isLoggingIn) {
					auth(token);
				} else if (playerId && isLoaded) {
					setAppState(APP_STATES.RUNNING);
					
					if (!AvatarUrl) {
						navigate(AppRouting.avatarSelection.index);
					}
				}
				break;
			case APP_STATES.RUNNING:
				if (!playerId) {
					setAppState(APP_STATES.SPLASH_SCREEN);
				}
				break;
		}
	}, [appState, inProgress, acquiringSilentToken, token, playerId, AvatarUrl, isLoaded, isLoggingIn, auth, setAppState]);

	return {
		isDev,
		isSafari,
		lang,
		appState,
		inProgress,
		acquiringSilentToken,
	};
}