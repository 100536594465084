import { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { setAccessToken } from '../redux/env';
import { getScope, getLoginRequest, getAppParams, getCreateAccountRequest } from '../regionManagement';
import { getQueryParameterByName } from '../utils/urlUtils';

export default function useGAM(manageLogin = false) {
	const token = useAppSelector(state => state.env.accessToken);
	const [acquiringSilentToken, setAcquiringSilentToken] = useState(false);
	const { instance, inProgress, accounts } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const dispatch = useAppDispatch();

	const { authority, authorityUrl } = getAppParams();

	const arr = accounts.filter(a => a.environment.includes(authority) &&
	// a.idTokenClaims?.acr?.toLowerCase() === props.policy &&
	(a.idTokenClaims?.iss ?? false) === authorityUrl);

	const account = arr[0] || accounts[0];

	useEffect(() => {
		if (!manageLogin) return;

		const idTokenHint = getQueryParameterByName('id_token_hint');
		const prompt = getQueryParameterByName('prompt');
		const isMagicLink = getQueryParameterByName('is_magic_link');

		if (idTokenHint && inProgress === 'none') {
			instance.loginRedirect({
				scopes: [getScope()],
				extraQueryParameters: {
					...getLoginRequest().extraQueryParameters,
					'id_token_hint': idTokenHint,
					prompt,
					isMagicLink,
				},
			});
		}
	}, [instance, inProgress, manageLogin]);

	useEffect(() => {
		if (!manageLogin) return;

		if (account && inProgress === 'none') {
			setAcquiringSilentToken(true);
			instance.acquireTokenSilent({
				scopes: [getScope()],
				account: account,
			}).then((response) => {
				dispatch(setAccessToken(response.accessToken || response.idToken));
				setAcquiringSilentToken(false);
			}, (e) => {
				console.error('Failed to acquire token', e);
				instance.logout();
			});
		}
	}, [account, instance, inProgress,  manageLogin]);
	
	return {
		isAuthenticated,
		instance,
		inProgress,
		token,
		acquiringSilentToken,
		create: () => instance.loginRedirect(getCreateAccountRequest()),
		login: () => instance.loginRedirect(getLoginRequest()),
		logout: () => instance.logout(getLoginRequest()),
	};
}