function BackIcon() {
	return (
		<svg className="back-icon" xmlns="http://www.w3.org/2000/svg" fill="none" width="24" height="24" viewBox="0 0 24 24">
			<path
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M19 12H5m0 0l6 6m-6-6l6-6"
			></path>
		</svg>
	);
}

export default BackIcon;
