import { useTranslation } from 'react-i18next';
import CloseIcon from '../icons/CloseIcon';
import Button from './Button';
import { raw } from '../../utils/textUtils';
import { CSSTransition } from 'react-transition-group';
import { useRef } from 'react';

export default function DailyScanRewards({ data, onClose }:{ data: { product: string, Rewards: SendMissionInputResponse['Rewards'] }, onClose: () => void }) {
	const { t } = useTranslation();
	const ref = useRef<HTMLDivElement>(null);
	const xp = data.Rewards.PlayerStatistics.find(stat => stat.StatisticName.toLowerCase().includes('xp'))?.Value || 0;

	return <CSSTransition nodeRef={ref} in={true} timeout={1200} appear>
		<div ref={ref} className="scan-rewards">
			<div className="panel">
				<CloseIcon onClick={onClose} />
				<div className="subtitle">{t('daily_scan_rewards.title')}</div>
				<div className="title">{data.product}</div>
				<div className="xp-bar" {...raw(t('daily_scan_rewards.earned', { xp }))} />
				<div className="text">{t('daily_scan_rewards.text')}</div>
				<div className="btn-ctn"><Button label={t('daily_scan_rewards.button_label')} className='thin full-width' onClick={onClose} /></div>
			</div>
		</div>
	</CSSTransition>;
}