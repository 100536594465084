import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import useMissions from './useMissions';
import useStats from './useStats';
import usePlayerData from './usePlayerData';
import { updatePlayerData } from '../redux/playerData';

export default function useAutoFirstMission() {
	const missionsLoaded = useAppSelector(state => state.missions.isLoaded);
	const { isPlayerDataLoaded, playerData } = usePlayerData();
	const { hasLoaded: statsLoaded } = useStats();
	const missions = useMissions();
	const completedMissions = missions.filter(m => m?.PlayerStatus?.IsComplete).length;
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (statsLoaded && missionsLoaded && isPlayerDataLoaded) {
			if (!playerData.activeMissionId && completedMissions === 0) {
				const firstMission = missions[0];

				if (firstMission) {
					dispatch(updatePlayerData({ activeMissionId: firstMission.itemId }));
				}
			}
		}
	}, []);
}