import { useTranslation } from 'react-i18next';
import useGlobalVariables from '../../hooks/useGlobalVariables';
import useMissions from '../../hooks/useMissions';
import CloseIcon from '../icons/CloseIcon';
import Button from './Button';
import { raw } from '../../utils/textUtils';
import { CSSTransition } from 'react-transition-group';
import { useRef } from 'react';

function getXpFromRewards(rewards:{
	dataType: string;
	dataKey: string;
	dataVal: string | number;
}[]) {
	return rewards.reduce((acc, reward) => {
		if (reward.dataType === 'stat_change' && reward.dataKey.toLowerCase().includes('xp')) {
			return parseInt(reward.dataVal as string) + acc;
		}

		return acc;
	}, 0);
}

export default function ScanRewards({ code, missionData, onClose }:{ code: string, missionData: SendMissionInputResponse, onClose: () => void }) {
	const { t } = useTranslation();
	const ref = useRef<HTMLDivElement>(null);
	const missions = useMissions();
	const activeMission = missions.find((m) => m.itemId === missionData.Instance.ItemId);

	const objective = activeMission?.objectives.find((o) => o.id === missionData.Objective.id);

	let xp = activeMission.PlayerStatus?.IsComplete ? getXpFromRewards(activeMission?.rewards) : 0;
	
	if (objective?.rewards) {
		xp = getXpFromRewards(objective.rewards);
	}

	if (missionData.PlayerStatus.IsComplete && missionData.Rewards.Statistics) {
		xp = 0;
		xp += Object.entries(missionData.Rewards.Statistics).reduce((acc, [stat, value]) => {
			if (stat.toLowerCase().includes('xp')) {
				return acc + value;
			}
			return acc;
		}, 0);
	}
	
	const {
		productSkus,
	} = useGlobalVariables();

	const product = Object.entries(productSkus).find(([, skus]) => skus.includes(code))?.[0] || '';

	return <CSSTransition nodeRef={ref} in={true} timeout={1200} appear>
		<div ref={ref} className="scan-rewards">
			<div className="panel">
				<CloseIcon onClick={onClose} />
				<div className="subtitle">{t('scan_rewards.subtitle')}</div>
				<div className="title">{product}</div>
				<div className="xp-bar" {...raw(t('scan_rewards.earned', { xp }))} />
				{activeMission?.PlayerStatus?.IsComplete && <>
					<div className="text">{t('scan_rewards.completed_text')}</div>
					<div className="btn-ctn"><Button label={t('scan_rewards.completed_button_label')} className='thin full-width' onClick={onClose} /></div>
				</>}
				{!activeMission?.PlayerStatus?.IsComplete && <>
					<div className="text">{t('scan_rewards.continue_text')}</div>
					<div className="btn-ctn"><Button label={t('scan_rewards.continue_button_label')} className='thin full-width' onClick={onClose} /></div>
				</>}
			</div>
		</div>
	</CSSTransition>;
}