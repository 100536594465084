import { useEffect } from 'react';
import { useAppDispatch } from '../redux/config/store';
import { writePlayerEvent } from '../redux/playfab';
import usePlayer from './usePlayer';

export default function useHeartbeat() {
	const { playerId } = usePlayer();
	const dispatch = useAppDispatch();

	useEffect(() => {
		let interval:number;

		if (playerId) {
		}

		const onVisibilityChange = () => {
			if (document.visibilityState === 'visible') {
				interval = window.setInterval(() => {
					if (playerId) {
						dispatch(writePlayerEvent({
							EventName: 'client_heartbeat',
							Body: {
								location: window.location.pathname,
							},
						}));
					}
				}, 60 * 1000);
			} else {
				clearInterval(interval);
			}
		};

		document.addEventListener('visibilitychange', onVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', onVisibilityChange);
			clearInterval(interval);
		};
	}, [playerId]);
}