import { CDN_BASE } from '../../Constants';
import MaskedVideo from './MaskedVideo';

export default function AnimatedBottleCap({ paused = false }: { paused?: boolean }) {
	return <div className="animated-bottle-cap">
		<MaskedVideo
			paused={paused}
			mp4={CDN_BASE + 'videos/transparent/CAP_LKWD_800x800.mp4'}
			webm={CDN_BASE + 'videos/transparent/CAP_LKWD_800x800.webm'}
		/>
	</div>;
}