import { IPlayerStatistics } from '../redux/statistics';
import usePlayer from './usePlayer';
import useStats from './useStats';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { getLeaderboardAroundPlayer } from '../redux/leaderBoardAroundPlayer';
import { useEffect } from 'react';
import useLeaderboard from './useLeaderboard';
import { LEADERBOARD_LENGTH } from '../Constants';

export default function useLeaderboardAroundPlayer(
	statisticName: keyof IPlayerStatistics = 'xp',
) {
	const { playerId, DisplayName, AvatarUrl } = usePlayer();
	const { [statisticName]: stat } = useStats();
	const { leaderboard } = useLeaderboard(statisticName);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!playerId) return;

		dispatch(getLeaderboardAroundPlayer({ StatisticName: statisticName }));
	}, [playerId]);
	
	const {
		[statisticName]: xpLeaderboard,
	} = useAppSelector((state) => state.leaderBoardAroundPlayer);

	const playerEntry = { ...(xpLeaderboard?.find?.(e => e.PlayFabId === playerId) || {
		Position: 999,
		PlayFabId: playerId,
		Profile: {
			DisplayName,
			AvatarUrl,
		},
		DisplayName,
		StatValue: stat,
	}) };

	if (playerEntry.Position <= LEADERBOARD_LENGTH) {
		playerEntry.Position = leaderboard.find((e) => e.PlayFabId === playerId)?.Position || playerEntry.Position;
	}

	return {
		updateLeaderboardAroundPlayer: () => dispatch(getLeaderboardAroundPlayer({ StatisticName: statisticName })),
		leaderBoardAroundPlayer: [playerEntry],
	};
}
