import { useRef, useState } from 'react';
import Button from '../../ui/Button';
import { CDN_BASE } from '../../../Constants';
import { useTranslation } from 'react-i18next';
import useTelemetry, { TelemetryEvents } from '../../../hooks/useTelemetry';
import { CSSTransition } from 'react-transition-group';


export default function AgeGate({ setSeen }: { setSeen: () => void }) {
	const [showTerms, setShowTerms] = useState(false);
	const { t } = useTranslation();
	const { logEvent } = useTelemetry();
	const ref = useRef<HTMLDivElement>(null);

	function onAccept() {
		logEvent(TelemetryEvents.clickAcceptAgeGate({}));
		setSeen();
	}

	function onReject() {
		logEvent(TelemetryEvents.clickRejectAgeGate({}));
		setShowTerms(true);
	}

	return <CSSTransition nodeRef={ref} in={true} timeout={1200} appear mountOnEnter unmountOnExit>
		<div ref={ref} className="age-gate">
			<div className="logo">
				<img src={CDN_BASE + 'tccc-mission-universe-logo.svg'} width="271" height="111" alt="" />
			</div>
			{!showTerms && <div className="panel">
				<div className="title">{t('age_gate.intro.title')}</div>
				<Button className="full-width thin" label={t('age_gate.intro.label_yes')} onClick={onAccept} />
				<Button className="full-width thin secondary" label={t('age_gate.intro.label_no')} onClick={onReject} />
			</div>}
			{showTerms && <div className="panel">
				<div className="title">{t('age_gate.error.title')}</div>
				<div className="text">{t('age_gate.error.text')}</div>
				<Button className="full-width thin" label={t('age_gate.error.button_label')} url={t('common.terms_conditions_link')} />
			</div>}
		</div>
	</CSSTransition>;
}