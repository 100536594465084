import { memo, useRef } from 'react';
import Menu from './components/common/Menu';
import Header from './components/common/Header';
import PopupPoll from './components/ui/PopupPoll';
import useApp from './hooks/useApp';
import AppRoutes, { AuthRoutes } from './AppRoutes';
import { APP_STATES } from './redux/env';
import Polls from './components/routes/Polls';
import useLogin from './hooks/useLogin';
import { CDN_BASE } from './Constants';
import { useTranslation } from 'react-i18next';
import { raw } from './utils/textUtils';
import RealtimeVisualizer from './components/RealtimeVisualizer';
import { CSSTransition } from 'react-transition-group';
import SubRegionSelect from './components/routes/SubRegionSelect';
import useRegion from './hooks/useRegion';

function App() {
	const { isSafari, appState, inProgress, isDev, acquiringSilentToken } = useApp();
	const { currentRegion, currentSubRegion } = useRegion();
	const { isLoggingIn } = useLogin();
	const { t, i18n } = useTranslation();
	const splashRef = useRef<HTMLDivElement>(null);

	if (appState === APP_STATES.SPLASH_SCREEN || isLoggingIn || appState === APP_STATES.LOGIN_WITH_TICKET || inProgress !== 'none' || acquiringSilentToken) {
		return <CSSTransition nodeRef={splashRef} in={true} timeout={1200} appear mountOnEnter unmountOnExit>
			<div ref={splashRef} className="splash">
				<div className="logo">
					<img src={CDN_BASE + 'tccc-mission-universe-logo.svg'} width="271" height="111" alt="" />
				</div>
				{isLoggingIn || inProgress !== 'none' && <div className="loading" {...raw(t('splash_screen.loading'))} />}
				{inProgress !== 'none' && <div className="small">Status: {inProgress}</div>}
			</div>
		</CSSTransition>;
	}

	if (appState === APP_STATES.AUTH) {
		return <AuthRoutes />;
	}

	const hasSubRegions = currentRegion && currentRegion.subRegions && Object.values(currentRegion.subRegions).length > 1;
	if (hasSubRegions && !currentSubRegion) {
		return <SubRegionSelect />;
	}

	return (
		<div className={`${i18n.language.split('-')[0]} ${i18n.language} ${(isSafari ? ' safari' : '')}`}>
			<Menu />
			<Header />
			<PopupPoll />
			<AppRoutes />
			<Polls />
			{isDev && <RealtimeVisualizer />}
		</div>
	);
}

export default memo(App);
