import { PropsWithChildren } from 'react';

function GiftCardIcon(props:PropsWithChildren) {
	return (
		<div className="gift-card-icon">
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="50" height="42" viewBox="0 0 50 42">
				<g fill="currentColor" clipPath="url(#gift-card-a)">
					<path d="M2.992 16.634v10.84l-1.397-.567a2.562 2.562 0 01-1.407-3.333l2.804-6.94zm24.81-10.048H6.708L8.723 1.6A2.552 2.552 0 0112.048.188l15.754 6.398zm15.49 28.829L41.277 40.4a2.552 2.552 0 01-3.325 1.411l-15.754-6.397h21.094zm6.52-16.989l-2.804 6.94v-10.84l1.397.567a2.562 2.562 0 011.407 3.333zM16.051 7.76v8.226c-1.115-1.68-2.789-3.697-4.524-3.96-.854-.13-1.653.165-2.311.849-.506.526-1.27 1.605-.71 2.926.349.822 1.135 1.52 2.16 2.068H4.468v-7.758A2.348 2.348 0 016.814 7.76h9.237zm29.48 2.351v7.758H22.843c1.023-.549 1.81-1.246 2.16-2.068.56-1.321-.204-2.4-.71-2.926-.659-.685-1.459-.978-2.312-.849-1.735.263-3.409 2.28-4.524 3.96V7.76h25.73a2.348 2.348 0 012.345 2.351zm0 9.167v12.61a2.348 2.348 0 01-2.345 2.352H6.814a2.348 2.348 0 01-2.345-2.351V19.278h9.976c-1.622 1.285-3.787 3.507-4.7 6.71a.705.705 0 00.677.898.703.703 0 00.675-.511c.948-3.327 3.51-5.518 4.955-6.53V34.24h1.405V19.846c1.444 1.01 4.006 3.202 4.954 6.528a.703.703 0 101.352-.387c-.913-3.202-3.077-5.424-4.7-6.71h26.468v.001z"></path>
					<path d="M23.709 15.25c-.514 1.212-3.032 2.295-5.725 2.559.274-.464.63-1.03 1.038-1.604 1.192-1.667 2.317-2.657 3.169-2.786.055-.008.117-.014.183-.014.241 0 .555.081.907.448.635.66.548 1.114.428 1.397zm-8.184 2.559c-2.693-.264-5.212-1.347-5.725-2.559-.121-.284-.207-.738.427-1.397.353-.367.666-.448.907-.448.067 0 .129.006.183.014.85.128 1.972 1.113 3.159 2.772.412.577.771 1.15 1.049 1.618z"></path>
				</g>
				<defs>
					<clipPath id="gift-card-a">
						<path fill="var(--stroke, #fff)" d="M0 0h50v42H0z"></path>
					</clipPath>
				</defs>
			</svg>
			{props.children}
		</div>
	);
}

export default GiftCardIcon;
