import React, { useEffect, useRef, useState } from 'react';
import useRealtimeConnection from '../../api/react/hooks/useRealtimeConnection';
import { API_CONFIG } from '../../Constants';
import usePlayer from '../../hooks/usePlayer';
import { ANY_EVENT } from '../../api/Realtime';
import './index.scss';
import RealtimeEvent from './components/RealtimeEvent';
import { guid } from '../../utils/guid';

const LS_KEY = 'realtime-visualizer';

export const inAppMessages = (() => {
	const listeners = [];
	return {
		add: (cb) => {
			listeners.push(cb);
		},
		remove: (cb) => {
			listeners.splice(listeners.indexOf(cb), 1);
		},
		trigger: (eventName, data) => {
			const d = {
				EventId: guid(),
				EventName: eventName,
				Timestamp: new Date().toISOString(),
				data,
				...data,
			};
			Object.values(listeners).forEach(l => l(d as RealtimeEvent));
		},
	};
})();

export default function RealtimeVisualizer() {
	const {
		playerId,
		SessionTicket,
	} = usePlayer();

	const [visible, setVisible] = useState(false);

	const messagesRef = useRef<HTMLPreElement>(null);

	const [events, setEvents] = useState<RealtimeEvent[]>(JSON.parse(localStorage.getItem(LS_KEY + '_' + playerId) || '[]'));

	useEffect(() => {
		localStorage.setItem(LS_KEY + '_' + playerId, JSON.stringify(events));
		requestAnimationFrame(() => {
			if (messagesRef.current) {
				messagesRef.current.scrollTo({
					behavior: 'smooth',
					top: messagesRef.current.scrollHeight,
				});
			}
		});
	}, [events]);

	function clearEvents(e) {
		setEvents([]);
		localStorage.setItem(LS_KEY + '_' + playerId, JSON.stringify([]));
		e.preventDefault();
		e.stopPropagation();
	}
	
	const {
		addListener,
		removeListener,
	} = useRealtimeConnection(API_CONFIG.realtime.apiUrl, API_CONFIG.playfab.appId, playerId, SessionTicket);

	useEffect(() => {
		const onEvent = (m => {
			setEvents(prev => ([ ...prev, m ]));
		});
		addListener(ANY_EVENT, onEvent);

		inAppMessages.add(onEvent);

		onEvent({ EventName: 'new_session', EventId: guid(), Payload: { test: 'test' }, Timestamp: new Date().toISOString() });

		return () => {
			removeListener(ANY_EVENT, onEvent);
			inAppMessages.remove(onEvent);
		};
	}, [addListener, removeListener]);
	
	return (
		<div className={`realtime-visualizer ${visible ? 'visible' : ''}`} onClick={() => setVisible(v => !v)}>
			<div className="top">
				<div className="title">Realtime <small>{playerId}</small></div>
				<div className="clear" onClick={clearEvents}>🗑️</div>
			</div>
			{events && events.length > 0 && <pre className="messages" ref={messagesRef}>
				{events.map((e) => (
					<RealtimeEvent e={e} key={e.EventId} />
				))}
			</pre>}
			{!events || events.length === 0 && <div className="no-events">No events yet</div>}
		</div>
	);
}