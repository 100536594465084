import { PayloadAction } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { setIsLoggingIn } from '../redux/env';
import { authPlayfab } from '../redux/playfab';

export default function useLogin() {
	const dispatch = useAppDispatch();
	const { isLoggingIn } = useAppSelector((state) => state.env);

	async function auth(token:string) {
		dispatch(setIsLoggingIn(true));
		return dispatch(authPlayfab({ token })).then((resp:PayloadAction<GenericApiCallResponse<AuthResponse>>) => {
			const playFabId = resp.payload?.data?.LoginResult?.PlayFabId;
			//eslint-disable-next-line no-console
			console.log(playFabId ? 'PlayFabId: ' + playFabId : 'Logging Error');

			return resp;
		});
	}

	return {
		auth,
		isLoggingIn,
	};
}