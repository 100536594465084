import { Routes, Route, Navigate } from 'react-router-dom';
import AvatarSelection from './components/routes/AvatarSelection';
import Home from './components/routes/Home';
import Rewards from './components/routes/Rewards';
import WorldMap from './components/routes/WorldMap';
import You from './components/routes/You';
import Missions from './components/routes/Missions';
import ProfileTab from './components/routes/You/ProfileTab';
import LeaderboardTab from './components/routes/You/LeaderboardTab';
import AuthHub from './components/routes/Auth/AuthHub';
import BadgesTab from './components/routes/You/BadgesTab';

const ROOT = '/';
const MISSIONS = 'missions';
const REWARDS = 'rewards';
const YOU = 'you';
const LEADERBOARD = 'leaderboard';
const BADGES = 'badges';
const WORLD_MAP = 'world-map';
const AVATAR_SELECTION = 'avatar-selection';

export const AppRouting = {
	root: '/',
	missions: {
		index: ROOT + MISSIONS,
		type: (type = ':type') => ROOT + MISSIONS + '/' + type,
	},
	rewards: {
		index: ROOT + REWARDS,
	},
	you: {
		index: ROOT + YOU,
		profile: ROOT + YOU,
		leaderboard: ROOT + YOU + '/' + LEADERBOARD,
		badges: ROOT + YOU + '/' + BADGES,
	},
	worldMap: {
		index: ROOT + WORLD_MAP,
	},
	avatarSelection: {
		index: ROOT + AVATAR_SELECTION,
	},
};

export function getTaxonomy(pathname:string) {
	switch (pathname) {
		case AppRouting.missions.index: return 'missions';
		case AppRouting.rewards.index: return 'rewards';
		case AppRouting.you.index: return 'profile';
		case AppRouting.you.profile: return 'profile';
		case AppRouting.you.leaderboard: return 'leaderboard';
		case AppRouting.you.badges: return 'badges';
		case AppRouting.worldMap.index: return 'world-map';
		case AppRouting.avatarSelection.index: return 'avatar-selection';
		default: return pathname;
	}
}

export default function AppRoutes() {
	return (
		<Routes>
			<Route path={ROOT}>
				<Route index element={<Home />} />
				<Route path={MISSIONS}>
					<Route index element={<Missions />} />
					<Route path=":type" element={<Missions />} />
				</Route>
				<Route path={REWARDS} element={<Rewards />} />
				<Route path={YOU} element={<You />}>
					<Route index element={<ProfileTab />} />
					<Route path={LEADERBOARD} element={<LeaderboardTab />} />
					<Route path={BADGES} element={<BadgesTab />} />
				</Route>
				<Route path={WORLD_MAP} element={<WorldMap />} />
				<Route path={AVATAR_SELECTION} element={<AvatarSelection />} />
				<Route
					path="*"
					element={<Navigate to={ROOT} replace />}
				/>
			</Route>
		</Routes>
	);
}

export function AuthRoutes() {
	return (
		<AuthHub />
	);
}