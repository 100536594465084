function BellIcon() {
	return (
		<svg className="bell-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 24" width="22" height="24">
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M10.883 24c1.913 0 3.464-1.521 3.464-3.396H7.418c0 1.875 1.551 3.395 3.465 3.395zm10.647-6.31l-2.523-2.79a2.261 2.261 0 01-.588-1.517V9.422c0-3.662-2.644-6.85-6.226-7.481v-.63a1.31 1.31 0 00-2.621 0v.627c-3.537.608-6.226 3.633-6.226 7.275v4.17c0 .559-.209 1.098-.587 1.517L.235 17.69c-.53.585-.106 1.507.691 1.507H20.84c.797 0 1.22-.922.692-1.506z"
				clipRule="evenodd"
			></path>
		</svg>
	);
}

export default BellIcon;