import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import useInventory from '../../hooks/useInventory';
import { ITEM_CLASSES } from '../../Constants';
import Bubble, { BUBBLE_THEMES } from '../ui/Bubble';
import Button from '../ui/Button';
import { AppRouting } from '../../AppRoutes';
import CouponBG from '../ui/CouponBG';
import { createPortal } from 'react-dom';
import RedeemPanel from '../ui/RedeemPanel';
import { useTranslation } from 'react-i18next';
import { Translation } from '../utils/Translation';
import useTelemetry, { TelemetryEvents } from '../../hooks/useTelemetry';
import { raw } from '../../utils/textUtils';

const SPACING = 16;

function Reward({ item, setSelectedReward }) {
	const { t } = useTranslation();

	const displayName = <Translation id={item?.itemId + '/playfab/DisplayName'} fallback={item?.playfab.DisplayName} />;
	const description = <Translation asRaw nl2br id={item?.itemId + '/playfab/Description'} fallback={item?.playfab.Description} />;

	const { logEvent } = useTelemetry();

	const onClick = () => {
		logEvent(TelemetryEvents.clickRedeemReward({ itemId: item.itemId, itemInstanceId: item.playfab.ItemInstanceId }));
		setSelectedReward(item);
	};

	return (
		<>
			<div className="bubble-container">
				<Bubble theme={BUBBLE_THEMES.RED} />
				{item.playfab.ItemImageUrl && <img className={item.type.title.toLowerCase()} src={item.playfab.ItemImageUrl} alt="" />}
			</div>
			<div className="content">
				<div className="top">
					<div className='type reward'>{t('rewards.listing.type')}</div>
					<div className="title">{displayName}</div>
				</div>
				<div className="bottom">
					<div className="details">
						<strong>{t('rewards.listing.details')}</strong><br/>
						{description}
					</div>
					<div className="subtitle">{t('rewards.listing.subtitle')}</div>
					<Button className='thin full-width' label={t('rewards.listing.button_label')} onClick={onClick} />
				</div>
				<CouponBG />
			</div>
		</>
	);
}

export default function Rewards() {
	const { t } = useTranslation();
	const [space, setSpace] = useState(SPACING);

	const rewards = useInventory([ITEM_CLASSES.GIFT_CARD]);
	const [selectedReward, setSelectedReward] = useState<IXRInventoryItemParsedData>(null);

	useEffect(() => {
		const resize = () => {
			setSpace(SPACING * window.innerWidth / 395);
		};

		window.addEventListener('resize', resize);
		resize();

		return () => window.removeEventListener('resize', resize);
	}, []);

	return <div className="rewards-page">
		<div className="rewards-list">
			<Swiper
				spaceBetween={space}
				slidesPerView={1}
			>
				{rewards.map((reward) => {
					return (
						<SwiperSlide key={reward.playfab.ItemInstanceId} className='reward'>
							<Reward item={reward} setSelectedReward={setSelectedReward} />
						</SwiperSlide>
					);
				})}
				{rewards.length === 0 && <SwiperSlide className='reward empty'>
					<div className="bubble-container">
						<Bubble theme={BUBBLE_THEMES.RED} />
					</div>
					<div className="content">
						<div className="top">
							<div className='type reward'>{t('rewards.empty.type')}</div>
							<div className="title">{t('rewards.empty.title')}</div>
						</div>
						<div className="bottom">
							<div className="details" {...raw(t('rewards.empty.details'))} />
							<Button className='thin full-width' to={AppRouting.missions.index} label={t('rewards.empty.button_label')} />
						</div>
						<CouponBG />
					</div>
				</SwiperSlide>}
			</Swiper>
		</div>
		{selectedReward && createPortal(<RedeemPanel key={selectedReward?.playfab?.ItemInstanceId} item={selectedReward} onClose={() => setSelectedReward(null)} />, document.body)}
	</div>;
}