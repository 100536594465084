import slugify from 'slugify';
import { CDN_BASE } from '../../Constants';
import MaskedVideo from './MaskedVideo';

export const BUBBLE_THEMES = {
	BLUE: 'Blue',
	GREEN: 'Green',
	NEUTRAL: 'neutral',
	WHITE: 'white',
	RED: 'Red',
	TREASURE_HUNT: 'TreasureHunt',
} as const;
export type BubbleTheme = typeof BUBBLE_THEMES[keyof typeof BUBBLE_THEMES];

type BubbleProps = {
	theme?: BubbleTheme;
};

export default function Bubble({ theme = BUBBLE_THEMES.NEUTRAL }:BubbleProps) {
	return <div className={`bubble ${slugify(theme, { lower: true })}`}>
		<MaskedVideo
			mp4={`${CDN_BASE}videos/transparent/BUBBLE_800x800_${theme}.mp4`}
			webm={`${CDN_BASE}videos/transparent/BUBBLE_800x800_${theme}.webm`}
		/>
	</div>;
}