import { CSSTransition } from 'react-transition-group';
import useNotifications from '../../hooks/useNotifications';
import { MouseEvent, useCallback, useRef } from 'react';
import { useSurvey } from '../../hooks/useSurveys';
import useInventory from '../../hooks/useInventory';
import { ITEM_CLASSES } from '../../Constants';
import { useAppDispatch } from '../../redux/config/store';
import { removeNotification } from '../../redux/notifications';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useTelemetry, { TelemetryEvents } from '../../hooks/useTelemetry';
import CloseIcon from '../icons/CloseIcon';

export default function Notification() {
	const { t } = useTranslation();
	const notification = useNotifications();
	const ref = useRef<HTMLDivElement>(null);
	const item = useInventory([ITEM_CLASSES.SURVEY]).find(i => i.playfab.ItemInstanceId === notification?.Link);
	const survey = useSurvey(item?.playfab?.InstanceData?.InstanceId);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { logEvent } = useTelemetry();

	const onClick = useCallback(() => {
		if (survey) {
			logEvent(TelemetryEvents.clickNotificationSurvey({ notificationId: notification.NotificationId, surveyId: survey && survey.id }));
			survey.open();
			dispatch(removeNotification(notification.NotificationId));
		} else {
			logEvent(TelemetryEvents.clickNotification({ notificationId: notification.NotificationId, notification: notification.Message }));
			navigate(notification.Link);
		}
	}, [survey, dispatch, navigate, notification, logEvent]);

	const onClickClose = useCallback((e:MouseEvent) => {
		dispatch(removeNotification(notification.NotificationId));

		e.preventDefault();
		e.stopPropagation();
	}, [dispatch, notification]);

	return <CSSTransition nodeRef={ref} in={Boolean(notification)} key={notification?.NotificationId} appear mountOnEnter unmountOnExit timeout={1200}>
		<div ref={ref} className="single-notification" onClick={onClick}>
			{!survey && <div className="text">{notification?.Message}</div>}
			{survey && <div className="text">{t('notification.new_poll_available', { qty: survey?.customData?.xp || 199 })}</div>}
			<CloseIcon onClick={onClickClose} />
		</div>
	</CSSTransition>;
}