import React, { memo } from 'react';
import { getAdditionalInfos } from '../utils/getAdditionalInfos';

const timeFormat = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric' });

function RealtimeEvent({ e }:{ e:RealtimeEvent }) {
	return (
		<div className="event">
			<div className="time">{timeFormat.format(new Date(e.Timestamp))}</div>
			<div className="content">
				<div className="name">{e.EventName}</div>
				<div className="additional-infos">{getAdditionalInfos(e)}</div>
			</div>
		</div>
	);
}

export default memo(RealtimeEvent);