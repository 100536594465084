import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type Auth = {
	email: string;
};

const authProcess = createSlice({
	name: 'authProcess',
	initialState: {
		email: '',
	} as Auth,
	reducers: {
		updateAuthData: (state, { payload }: PayloadAction<Partial<Auth>>) => {
			return {
				...state,
				...payload,
			};
		},
	},
});

export const {
	updateAuthData,
} = authProcess.actions;

export default authProcess;
