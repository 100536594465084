import { CDN_BASE } from '../../../Constants';
import Bubble, { BUBBLE_THEMES } from '../../ui/Bubble';
import AnimatedBottleCap from '../../ui/AnimatedBottleCap';
import { useEffect, useCallback, useRef } from 'react';
import Button from '../../ui/Button';
import { useTranslation } from 'react-i18next';
import useTelemetry, { TelemetryEvents } from '../../../hooks/useTelemetry';
import { raw } from '../../../utils/textUtils';
import { CSSTransition } from 'react-transition-group';

export default function FTUESlider({ setSeen }: { setSeen: () => void }) {
	const { t } = useTranslation();
	const { logEvent } = useTelemetry();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const start = Date.now();

		return () => {
			const duration = Date.now() - start;

			if (duration > 10) {
				logEvent(TelemetryEvents.timeSpentFtueSlider({ duration: duration / 1000 }));
			}
		};
	}, [logEvent]);

	const onClickProceed = useCallback(() => {
		setSeen();
		logEvent(TelemetryEvents.clickProceedFtueSlide({}));
	}, [logEvent]);

	return (
		<CSSTransition in={true} nodeRef={ref} timeout={1200} appear mountOnEnter unmountOnExit>
			<div ref={ref} className='ftue'>
				<div className="logo">
					<img src={CDN_BASE + 'tccc-mission-universe-logo.svg'} width="271" height="111" alt="" />
				</div>
				<div className="top">
					<div className="bubble-ctn bubble-2">
						<Bubble theme={BUBBLE_THEMES.WHITE} />
						<AnimatedBottleCap />
					</div>
					<div className="bubble-ctn bubble-1">
						<Bubble theme={BUBBLE_THEMES.TREASURE_HUNT} />
						<img className="city" src={CDN_BASE + 'treasure-hunt-city.png'} width="446" height="456" alt="" />
					</div>
					<div className="bubble-ctn bubble-3">
						<Bubble theme={BUBBLE_THEMES.BLUE} />
						<img className="gift-card" src={CDN_BASE + 'gift-card.png'} width="385" height="287" alt="" />
					</div>
				</div>
				<div className="panel">
					<div className="text" {...raw(t('ftue_slider.panel.text'))} />
				</div>
				
				<div className="btns">
					<Button onClick={onClickProceed} className="cta start" label={t('ftue_slider.start_button_label')} />
				</div>
			</div>
		</CSSTransition>
	);
}
