import { createSlice } from '@reduxjs/toolkit';
import { getXrApi } from '../api/apiBridge';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';
import xrAction from './async/xrAction';

export const getGlobalVariables = createDebouncedAsyncAction(
	'playfab-xr/getGlobalVariables',
	xrAction(() => {
		return getXrApi().Client.GetGlobalVariable();
	}),
);

export type Region = { xpStatName:string, center: [number, number], countryCode: string, radius: number, subRegions:SubRegion };
export type SubRegion = Omit<Region & { name, description }, 'subRegions' | 'countryCode'>;

const initialState = {
	isLoaded: false,
	regions: {} as Record<string, Region>,
	pointsOfSale: [] as Place[],
	avatars: [] as string[],
	productSkus: {} as Record<string, string[]>,
	CompoundStatsConfig: {} as ({ source: { name: string, mapping: number[] }, target: { name: string, mapping: number[] } }[] | null),
};

const globalVariables = createSlice({
	name: 'globalVariables',
	reducers: {
		setGlobalVariable(state, action) {
			state[action.payload.key as keyof typeof initialState] = action.payload.value;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getGlobalVariables.actions.fulfilled, (state, action) => {
			return {
				...state,
				isLoaded: true,
				...action.payload.data.GlobalVariables.reduce((acc, curr) => {
					acc[curr.dataKey] = curr.dataVal;
					return acc;
				}, {} as Record<string, any>),
			};
		});
	},
	initialState,
});

export default globalVariables;

export const { setGlobalVariable } = globalVariables.actions;