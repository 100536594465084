import { v4 as uuid } from 'uuid';
import { useEffect } from 'react';
import usePlayer from './usePlayer';
import { getXrApi } from '../api/apiBridge';
import { PLAYFAB_CONFIG } from '../Constants';

type SessionStartEvent = {
	UserID: string;
	DeviceType: string;
	DeviceModel: string;
	OS: string;
	ClientSessionID: string;
};

type FocusEvent = {
	FocusId: string;
	FocusState: boolean;
	FocusStateDuration: number;
	EventTimestamp: string;
	ClientSessionID: string;
};

type EventContents = {
	Entity: {
		EntityId: string;
		EntityType: string;
	};
	EventNamespace: string;
	Name: string;
	OriginalId?: string;
	OriginalTimestamp: string;
	Payload: SessionStartEvent | FocusEvent;
};

const queue = [] as EventContents[];
const gameSessionId = uuid();
let initialFocus = true;
let focusId = null;
let focusOnTimestamp:number = 0;
let focusOffTimestamp:number = 0;
let focusStateDuration = 0;
let isSending = false;
const MAX_BATCH_SIZE = 200;

async function sendEvents() {
	if (!isSending) {
		isSending = true;

		if (queue.length > 0) {
			const url = `https://${PLAYFAB_CONFIG.appId}.playfabapi.com/Event/WriteEvents`;

			fetch(url, {
				method: 'POST',
				body: JSON.stringify({
					Events: queue.splice(0, MAX_BATCH_SIZE),
				}),
				headers: {
					'Content-Type': 'application/json',
					'X-EntityToken': getXrApi().GetEntityToken(),
				},
				keepalive: true,
			});
		}
	}

	isSending = false;
}

function generateEventContents(eventName:string, payload: SessionStartEvent | FocusEvent): EventContents {
	return {
		Entity: null,
		EventNamespace: 'com.playfab.events.sessions',
		Name: eventName,
		OriginalTimestamp: new Date().toJSON(),
		Payload: payload,
	};
}

function onApplicationFocus(isFocused: boolean) {
	if (initialFocus) {
		focusId = uuid();
	}

	if (isFocused) {
		focusOnTimestamp = Date.now();
		focusId = uuid();

		if (!initialFocus) {
			focusStateDuration = Math.round((Date.now() - focusOffTimestamp) / 1000);

			if (focusStateDuration < 0) {
				focusStateDuration = 0;
			}
		}
	} else {
		focusStateDuration = Math.round((Date.now() - focusOnTimestamp) / 1000);

		if (focusStateDuration < 0) {
			focusStateDuration = 0;
		}

		focusOffTimestamp = Date.now();
	}

	queue.push(generateEventContents('client_focus_change', {
		FocusId: focusId,
		FocusState: isFocused,
		FocusStateDuration: focusStateDuration,
		EventTimestamp: new Date().toJSON(),
		ClientSessionID: gameSessionId,
	}));

	initialFocus = false;

	if (!isFocused) {
		sendEvents();
	}
}

function clientSessionStart(playerId) {
	queue.push(generateEventContents('client_session_start', {
		UserID: playerId,
		DeviceType: 'Web',
		DeviceModel: navigator.userAgent,
		OS: navigator.platform,
		ClientSessionID: gameSessionId,
	} as SessionStartEvent));

	onApplicationFocus(true);
}

export default function useScreenTime() {

	const { playerId } = usePlayer();

	useEffect(() => {
		if (!playerId) return () => {};

		clientSessionStart(playerId);

		const onVisibilityChange = () => {
			onApplicationFocus(!document.hidden);
		};

		document.addEventListener('visibilitychange', onVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', onVisibilityChange);
		};
	}, [playerId]);
}