import { useMemo } from 'react';
import { useAppSelector } from '../redux/config/store';

export default function useInventory(classFilter:string[] = null) {
	const inventory = useAppSelector((state) => state.inventory);

	const filteredItems = useMemo(() => {
		if (classFilter) {
			return inventory.filter((item) => classFilter.includes(item.playfab.ItemClass));
		}
		return null;
	}, [inventory, classFilter]);
	
	return filteredItems || inventory;
}