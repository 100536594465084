import { ITEM_CLASSES } from '../Constants';
import { createSelector } from '@reduxjs/toolkit';
import { IRootState, useAppSelector } from '../redux/config/store';

const catalogSelector = (state:IRootState) => state.catalog.items;
const inventorySelector = (state:IRootState) => state.inventory;
const badgesSelector = createSelector(
	[
		catalogSelector,
		inventorySelector,
	],
	(catalog, inventory) => {
		const badges = catalog.filter((item) => item.playfab.ItemClass === ITEM_CLASSES.BADGE);
		const ownedBadges = inventory.filter((item) => item.playfab.ItemClass === ITEM_CLASSES.BADGE);

		return badges.map((badge) => {
			const owned = ownedBadges.find((item) => item.itemId === badge.itemId);
			return {
				...badge,
				owned: Boolean(owned),
			};
		});
	},
);

export default function useBadges() {
	const badges = useAppSelector(badgesSelector);

	return {
		badges,
	};
}