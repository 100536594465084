import { configureStore, GetState, ThunkDispatch } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import reduxLocalstorage from 'redux-simple-localstorage';

import { Action, AnyAction } from 'redux';
import { createLogger } from 'redux-logger';

import tracker from './middleware/tracker';

import { EXCLUDED_LOGGER_ACTIONS, STORE_NAME } from '../../Constants';
import { rootReducer } from '../rootReducer';

const { read, write } = reduxLocalstorage(STORE_NAME + '_store');

export type IRootState = ReturnType<typeof rootReducer>;
export type IAppDispatch = ThunkDispatch<IRootState, any, AnyAction>;

export const useAppDispatch = () => useDispatch<IAppDispatch>();
export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;

function filterActions(getState:GetState<IRootState>, action:Action) {
	if (EXCLUDED_LOGGER_ACTIONS.find(x => action.type.includes(x))) return false;
	return true;
}

const props = {} as { preloadedState?: any };
const previous = read();
if (previous) {
	// props.preloadedState = {
	// 	playfab: previous.playfab,
	// };
}

export const store = configureStore({
	...props,
	reducer: (state, action) => {
		if (state) {
			if (action.type === 'USER_LOGOUT') {
				return rootReducer(undefined, action);
			}
		}

		return rootReducer(state, action);
	},
	middleware: (getDefaultMiddleware) => {
		const middleware = getDefaultMiddleware();

		if (process.env.NODE_ENV !== 'production') {
			middleware.push(createLogger({ collapsed: true, predicate: filterActions, diff: true }));
			middleware.push(tracker as any);
		}
		
		middleware.push(write);

		return middleware;
	},
});
