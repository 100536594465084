function QuestionMarkIcon() {
	return (
		<svg className="question-mark-icon" xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				fill="url(#question-mark-icon-a)"
				fillOpacity="0.5"
				fillRule="evenodd"
				d="M3 4.075a.423.423 0 00.43.44H4.9c.247 0 .442-.2.475-.445.159-1.17.962-2.022 2.393-2.022 1.222 0 2.342.611 2.342 2.082 0 1.132-.668 1.652-1.72 2.444-1.2.872-2.15 1.89-2.082 3.542l.005.386c.003.244.202.44.446.44h1.445c.247 0 .446-.2.446-.446v-.188c0-1.278.487-1.652 1.8-2.647 1.086-.826 2.217-1.743 2.217-3.667C12.667 1.301 10.393 0 7.903 0 5.645 0 3.17 1.053 3.001 4.075zm2.776 10.273c0 .95.758 1.652 1.8 1.652 1.085 0 1.832-.702 1.832-1.652 0-.985-.747-1.675-1.833-1.675-1.04 0-1.799.69-1.799 1.675z"
				clipRule="evenodd"
			></path>
   
			<defs>
				<linearGradient
					id="question-mark-icon-a"
					x1="15"
					x2="15"
					y1="0"
					y2="31.938"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#91B8B6"></stop>
					<stop offset="1" stopColor="#765E78"></stop>
				</linearGradient>
			</defs>
		</svg>
	);
}

export default QuestionMarkIcon;
