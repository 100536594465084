import { useEffect, useState } from 'react';
import { CDN_BASE } from '../../Constants';
import { useTranslation } from 'react-i18next';
import { getRegion } from '../../regionManagement';

export default function OutsideMarket() {
	const { t } = useTranslation();

	const [region, setRegion] = useState<string | null>(null);

	useEffect(() => {
		getRegion().then(setRegion);
	}, []);

	return <div className="age-gate">
		<div className="logo">
			<img src={CDN_BASE + 'tccc-mission-universe-logo.svg'} width="271" height="111" alt="" />
		</div>
		<div className="panel">
			<div className="text">
				<strong>{t('outside_market.title')}</strong><br/>
				{t('outside_market.text')}
			</div>
		</div>
		{<div className="small">Region: {region || window?.location?.pathname?.split('/')?.[2] || 'unknown'}</div>}
	</div>;
}