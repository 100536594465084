import circle from '@turf/circle';
import { mask, Point } from '@turf/turf';
import { FillPaint, LinePaint } from 'mapbox-gl';
import { GeoJSONLayer } from 'react-mapbox-gl';

const lineStyle:LinePaint = {
	'line-color': 'black',
	'line-opacity': 1,
	'line-width': 3,
};

const fillStyle:FillPaint = {
	'fill-color': 'rgba(0, 0, 0, 0.5)',
};

function Radius({ center, radius = 800 }:{ center: Point | [number, number], radius?:number }) {
	if (!center) {
		return null;
	}

	return (
		<GeoJSONLayer
			linePaint={lineStyle}
			fillPaint={fillStyle}
			data={mask(circle(center, radius, { units: 'meters' }))}
		/>
	);
}

export default Radius;