import { STORE_NAME } from './Constants';
import i18n from './i18n';

const queryParams = {
	US: {
		lang: 'en',
		country: 'US',
		siteSection: 'Homepage',
		region: 'naou',
	},
	CA: {
		lang: 'en',
		country: 'CA',
		siteSection: 'Homepage',
		region: 'naou',
	},
	DE: {
		lang: 'de',
		country: 'DE',
		siteSection: 'Homepage',
		region: 'emea',
	},
	GB: {
		lang: 'en',
		country: 'GB',
		siteSection: 'Homepage',
		region: 'emea',
	},
	MX: {
		lang: 'es',
		country: 'MX',
		siteSection: 'Homepage',
		region: 'latam',
	},
	CO: {
		lang: 'es',
		country: 'CO',
		siteSection: 'Homepage',
		region: 'latam',
	},
} as const;
type RegionParamsKey = keyof typeof queryParams;

const UNKNOWN = 'unknown';

let region:RegionParamsKey | 'unknown' = (sessionStorage.getItem(STORE_NAME + '_region') as RegionParamsKey) || UNKNOWN;

export function getRegionFromURL() {
	const urlCountry = window.location.pathname.split('/')[2] || '';
	return urlCountry.toUpperCase() as RegionParamsKey;
}

export function isValidRegion() {
	return !!queryParams[region] && region !== UNKNOWN;
}

function setRegion(r:RegionParamsKey | 'unknown') {
	region = r;
	window.tccc_analytics.country = r;

	if (queryParams[r]) {
		sessionStorage.setItem(STORE_NAME + '_region', region);
	}
	
	return r;
}

export async function setRegionFromHeader() {
	const resp = await fetch('/');
	const r = resp.headers.get('cloudfront-viewer-country')?.toUpperCase() as RegionParamsKey || UNKNOWN;
	
	if (r) {
		setRegion(r);
	}
}

export async function getRegion() {
	let regionData;

	const urlRegion = getRegionFromURL();
	
	if (urlRegion && urlRegion !== region) {
		setRegion(urlRegion);
		regionData = queryParams[region];
	}

	if (region === UNKNOWN) {
		await setRegionFromHeader();
		regionData = queryParams[region];
	}

	if (regionData) {
		i18n.changeLanguage(regionData.lang + '-' + regionData.country);
	}

	return Promise.resolve(region);
}

export function getBasePath() {
	const r = queryParams[region];
	if (!r) return '/';
	return `${r.lang.toLowerCase()}/${r.country.toLowerCase()}`;
}

export function getScope() {
	switch (region) {
		case 'CA': return 'https://ccnaprod.onmicrosoft.com/authorizer-service/read';
		case 'US': return 'https://ccnaprod.onmicrosoft.com/authorizer-service/read';
		case 'DE': return 'https://emeaprod.onmicrosoft.com/authorizer-service/read';
		case 'GB': return 'https://emeaprod.onmicrosoft.com/authorizer-service/read';
		case 'MX': return 'https://latamprod.onmicrosoft.com/authorizer-service/read';
		case 'CO': return 'https://latamprod.onmicrosoft.com/authorizer-service/read';
		default: return 'https://emeaprod.onmicrosoft.com/authorizer-service/read';
	}
}

export function getLoginRequest() {
	return {
		scopes: [getScope()],
		extraQueryParameters: {
			...queryParams[region],
			otag: (window as any).OnetrustActiveGroups,
		},
	};
}

export function getCreateAccountRequest() {
	const req = getLoginRequest();
	// @ts-ignore
	req.extraQueryParameters.signUpFlow = 'go';
	return req;
}

export function getAppParams() {
	switch (region) {
		case 'CA':
		case 'US':
			return {
				clientId: 'f2463397-42fd-4529-acc3-129743f01731',
				authority: 'login.naou.coca-cola.com',
				authorityUrl: 'https://login.naou.coca-cola.com/a62739e6-ccaa-4a4a-831c-416177dae1ea/b2c_1a_signup_signin_gam',
			};
		case 'MX':
			return {
				clientId: '0c927e83-e4ea-425f-a905-bc470376f4e4',
				authority: 'login.latam.coca-cola.com',
				authorityUrl: 'https://login.latam.coca-cola.com/c206a032-1abd-4f16-abec-5634d45d70eb/b2c_1a_signup_signin_gam',
			};
		case 'CO':
			return {
				clientId: '4bd6215b-50a7-4642-bce2-f414be2d0178',
				authority: 'login.latam.coca-cola.com',
				authorityUrl: 'https://login.latam.coca-cola.com/c206a032-1abd-4f16-abec-5634d45d70eb/b2c_1a_signup_signin_gam',
			};
		case 'GB':
			return {
				clientId: 'bca4051c-87c5-4d31-9853-10bf779a9c2f',
				authority: 'login.emea.coca-cola.com',
				authorityUrl: 'https://login.emea.coca-cola.com/88467e8b-5137-4c7c-ae8c-77ee707f2f37/b2c_1a_signup_signin_gam',
			};
		case 'DE':
		default:
			return {
				clientId: '30c76a1d-e9e0-4624-81da-0979af2bd5ff',
				authority: 'login.emea.coca-cola.com',
				authorityUrl: 'https://login.emea.coca-cola.com/88467e8b-5137-4c7c-ae8c-77ee707f2f37/b2c_1a_signup_signin_gam',
			};
	}
}