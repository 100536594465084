import { createSlice } from '@reduxjs/toolkit';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';
import { IPlayerStatistics } from './statistics';
import xrAction from './async/xrAction';
import { getXrApi } from '../api/apiBridge';

export const getLeaderboard = createDebouncedAsyncAction(
	'playfab-xr/getLeaderboard',
	xrAction(({ StatisticName, StartPosition = 0, MaxResultsCount = 10 }:{ StatisticName: keyof IPlayerStatistics, StartPosition?: number, MaxResultsCount?:number }) => getXrApi().Client.GetLeaderboard({
		StatisticName,
		StartPosition,
		MaxResultsCount,
		ProfileConstraints: {
			ShowStatistics: true,
			ShowDisplayName: true,
			ShowAvatarUrl: true,
		},
	})),
);

const leaderboard = createSlice({
	name: 'leaderboard',
	initialState: {} as Record<keyof IPlayerStatistics, LeaderboardEntry[]>,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getLeaderboard.actions.fulfilled, (state, action) => {
			state[action.meta.arg.StatisticName] = action?.payload?.data?.Leaderboard || [];
		});
	},
});

export default leaderboard;