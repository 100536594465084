import { memo, useState } from 'react';
import { CDN_BASE } from '../../Constants';
import Button from '../ui/Button';
import { AppRouting } from '../../AppRoutes';
import { useTranslation } from 'react-i18next';
import useTranslatedMission from '../../hooks/useTranslatedMission';
import useTelemetry, { TelemetryEvents } from '../../hooks/useTelemetry';
import { updatePlayerData } from '../../redux/playerData';
import { useAppDispatch, useAppSelector } from '../../redux/config/store';

function TreasureHuntMission({ isActive, mission, onClose }: { isActive?: boolean, mission:IXRMissionItem, onClose: () => void }) {
	const { logEvent } = useTelemetry();
	const { t } = useTranslation();
	const tMission = useTranslatedMission(mission);
	const activeObjectiveIndex = mission.objectives.findIndex(o => !o?.PlayerStatus?.IsComplete);
	const activeObjective = mission.objectives[activeObjectiveIndex] as IXRMissionObjectiveParsedData;
	const showClues = useAppSelector(state => state.playerData?.data?.seenFirstMission);
	const missionCompleted = mission.PlayerStatus.IsComplete;
	const completedObjectives = mission.objectives.filter(o => o.PlayerStatus.IsComplete).length;
	const dispatch = useAppDispatch();

	const onClickViewClues = () => {
		dispatch(updatePlayerData({ seenFirstMission: true }));
		logEvent(TelemetryEvents.clickViewClues({ missionId: mission.itemId }));
	};

	return <div className={`mission ${isActive ? 'active' : ''}`}>
		<div className="title">{tMission.displayName}</div>
		{
			!missionCompleted && !showClues && <>
				<div className="description">{tMission.description}</div>
				<Button label={t('missions_list.treasure_hunt.view_clues')} onClick={onClickViewClues} />
			</>
		}
		{
			!missionCompleted && showClues && <>
				<div className="objective">
					<div className="subtitle">{t('missions_list.treasure_hunt.subtitle', { count: activeObjectiveIndex + 1 })}</div>
					<div className="clue-description">{tMission?.objectives?.[activeObjective?.id]?.clue}</div>
				</div>
				<div className="clues">
					{mission.objectives.map((clue, index) => <div key={index} className={`clue ${clue?.PlayerStatus?.IsComplete ? 'completed' : ''} ${activeObjectiveIndex === index ? 'active' : ''}`}>
						<img src={CDN_BASE + 'bottle-cap.png'} alt="" />
					</div>)}
					<div className="clues-left">{t('missions_list.treasure_hunt.clues_left', { count: completedObjectives, total: mission.objectives.length })}</div>
				</div>
				<Button label={t('missions_list.treasure_hunt.continue_button_label')} onClick={onClose} />
			</>
		}
		{missionCompleted && <>
			<div className="description">{t('missions_list.treasure_hunt.completed.subtitle')}</div>
			<Button label={t('missions_list.treasure_hunt.completed.button_label')} to={AppRouting.missions.index} />
		</>}
	</div>;
}

export default memo(TreasureHuntMission);