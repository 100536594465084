import { MouseEvent } from 'react';

function CloseIcon({ onClick }: { onClick: (e?:MouseEvent) => void }) {
	return (
		<svg onClick={onClick} className="close-icon" xmlns="http://www.w3.org/2000/svg" fill="none" width="24" height="24" viewBox="0 0 24 24">
			<path
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M21 21l-9-9m0 0L3 3m9 9l9-9m-9 9l-9 9"
			></path>
		</svg>
	);
}

export default CloseIcon;
