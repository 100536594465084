function EditIcon() {
	return (
		<svg className="edit-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 11" width="12" height="11">
			<path
				fill="currentColor"
				d="M8.984 9.008a.658.658 0 01-.657.657H1.71a.658.658 0 01-.656-.657V2.392c0-.362.294-.657.656-.657h5.128L7.89.682H1.71A1.71 1.71 0 000 2.392v6.616c0 .945.766 1.71 1.71 1.71h6.617a1.71 1.71 0 001.71-1.71v-5.26L8.984 4.803v4.206z"
			></path>
			<path
				fill="currentColor"
				d="M8.939.75L4.272 5.417l-.437 1.711a.176.176 0 00.214.215l1.712-.438 4.667-4.666L8.938.75zM10.87.308a1.053 1.053 0 00-1.488 0l-.04.04 1.49 1.488.038-.039a1.052 1.052 0 000-1.489z"
			></path>
		</svg>
	);
}

export default EditIcon;
