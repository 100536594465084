import { memo, useCallback, useRef } from 'react';
import { CDN_BASE, PLAYER_EVENTS } from '../../../Constants';
import { Marker } from 'react-mapbox-gl';
import { CSSTransition } from 'react-transition-group';
import { useAppDispatch } from '../../../redux/config/store';
import { sendMissionInput, writePlayerEvent } from '../../../redux/playfab';
import { PayloadAction } from '@reduxjs/toolkit';
import useTelemetry, { TelemetryEvents } from '../../../hooks/useTelemetry';
import AnimatedBottleCap from '../AnimatedBottleCap';

const UnClaimedMarker = memo(function ({ isPlayerInVicinity, coordinates, place, onClaimed }: { isPlayerInVicinity:boolean, place:Place, coordinates:[number, number], onClaimed: (resp:SendMissionInputResponse) => void }) {
	const poiFadedRef = useRef<HTMLImageElement>(null);
	const dispatch = useAppDispatch();
	const { logEvent } = useTelemetry();

	const onClick = useCallback(() => {
		dispatch(writePlayerEvent({
			EventName: PLAYER_EVENTS.PLAYER_VISITED_LOCATION,
			Body: {
				lat: coordinates[1],
				lon: coordinates[0],
			},
		}));

		logEvent(TelemetryEvents.clickMarker({ poi: place.placeName, missionId: place?.mission?.itemId, objectiveId: place?.objective?.ObjectiveId }));

		dispatch(sendMissionInput({
			ItemId: place.mission?.itemId || '',
			ObjectiveId: place.objective?.ObjectiveId || '',
			Input: coordinates[1] + ',' + coordinates[0],
		})).then((resp:PayloadAction<GenericApiCallResponse<SendMissionInputResponse>>) => {
			if (resp?.payload?.success) {
				onClaimed?.(resp.payload.data);
				logEvent(TelemetryEvents.claimMarker({ poi: place.placeName, missionId: place?.mission?.itemId, objectiveId: place?.objective?.ObjectiveId }));
			}
		});
	}, [logEvent, dispatch, place, coordinates, onClaimed]);
	
	return <Marker
		coordinates={coordinates}
		anchor="bottom"
		className={`poi unclaimed ${isPlayerInVicinity ? '' : 'hide'}`}
	>
		<CSSTransition nodeRef={poiFadedRef} in={isPlayerInVicinity} timeout={600} appear mountOnEnter unmountOnExit>
			<div className="inner" ref={poiFadedRef}>
				<AnimatedBottleCap />
				<img className="marker" src={CDN_BASE + 'map/pin-pos-completed.png'} />
			</div>
		</CSSTransition>
		<div className="hotspot" onClick={onClick} />
	</Marker>;
});

type MapMarkerProps = {
	isPlayerInVicinity:boolean,
	coordinates:[number, number],
	place:Place,
	showPopup: boolean,
	onClick: (place:Place) => void
	onClaimed?: (resp:SendMissionInputResponse) => void
};

const MapMarker = ({ isPlayerInVicinity, coordinates, place, showPopup, onClick, onClaimed }:MapMarkerProps) => {
	const popupRef = useRef<HTMLDivElement>(null);

	if (place.type === 'poi' && place.canBeClaimed) return <UnClaimedMarker onClaimed={onClaimed} isPlayerInVicinity={isPlayerInVicinity} place={place} coordinates={coordinates} />;
	if (place.type === 'poi' && !place.visited) return null;

	const classes = ['poi'];
	if (showPopup) classes.push('active');

	return <Marker
		coordinates={coordinates}
		anchor="bottom"
		className={classes.join(' ')}
	>
		<img src={CDN_BASE + 'bottle-cap.png'} alt="" className="icon" />
		<img className="marker" src={CDN_BASE + `map/pin-${place.type}-completed.png`} />
		<CSSTransition nodeRef={popupRef} in={showPopup} timeout={{ enter: 600, appear: 600, exit: 0 }} appear unmountOnExit mountOnEnter>
			<div ref={popupRef} className="popup">
				<div className="title">{place.placeName}</div>
				<div className="address">{place.address}</div>
			</div>
		</CSSTransition>
		<div className="hotspot" onClick={(e) => { e.stopPropagation(); onClick(place); }} />
	</Marker>;
};

export default memo(MapMarker);