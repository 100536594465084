import { Ref } from 'react';
import useMissions from '../../hooks/useMissions';
import { MISSIONS_TYPES } from '../../Constants';
import TreasureHuntMission from './TreasureHuntMission';
import CircleClose from '../ui/CircleClose';
import Button from '../ui/Button';
import { AppRouting } from '../../AppRoutes';
import { useTranslation } from 'react-i18next';

type MissionListProps = {
	nodeRef?: Ref<HTMLDivElement>,
	onClose: () => void,
};

export default function MissionsList({ nodeRef, onClose }:MissionListProps) {
	const { t } = useTranslation();

	const missions = useMissions().filter(m => m.isActiveMission);
	
	return <div className="missions-list" ref={nodeRef}>
		<div className="nav">
			<CircleClose onClick={onClose} />
		</div>
		<div className="missions">
			{missions.map((mission) => {
				switch (mission.type.title) {
					case MISSIONS_TYPES.TREASURE_HUNT:
						return <TreasureHuntMission key={mission.itemId} isActive={true} mission={mission} onClose={onClose} />;
					default:
						return null;
				}
			})}
			{!missions.length && <div className="missions">
				<div className="mission active">
					<div className="title">{t('missions_list.empty.title')}</div>
					<div className="description">{t('missions_list.empty.description')}</div>
					<Button className='thin full-width' label={t('missions_list.empty.button_label')} to={AppRouting.missions.index} />
				</div>
			</div>}
		</div>
	</div>;
}