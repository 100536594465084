import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { IPlayerStatistics } from '../redux/statistics';
import { getLeaderboard } from '../redux/leaderboard';
import usePlayer from './usePlayer';
import { getGlobalVariables } from '../redux/globalVariables';
import { LEADERBOARD_LENGTH } from '../Constants';

export default function useLeaderboard(statisticName: keyof IPlayerStatistics, forceUpdate = false) {
	let leaderboard = useAppSelector(s => s.leaderboard[statisticName]);
	const completeLeaderboard = leaderboard;

	leaderboard = leaderboard?.filter?.(x => x.StatValue > 0)?.slice(0, LEADERBOARD_LENGTH);

	const {
		playerId,
	} = usePlayer();

	const dispatch = useAppDispatch();

	const fetchLeaderboard = useCallback(() => {
		dispatch(getLeaderboard({ StatisticName: statisticName, MaxResultsCount: LEADERBOARD_LENGTH, StartPosition: 0 }));
	}, [dispatch]);

	useEffect(() => {
		if (playerId && (!leaderboard || forceUpdate)) {
			fetchLeaderboard();
		}
	}, [statisticName, playerId, dispatch, leaderboard, forceUpdate, fetchLeaderboard]);

	return {
		updateLeaderboard: () => dispatch(getGlobalVariables()).then(fetchLeaderboard),
		leaderboard: leaderboard || [],
		completeLeaderboard,
	};
}
