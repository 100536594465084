function WarningIcon() {
	return (
		<svg className="warning-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 29" width="32" height="29">
			<path
				fill="currentColor"
				fillRule="evenodd"
				d="M.54 22.668L12.474 1.994c1.536-2.659 5.37-2.659 6.906 0l11.951 20.674c1.536 2.659-.386 5.985-3.457 5.985H3.997c-3.07 0-4.992-3.326-3.457-5.985zm1.878 2.904c.158.281.623.913 1.57.913h23.869c.947 0 1.412-.64 1.57-.913.158-.272.474-1 0-1.816L17.493 3.082a1.777 1.777 0 00-1.57-.912c-.316 0-1.097.096-1.571.912L2.418 23.756c-.474.816-.158 1.536 0 1.816z"
				clipRule="evenodd"
			></path>
			<path
				fill="currentColor"
				d="M15.932 24.309a1.518 1.518 0 100-3.036 1.518 1.518 0 000 3.036zm0-17.357c1.167 0 2.07 1 1.965 2.158l-1.009 10.434a.953.953 0 01-1.895 0L13.983 9.11a1.969 1.969 0 011.966-2.158h-.017z"
			></path>
		</svg>
	);
}

export default WarningIcon;
