/* eslint-disable @typescript-eslint/naming-convention */
import { IPlayerStatistics } from '../redux/statistics';
import useRegion from './useRegion';
import useStats from './useStats';

export default function useXP() {
	const stats = useStats();

	const {
		currentRegion,
		currentSubRegion,
	} = useRegion();

	const statName = currentSubRegion?.xpStatName || currentRegion?.xpStatName as keyof IPlayerStatistics;

	const xp = stats[statName] ?? 0;

	return {
		xp,
		statName,
	};
}