import { NavLink } from 'react-router-dom';
import { CDN_BASE } from '../../../Constants';
import useBadges from '../../../hooks/useBadges';
import usePlayer from '../../../hooks/usePlayer';
import CheckmarkIcon from '../../icons/CheckmarkIcon';
import useStats from '../../../hooks/useStats';
import useLeaderboardAroundPlayer from '../../../hooks/useLeaderboardAroundPlayer';
import useXP from '../../../hooks/useXP';
import { AppRouting } from '../../../AppRoutes';
import Button from '../../ui/Button';
import { useLogout } from '../../../hooks/useLogout';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { useRef } from 'react';
import EditIcon from '../../icons/EditIcon';
import Bubble from '../../ui/Bubble';

export default function ProfileTab() {
	const { t } = useTranslation();
	const ref = useRef<HTMLDivElement>(null);
	const { badges } = useBadges();
	const {
		AvatarUrl,
		DisplayName,
		playerId,
	} = usePlayer();

	const { statName } = useXP();
	const {
		capsCollected,
		badgesEarned,
		missionsCompleted,
		pollsCompleted,
		productsScanned,
		distanceTraveled,
	} = useStats();

	const { logout } = useLogout();

	const medals = [
		{ glow: 'gold', image: CDN_BASE + 'gold-medal.png' },
		{ glow: 'silver', image: CDN_BASE + 'silver-medal.png' },
		{ glow: 'bronze', image: CDN_BASE + 'bronze-medal.png' },
	];

	const { leaderBoardAroundPlayer } = useLeaderboardAroundPlayer(statName);

	const niceStats = [
		{ icon: null, name: t('you.profile_tab.stats.caps_collected'), value: capsCollected, prefix: 'x', suffix:'', under: '' },
		{ icon: null, name: t('you.profile_tab.stats.badges_earned'), value: badgesEarned, prefix: 'x', suffix:'', under: '' },
		{ icon: null, name: t('you.profile_tab.stats.distance_traveled'), value: distanceTraveled > 1000 ? Math.round(distanceTraveled / 100) / 10 : distanceTraveled, prefix: '', suffix: '', under: distanceTraveled > 1000 ? 'km' : 'm' },
		{ icon: null, name: t('you.profile_tab.stats.missions_completed'), value: missionsCompleted, prefix: 'x', suffix:'', under: '' },
		{ icon: null, name: t('you.profile_tab.stats.polls_completed'), value: pollsCompleted, prefix: 'x', suffix:'', under: '' },
		{ icon: null, name: t('you.profile_tab.stats.products_scanned'), value: productsScanned, prefix: 'x', suffix:'', under: '' },
	];
	
	return <CSSTransition nodeRef={ref} in={true} timeout={600} appear>
		<div ref={ref} className="tab profile-tab">
			<div className="panel profile">
				<div className="title">{t('you.profile_tab.profile.title')}</div>
				<a className="t-and-c" href={t('common.terms_conditions_link')}>{t('you.profile_tab.profile.terms_conditions')}</a>
				<div className="card">
					<NavLink to={AppRouting.avatarSelection.index} className="avatar">
						<img src={AvatarUrl} alt="" />
						<Bubble theme='white' />
						<EditIcon />
					</NavLink>
					<div className="context">
						<div className="name">{DisplayName}</div>
						<div className="text">{t('you.profile_tab.profile.description')}<br/><small>ID: {playerId}</small></div>
					</div>
				</div>
			</div>

			<div className="panel stats">
				<div className="title">{t('you.profile_tab.stats.title')}</div>
				<div className="stats-grid">
					{niceStats.map(stat => <div key={stat.name} className="stat">
						<div className="value-ctn">
							{stat.icon}
							<div className="value">{stat.prefix}{stat.value}{stat.suffix}{stat.under && <div className="under">{stat.under}</div>}</div>
						</div>
						<div className="name">{stat.name}</div>
					</div>)}
				</div>
			</div>

			<div className="panel rank">
				<div className="title">{t('you.profile_tab.rank.title')}</div>
				{leaderBoardAroundPlayer.map((entry) => {
					const position = entry.Position;
					return <NavLink to={AppRouting.you.leaderboard} key={entry.PlayFabId} className={`leaderboard-entry ${medals[entry.Position - 1]?.glow ? 'glow-' + medals[position]?.glow : ''}`}>
						{position < 3 && <div className="rank"><img width="100" height="100" src={medals[position].image} alt="" /></div>}
						{position >= 3 && <div className="rank">{position < 100 ? position + 1 : '99+'}</div>}
						<div className="name">{entry.DisplayName}</div>
						<div className="value">{entry.StatValue}xp</div>
					</NavLink>;
				})}
			</div>

			<div className="panel badges">
				<div className="title">{t('you.profile_tab.badges.title')}</div>
				<NavLink to={AppRouting.you.badges} className="badges-grid">
					{badges.map((badge) => <div key={badge.id} className={`badge ${badge.owned ? 'owned' : ''}`}>
						<div className="image">
							{badge.owned && <div className="check"><CheckmarkIcon /></div>}
							<img className="static-bubble" width="115" height="115" src={CDN_BASE + `static-bubble-${badge.owned ? 'rainbow' : 'white'}.png`} alt="" />
							{badge.playfab.ItemImageUrl && <img className="icon" width="115" height="115" src={badge.playfab.ItemImageUrl} alt="" />}
						</div>
					</div>)}
				</NavLink>
			</div>

			<div className="panel logout">
				<Button className="full-width thin" label={t('you.profile_tab.logout_button_label')} onClick={logout} />
			</div>
		</div>
	</CSSTransition>;
}