function ThumbsUpIcon() {
	return (
		<svg className="thumbs-up-icon" xmlns="http://www.w3.org/2000/svg" fill="none" width="22" height="23" viewBox="0 0 22 23">
			<path
				fill="currentColor"
				d="M20.635 12.14s1.035-.691.966-1.882c-.07-1.191-.933-2.006-3.064-1.88-2.131.125-3.615-.126-3.615-.126s.314-.69 1.003-2.319c.69-1.63.92-2.214.564-3.614C16.134.919 15.11 0 14.045 0c-1.066 0-1.02.605-1.02.605s.088 1.162-.57 2.675c-.658 1.514-2.5 3.575-3.597 4.76-1.097 1.184-2.259 1.842-2.303 2.697-.044.855-.153 9.255.066 9.847.22.592.68.504 2.368.768 1.689.263 4.167 1.315 7.808.723 3.64-.592 2.675-2.39 2.434-2.873 0 0 1.228-.087 1.667-1.338.438-1.25-.549-2.039-.549-2.039s1.053-.417 1.229-1.58c.175-1.161-.702-2.06-.944-2.104h.001zM4.216 9.028H1.301A1.3 1.3 0 000 10.329v10.39a1.3 1.3 0 001.301 1.3h2.915a1.3 1.3 0 001.301-1.3v-10.39a1.3 1.3 0 00-1.3-1.301z"
			></path>
		</svg>
	);
}

export default ThumbsUpIcon;
