import { useTranslation } from 'react-i18next';
import { CDN_BASE, MISSIONS_TYPES } from '../../Constants';
import useMissions from '../../hooks/useMissions';
import Button from '../ui/Button';
import { useEffect } from 'react';
import useTelemetry, { TelemetryEvents } from '../../hooks/useTelemetry';

function TreasureHunt() {
	const { t } = useTranslation();

	return (
		<div className="intro-modal-content treasure-hunt">
			<div className="groups">
				<div className="group">
					<div className="icon">
						<img src={CDN_BASE + 'map/th-visit.png'} width="162" height="242" alt="" />
					</div>
					<div className="content">
						<p>{t('intro_modal.treasure_hunt.step_1_text')}</p>
					</div>
				</div>
				
				<div className="group">
					<div className="icon">
						<img src={CDN_BASE + 'map/th-missions.png'} width="162" height="242" alt="" />
					</div>
					<div className="content">
						<p>{t('intro_modal.treasure_hunt.step_2_text')}</p>
					</div>
				</div>
				
				<div className="group">
					<div className="icon">
						<img src={CDN_BASE + 'map/th-drops.png'} width="162" height="242" alt="" />
					</div>
					<div className="content">
						<p>{t('intro_modal.treasure_hunt.step_3_text')}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default function IntroModal({ onClose }:{ onClose: () => void }) {
	const { logEvent } = useTelemetry();
	const { t } = useTranslation();
	const missions = useMissions();
	const activeMission = missions.find(m => m.isActiveMission);

	useEffect(() => {
		logEvent(TelemetryEvents.introModalOpen({ missionId: activeMission?.itemId, type: activeMission?.type?.title }));

		return () => {
			logEvent(TelemetryEvents.introModalClose({ missionId: activeMission?.itemId, type: activeMission?.type?.title }));
		};
	}, [logEvent]);

	return <div className="intro-modal">
		<TreasureHunt />
		<Button label={t('intro_modal.button_label')} onClick={onClose} />
	</div>;
}