function CouponBG() {
	return (
		<svg className="coupon-bg" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 329 330">
			<g filter="url(#a-coupon-bg)">
				<mask id="e-coupon-bg" fill="#fff">
					<path
						fillRule="evenodd"
						d="M24 0C10.745 0 0 10.745 0 24v67.195a9.072 9.072 0 012.032-.229c4.988 0 9.031 4.037 9.031 9.017 0 4.98-4.043 9.017-9.031 9.017A9.067 9.067 0 010 108.771V306c0 13.255 10.745 24 24 24h281c13.255 0 24-10.745 24-24V109h-.032c-4.988 0-9.031-4.037-9.031-9.017 0-4.98 4.043-9.017 9.031-9.017H329V24c0-13.255-10.745-24-24-24H24z"
						clipRule="evenodd"
					></path>
				</mask>
				<path
					fill="url(#b-coupon-bg)"
					fillRule="evenodd"
					d="M24 0C10.745 0 0 10.745 0 24v67.195a9.072 9.072 0 012.032-.229c4.988 0 9.031 4.037 9.031 9.017 0 4.98-4.043 9.017-9.031 9.017A9.067 9.067 0 010 108.771V306c0 13.255 10.745 24 24 24h281c13.255 0 24-10.745 24-24V109h-.032c-4.988 0-9.031-4.037-9.031-9.017 0-4.98 4.043-9.017 9.031-9.017H329V24c0-13.255-10.745-24-24-24H24z"
					clipRule="evenodd"
				></path>
				<path
					fill="url(#c-coupon-bg)"
					fillOpacity="0.44"
					fillRule="evenodd"
					d="M24 0C10.745 0 0 10.745 0 24v67.195a9.072 9.072 0 012.032-.229c4.988 0 9.031 4.037 9.031 9.017 0 4.98-4.043 9.017-9.031 9.017A9.067 9.067 0 010 108.771V306c0 13.255 10.745 24 24 24h281c13.255 0 24-10.745 24-24V109h-.032c-4.988 0-9.031-4.037-9.031-9.017 0-4.98 4.043-9.017 9.031-9.017H329V24c0-13.255-10.745-24-24-24H24z"
					clipRule="evenodd"
				></path>
				<path
					fill="url(#d-coupon-bg)"
					d="M0 91.195h-2v2.511l2.447-.561L0 91.195zm0 17.576l.447-1.949L-2 106.26v2.511h2zM329 109h2v-2.007l-2.007.007.007 2zm0-18.034l-.007 2 2.007.008v-2.008h-2zM2 24C2 11.85 11.85 2 24 2v-4C9.64-2-2 9.64-2 24h4zm0 67.195V24h-4v67.195h4zm.032-2.229c-.85 0-1.68.097-2.48.28l.895 3.899a7.076 7.076 0 011.585-.179v-4zm11.031 11.017c0-6.087-4.942-11.017-11.031-11.017v4c3.886 0 7.031 3.145 7.031 7.017h4zM2.032 111c6.09 0 11.031-4.929 11.031-11.017h-4c0 3.872-3.145 7.017-7.031 7.017v4zm-2.48-.28c.8.184 1.63.28 2.48.28v-4a7.099 7.099 0 01-1.585-.178l-.894 3.898zM2 306V108.771h-4V306h4zm22 22c-12.15 0-22-9.85-22-22h-4c0 14.359 11.64 26 26 26v-4zm281 0H24v4h281v-4zm22-22c0 12.15-9.85 22-22 22v4c14.359 0 26-11.641 26-26h-4zm0-197v197h4V109h-4zm1.968 2h.039l-.014-4h-.025v4zm-11.031-11.017c0 6.088 4.942 11.017 11.031 11.017v-4c-3.886 0-7.031-3.145-7.031-7.017h-4zm11.031-11.017c-6.089 0-11.031 4.93-11.031 11.017h4c0-3.872 3.145-7.017 7.031-7.017v-4zm.039 0h-.039v4h.025l.014-4zM327 24v66.966h4V24h-4zM305 2c12.15 0 22 9.85 22 22h4c0-14.36-11.641-26-26-26v4zM24 2h281v-4H24v4z"
					mask="url(#e-coupon-bg)"
				></path>
			</g>
			<defs>
				<linearGradient
					id="b-coupon-bg"
					x1="111.739"
					x2="289.272"
					y1="-3.053"
					y2="124.419"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff" stopOpacity="0.81"></stop>
					<stop offset="0.27" stopColor="#fff" stopOpacity="0.3"></stop>
					<stop offset="0.64" stopColor="#fff" stopOpacity="0.47"></stop>
					<stop offset="1" stopColor="#fff" stopOpacity="0.18"></stop>
				</linearGradient>
				<linearGradient
					id="c-coupon-bg"
					x1="229"
					x2="-10.5"
					y1="20.226"
					y2="315.726"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff" stopOpacity="0"></stop>
					<stop offset="1" stopColor="#fff"></stop>
				</linearGradient>
				<linearGradient
					id="d-coupon-bg"
					x1="107.283"
					x2="293.333"
					y1="-3.053"
					y2="162.174"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff" stopOpacity="0.81"></stop>
					<stop offset="0.27" stopColor="#fff" stopOpacity="0.3"></stop>
					<stop offset="0.64" stopColor="#fff" stopOpacity="0.47"></stop>
					<stop offset="1" stopColor="#F20109" stopOpacity="0.25"></stop>
				</linearGradient>
				<filter
					id="a-coupon-bg"
					width="349"
					height="350"
					x="-10"
					y="-10"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
					<feGaussianBlur
						in="BackgroundImageFix"
						stdDeviation="5"
					></feGaussianBlur>
					<feComposite
						in2="SourceAlpha"
						operator="in"
						result="effect1_backgroundBlur_952_7821"
					></feComposite>
					<feBlend
						in="SourceGraphic"
						in2="effect1_backgroundBlur_952_7821"
						result="shape"
					></feBlend>
				</filter>
			</defs>
		</svg>
	);
}

export default CouponBG;
