import { useEffect, useState } from 'react';
import useLocalization from './useLocalization';

type Objectives = Record<string, {
	title: string,
	clue:string,
	successMessage:string,
}>;

type Data = {
	displayName:string,
	description:string,
	rewardDescription: string,
	objectives: Objectives,
};

export default function useTranslatedMission( mission: IXRMissionItem) {
	const [data, setData] = useState<Data>({
		displayName: mission?.playfab?.DisplayName,
		description: mission?.data?.description as string,
		rewardDescription: mission?.data?.rewardDescription as string,
		objectives: (mission?.objectives as IXRMissionObjectiveParsedData[])?.reduce((carry, o) => {
			carry[o.id] = {
				title: o.title,
				clue: o.data?.clue as string,
				successMessage: o.data?.successMessage as string,
			};
			return carry;
		}, {} as Objectives) || {},
	});

	const { string } = useLocalization();

	function load(dataKey: keyof Data, key:string, fallback: string) {
		string(key, fallback).then((result) => {
			setData((prev) => ({ ...prev, [dataKey]: result || fallback }));
		});
	}

	useEffect(() => {
		if (!mission) return;

		load('displayName', mission.itemId + '/playfab/DisplayName', mission.playfab.DisplayName);
		load('description', mission.itemId + '/custom-data/description', mission.data.description as string);
		load('rewardDescription', mission.itemId + '/custom-data/rewardDescription', mission.data.rewardDescription as string);

		(mission.objectives as IXRMissionObjectiveParsedData[]).forEach((o) => {
			string(mission.itemId + '/objective_' + o.id + '/title', o.title as string).then((result) => {
				setData((prev) => {
					const objectives = { ...prev.objectives };
					if (!objectives[o.id]) objectives[o.id] = { title: '', clue: '', successMessage: '' };
					objectives[o.id].title = result || o.title;
					return { ...prev, objectives };
				});
			});
			string(mission.itemId + '/objective_' + o.id + '/custom-data/clue', o.data.clue as string).then((result) => {
				setData((prev) => {
					const objectives = { ...prev.objectives };
					objectives[o.id].clue = result || o.data?.clue;
					return { ...prev, objectives };
				});
			});
			string(mission.itemId + '/objective_' + o.id + '/custom-data/successMessage', o.data.successMessage as string).then((result) => {
				setData((prev) => {
					const objectives = { ...prev.objectives };
					if (!objectives[o.id]) objectives[o.id] = { title: '', clue: '', successMessage: '' };
					objectives[o.id].successMessage = result || o.data.successMessage;
					return { ...prev, objectives };
				});
			});
		});
	}, [mission]);

	return data;
}
