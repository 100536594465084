import { useTranslation } from 'react-i18next';
import Button from './Button';
import { raw } from '../../utils/textUtils';
import useTelemetry, { TelemetryEvents } from '../../hooks/useTelemetry';
import { useCallback, useEffect, useState } from 'react';
import { getXrApi } from '../../api/apiBridge';
import { useAppDispatch } from '../../redux/config/store';
import { getItemInventory } from '../../redux/inventory';

export default function RedeemPanel({ item, onClose }:{ item:IXRInventoryItemParsedData, onClose: () => void }) {
	const { t } = useTranslation();
	const { logEvent } = useTelemetry();
	const [code, setCode] = useState<string>(item?.playfab?.InstanceData?.GiveawayKey);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(!code);

	useEffect(() => {
		if (code) return;

		getXrApi().Client.ClaimGiveawayKey({ ItemInstanceId: item.playfab.ItemInstanceId }).then(resp => {
			setLoading(false);
			if (resp.success) {
				setCode(resp.data.GiveawayKey);
			} else {
				logEvent(TelemetryEvents.couponError({ error: resp.data?.error || resp.message }));
			}
			dispatch(getItemInventory());
		});
	}, [code, dispatch, setLoading, item]);

	const onClickCopy = useCallback(() => {
		navigator.clipboard.writeText(code);
		logEvent(TelemetryEvents.copyRewardCode({ item: item.playfab.ItemId, instanceId: item.playfab.ItemInstanceId }));
	}, [code, logEvent]);

	return <div className="redeem-panel">
		<div className="panel">
			{loading && <div className="title">{t('redeem_panel.loading')}</div>}
			{!loading && code && <>
				<div className="title">{t('redeem_panel.title', { name: item.playfab.DisplayName })}</div>
				<div className="subtitle">{t('redeem_panel.subtitle')}</div>
				<div className="code">{code}</div>
				<div className="text" {...raw(t('redeem_panel.text'))} />
				<Button label={t('redeem_panel.copy_button_label')} className='thin full-width' onClick={onClickCopy} />
			</>}
			{!loading && !code && <>
				<div className="title" {...raw(t('redeem_panel.no_code_title'))} />
				<div className="text" {...raw(t('redeem_panel.no_code_text'))} />
			</>}
			<Button label={t('redeem_panel.close_button_label')} className='secondary thin full-width' onClick={onClose} />
		</div>
	</div>;
}