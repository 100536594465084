import { NavLink } from 'react-router-dom';
import { CDN_BASE } from '../../Constants';
import AnimatedBottleCap from '../ui/AnimatedBottleCap';
import Bubble, { BUBBLE_THEMES } from '../ui/Bubble';
import { createPortal } from 'react-dom';
import BarcodeScannerModalNew from '../ui/BarcodeScannerModalNew';
import { AppRouting } from '../../AppRoutes';
import { setPollsTabOpened } from '../../redux/game';
import { useAppDispatch, useAppSelector } from '../../redux/config/store';
import { useCallback, useRef, useState } from 'react';
import { raw } from '../../utils/textUtils';
import { useTranslation } from 'react-i18next';
import useTelemetry, { TelemetryEvents } from '../../hooks/useTelemetry';
import { CSSTransition } from 'react-transition-group';
import Button from '../ui/Button';
import { updatePlayerData } from '../../redux/playerData';

export default function Home() {
	const { t } = useTranslation();
	const [showScanner, setShowScanner] = useState(false);
	const dispatch = useAppDispatch();
	const ref = useRef<HTMLDivElement>(null);
	const step1 = useRef<HTMLDivElement>(null);
	const step2 = useRef<HTMLDivElement>(null);
	const step3 = useRef<HTMLDivElement>(null);
	const btnsRef = useRef<HTMLDivElement>(null);
	const panelRef = useRef<HTMLDivElement>(null);
	const tutorialBg = useRef<HTMLDivElement>(null);

	const seenHomeTutorial = useAppSelector(state => state.playerData.data.seenHomeTutorial);

	const [step, setStep] = useState(1);

	const { logEvent } = useTelemetry();

	const onClickProductScan = () => {
		logEvent(TelemetryEvents.clickProductScan({}));
		setShowScanner(true);
	};

	const onClickCloseProductScan = () => {
		logEvent(TelemetryEvents.clickCloseProductScan({}));
		setShowScanner(false);
	};

	const onClickPolls = () => {
		dispatch(setPollsTabOpened(true));
		logEvent(TelemetryEvents.clickSurveys({}));
	};

	const [isTutorial, setIsTutorial] = useState(false);

	const onEntered = useCallback(() => {
		setIsTutorial(!seenHomeTutorial);
	}, [seenHomeTutorial]);

	const onClickNext = () => {
		setStep(s => s + 1);
	};

	const onClickProceed = () => {
		dispatch(updatePlayerData({ seenHomeTutorial: true }));
		setIsTutorial(false);
	};

	const onClickSkip = () => {
		dispatch(updatePlayerData({ seenHomeTutorial: true }));
		setIsTutorial(false);
	};

	return <CSSTransition nodeRef={ref} in={true} onEntered={onEntered} timeout={1200} appear mountOnEnter unmountOnExit>
		<div ref={ref} className={`home ${isTutorial ? 'tutorial' : ''}`}>
			<NavLink to={AppRouting.worldMap.index} className={`element treasure-hunt ${isTutorial && step === 1 ? 'isolate' : ''}`}>
				<AnimatedBottleCap />
				<img src={CDN_BASE + 'treasure-hunt-city.png'} width="446" height="456" alt="" />
				<div className="title" {...raw(t('home.treasure_hunt.title'))} />
				<Bubble theme={BUBBLE_THEMES.TREASURE_HUNT} />
			</NavLink>
			<div className={`element product-scan ${isTutorial && step === 2 ? 'isolate' : ''}`} onClick={onClickProductScan}>
				<div className="rotate"><img src={CDN_BASE + 'coke-bottle.png'} width="183" height="183" alt="" /></div>
				<div className="title" {...raw(t('home.product_scan.title'))} />
				<Bubble theme={BUBBLE_THEMES.WHITE} />
			</div>
			<div onClick={onClickPolls} className={`element polls ${isTutorial && step === 3 ? 'isolate' : ''}`}>
				<img src={CDN_BASE + 'poll-cube.png'} width="183" height="183" alt="" />
				<div className="title" {...raw(t('home.polls.title'))} />
				<Bubble theme={BUBBLE_THEMES.WHITE} />
			</div>
			{showScanner && createPortal(<BarcodeScannerModalNew onClickClose={onClickCloseProductScan} />, document.body)}
			<CSSTransition nodeRef={tutorialBg} in={isTutorial} timeout={600} appear unmountOnExit mountOnEnter>
				<div ref={tutorialBg} className="tutorial-bg"></div>
			</CSSTransition>
			<CSSTransition nodeRef={step1} in={isTutorial && step === 1} timeout={{ enter: 600, appear: 600, exit: 0 }} appear mountOnEnter unmountOnExit>
				<div ref={step1} className="tutorial-step step-1 element">
					<div className="text" {...raw(t('home.tutorial.step_1'))} />
					<Bubble theme={BUBBLE_THEMES.WHITE} />
				</div>
			</CSSTransition>
			<CSSTransition nodeRef={step2} in={isTutorial && step === 2} timeout={{ enter: 600, appear: 600, exit: 0 }} appear mountOnEnter unmountOnExit>
				<div ref={step2} className="tutorial-step step-2 element">
					<div className="text" {...raw(t('home.tutorial.step_2'))} />
					<Bubble theme={BUBBLE_THEMES.WHITE} />
				</div>
			</CSSTransition>
			<CSSTransition nodeRef={step3} in={isTutorial && step === 3} timeout={{ enter: 600, appear: 600, exit: 0 }} appear mountOnEnter unmountOnExit>
				<div ref={step3} className="tutorial-step step-3 element">
					<div className="text" {...raw(t('home.tutorial.step_3'))} />
					<Bubble theme={BUBBLE_THEMES.WHITE} />
				</div>
			</CSSTransition>

			<CSSTransition nodeRef={btnsRef} in={isTutorial && step <= 3} timeout={{ enter: 600, appear: 600, exit: 0 }} appear mountOnEnter unmountOnExit>
				<div ref={btnsRef} className="btns">
					<div onClick={onClickSkip} className="skip">{t('home.tutorial.skip_button_label')}</div>
					<Button onClick={onClickNext} className="cta" label={t('home.tutorial.next_button_label')} />
				</div>
			</CSSTransition>

			<CSSTransition nodeRef={panelRef} in={isTutorial && step === 4} timeout={{ enter: 1200, appear: 1200, exit: 0 }} appear mountOnEnter unmountOnExit>
				<div ref={panelRef} className="tutorial-panel-ctn">
					<div className="panel">
						<div className="title" {...raw(t('home.tutorial.panel.title'))} />
						<div className="text" {...raw(t('home.tutorial.panel.text'))} />
						<Button onClick={onClickProceed} className="full-width" label={t('home.tutorial.panel.button_label')} />
					</div>
				</div>
			</CSSTransition>
		</div>
	</CSSTransition>;
}