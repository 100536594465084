import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './ts/redux/config/store';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'swiper/css';
import './scss/index.scss';

import { BrowserRouter as Router } from 'react-router-dom';
import App from './ts/App';
import vh from './ts/utils/vh';
import SizeHelper from './ts/utils/SizeHelper';
import { BASE_PATH } from './ts/Constants';
import './ts/i18n';

import {
	EventType, PublicClientNext,
} from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ClientSideNavigation } from './ts/components/routes/ClientSideNavigation';
import { Suspense } from 'react';
import { getAppParams, getBasePath, getRegion, isValidRegion } from './ts/regionManagement';
import OutsideMarket from './ts/components/routes/OutsideMarket';

getRegion().then(() => {
	const ctx = document.documentElement;
	[
		vh,
		SizeHelper,
	].forEach(module => module.init(ctx));

	const root = ReactDOM.createRoot(
		document.getElementById('root') as HTMLElement,
	);

	if (!isValidRegion()) {
		history.replaceState(null, '', '/');
		root.render(<OutsideMarket />);
		return;
	}

	const { clientId, authority, authorityUrl } = getAppParams();

	const basePath = getBasePath();

	PublicClientNext.createPublicClientApplication({
		auth: {
			clientId: clientId,
			knownAuthorities: [authority],
			postLogoutRedirectUri: '/',
			authority: authorityUrl,
			redirectUri: '/',
			navigateToLoginRequestUrl: false,
		},
		cache: {
			cacheLocation: 'localStorage',
			storeAuthStateInCookie: false,
		},
		system: {
			allowRedirectInIframe: true,
			tokenRenewalOffsetSeconds: 10,
			loggerOptions: {
				logLevel: 4,
			},
		},
	}).then((app) => {
		app.enableAccountStorageEvents();
	
		const accounts = app.getAllAccounts();
	
		if (!app.getActiveAccount() && accounts.length > 0) {
			app.setActiveAccount(accounts[0]);
		}
	
		app.addEventCallback((event: any) => {
			if (
				event.eventType === EventType.LOGIN_SUCCESS ||
				event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
				event.eventType === EventType.SSO_SILENT_SUCCESS
			) {
				app.setActiveAccount(event.payload.account);
			}
		});
		
		if (window.location.pathname === '/' || !window.location.pathname.startsWith('/' + basePath)) {
			history.replaceState(null, '', '/' + basePath + window.location.search + window.location.hash);
		}
	
		root.render(
			<Router basename={BASE_PATH + basePath}>
				<ClientSideNavigation instance={app}>
					<MsalProvider instance={app}>
						<Provider store={store}>
							<Suspense>
								<App />
							</Suspense>
						</Provider>
					</MsalProvider>
				</ClientSideNavigation>
			</Router>,
		);
	});
});
