import { useRef, useCallback, useState, useEffect, CSSProperties } from 'react';
import useGlobalVariables from '../../hooks/useGlobalVariables';
import { updateLocalAvatarUrl, updateAvatarUrl } from '../../redux/playfab';
import { useAppDispatch } from '../../redux/config/store';
import usePlayer from '../../hooks/usePlayer';
import { CDN_BASE } from '../../Constants';
import Button from '../ui/Button';
import { AppRouting } from '../../AppRoutes';
import { useTranslation } from 'react-i18next';
import useTelemetry, { TelemetryEvents } from '../../hooks/useTelemetry';
import { CSSTransition } from 'react-transition-group';
import useBadges from '../../hooks/useBadges';
import QuestionMarkIcon from '../icons/QuestionMarkIcon';
import LockIcon from '../icons/LockIcon';

export default function AvatarSelection() {
	const { t } = useTranslation();
	const ref = useRef<HTMLDivElement>(null);

	const {
		avatars,
	} = useGlobalVariables();

	const {
		AvatarUrl,
	} = usePlayer();
	const [isFTUE] = useState(!AvatarUrl);

	const { logEvent } = useTelemetry();

	const [updatingAvatar, setUpdatingAvatar] = useState(false);
	const [canGoNext, setCanGoNext] = useState(Boolean(AvatarUrl));

	useEffect(() => {
		setCanGoNext(Boolean(AvatarUrl));
	}, [AvatarUrl]);

	const { badges } = useBadges();

	const dispatch = useAppDispatch();
	const debounceRef = useRef<number | null>(null);

	const onClick = useCallback((avatar:string) => {
		if (AvatarUrl === avatar || updatingAvatar) return;

		if (debounceRef.current) {
			clearTimeout(debounceRef.current);
		}

		dispatch(updateLocalAvatarUrl(avatar));

		setUpdatingAvatar(true);
		
		dispatch(updateAvatarUrl(avatar)).then(() => {
			logEvent(TelemetryEvents.avatarSelected({ avatar }));
			setCanGoNext(true);
			setUpdatingAvatar(false);
		});
	}, [dispatch, AvatarUrl, updatingAvatar]);


	let i = 0;

	badges.sort((a, b) => {
		if (a.owned === b.owned) return a.itemId.localeCompare(b.itemId);
		return a.owned ? -1 : 1;
	});

	return <CSSTransition nodeRef={ref} in={true} timeout={2400} appear mountOnEnter unmountOnExit>
		<div ref={ref} className="avatar-selection">
			<div className="top">
				<div className="title">{t('avatar_selection.title')}</div>
				<div className="subtext">{t('avatar_selection.subtext')}</div>
			</div>
			<div className="panel">
				<div className="avatar-grid">
					{avatars.map((avatar) => {
						return <div className="avatar" style={{ '--i': ++i } as CSSProperties} key={avatar} onClick={() => onClick(avatar)}>
							<img src={avatar} />
							{AvatarUrl === avatar && <img className='selection' src={CDN_BASE + 'avatars/bubble-selected.png'} width="100" height="100" alt="" />}
							{AvatarUrl !== avatar && <img className='selection' src={CDN_BASE + 'static-bubble-white.png'} width="100" height="100" alt="" />}
						</div>;
					})}
					{badges.map((badge) => {
						return <div className={`avatar ${badge.owned ? '' : 'disabled'}`} style={{ '--i': ++i } as CSSProperties} key={badge.itemId} onClick={() => onClick(badge.playfab.ItemImageUrl)}>
							<img className='icon' src={badge.playfab.ItemImageUrl} />
							{!badge.owned && <div className="locked">
								<LockIcon />
							</div> }
							{AvatarUrl === badge.playfab.ItemImageUrl && <img className='selection' src={CDN_BASE + 'avatars/bubble-selected.png'} width="100" height="100" alt="" />}
							{AvatarUrl !== badge.playfab.ItemImageUrl && <img className='selection' src={CDN_BASE + 'static-bubble-white.png'} width="100" height="100" alt="" />}
						</div>;
					})}
				</div>
			</div>
			{<div className={`btn-ctn ${canGoNext && !updatingAvatar ? 'active' : ''}`}>
				<Button to={isFTUE ? AppRouting.root : AppRouting.you.index} className="full-width" label={isFTUE ? t('avatar_selection.ready_button_label') : t('avatar_selection.submit_button_label')} />
			</div>}
		</div>
	</CSSTransition>;
}