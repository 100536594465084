import { useCallback } from 'react';
import useGAM from './useGAM';
import { STORE_NAME, XR_CONFIG } from '../Constants';
import useTelemetry, { TelemetryEvents } from './useTelemetry';

export function useLogout() {
	const { logout: cokeLogout } = useGAM();

	const { logEvent } = useTelemetry();

	const logout = useCallback(() => {
		logEvent(TelemetryEvents.clickLogout({}));
		
		localStorage.removeItem(STORE_NAME);
		localStorage.removeItem(XR_CONFIG.appId + '_' + STORE_NAME + '_PLAYFABXR_SESSION_TICKET');
		localStorage.removeItem(XR_CONFIG.appId + '_' + STORE_NAME + '_PLAYFABXR_PLAYFABID');
		localStorage.removeItem(XR_CONFIG.appId + '_' + STORE_NAME + '_PLAYFABXR_ENTITY_TOKEN');
		localStorage.removeItem(STORE_NAME + '_last_map_center');
		localStorage.removeItem(STORE_NAME + '_player_positions');
		localStorage.removeItem(STORE_NAME + '_seen_modals');
		cokeLogout();
	}, [logEvent]);

	return {
		logout,
	};
}