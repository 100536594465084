import { ReactElement } from 'react';
import ChevronDown from '../icons/ChevronDown';

type SelectProps = {
	placeholder: string,
	children?: ReactElement<HTMLOptionElement>[],
	onChange?: (e:string) => void,
	value?: string,
};

export default function Select({ placeholder, children, onChange, value }:SelectProps) {
	function onChangeSelect(e:React.ChangeEvent<HTMLSelectElement>) {
		onChange(e.currentTarget.value);
	}

	return (
		<label className="select box">
			<span className="text">{placeholder}</span>
			<div className="arrow">
				<ChevronDown />
			</div>
			<select onChange={onChangeSelect} value={value}>
				{children}
			</select>
		</label>
	);
}