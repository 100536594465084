import { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useLocalization from '../../hooks/useLocalization';
import { raw } from '../../utils/textUtils';

export const Translation = memo(({ id, fallback, asRaw = false, nl2br = false }:{ id: string, fallback: string, asRaw?:boolean, nl2br?: boolean }) => {
	const { i18n } = useTranslation();
	const lang = i18n.language;

	const [localizedString, setLocalizedString] = useState<string>(fallback);

	const { string } = useLocalization();

	useEffect(() => {
		string(id, fallback).then((result) => {
			setLocalizedString(result || fallback);
		});
	}, [id, lang, fallback]);

	const text = nl2br ? localizedString.replace(/\n/g, '<br />') : localizedString;

	return asRaw ? <div {...raw(text)} /> : <>{text}</>;
});