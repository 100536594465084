function MenuIcon() {
	return (
		<svg className="menu-icon" xmlns="http://www.w3.org/2000/svg" fill="none" width="90" height="90" viewBox="0 0 90 90">
			<path
				fill="#fff"
				fillOpacity="0.2"
				stroke="url(#menu-icon-a)"
				strokeWidth="10"
				d="M85 45c0 22.091-17.909 40-40 40S5 67.091 5 45 22.909 5 45 5s40 17.909 40 40z"
			></path>
			<defs>
				<linearGradient
					id="menu-icon-a"
					x1="0"
					x2="90"
					y1="45"
					y2="45"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F40009" stopOpacity="0.6"></stop>
					<stop offset="0.45" stopColor="#F79900" stopOpacity="0.6"></stop>
					<stop offset="1" stopColor="#6AC9CE" stopOpacity="0.6"></stop>
				</linearGradient>
			</defs>
		</svg>
	);
}

export default MenuIcon;
