function CheckmarkIcon() {
	return (
		<svg className="checkmark-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 11" width="14" height="11">
			<path
				fill="currentColor"
				d="M.761 5.828a.526.526 0 01-.146-.343c0-.098.048-.247.146-.345l.689-.686a.476.476 0 01.689 0l.048.047 2.704 2.901a.237.237 0 00.343 0l6.583-6.83h.05a.475.475 0 01.69 0l.686.688a.473.473 0 010 .686l-7.86 8.158a.447.447 0 01-.346.146.44.44 0 01-.343-.146L.86 5.977l-.1-.149z"
			></path>
		</svg>
	);
}

export default CheckmarkIcon;