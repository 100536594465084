import { useSelector } from 'react-redux';
import { IRootState, useAppDispatch } from '../redux/config/store';
import { getPlayerData, updatePlayerData } from '../redux/playerData';
import { useEffect } from 'react';
import usePlayer from './usePlayer';

export default function usePlayerData() {
	const playerData = useSelector((state:IRootState) => state.playerData.data);
	const { playerId } = usePlayer();
	const isPlayerDataLoaded = useSelector((state:IRootState) => state.playerData.loaded);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!isPlayerDataLoaded && playerId) {
			dispatch(getPlayerData());
		}
	}, [dispatch, isPlayerDataLoaded, playerId]);

	return {
		playerData,
		isPlayerDataLoaded,
		updatePlayerData: (data:Record<string, unknown>, permission:'Public' | 'Private' = 'Private') => dispatch(updatePlayerData({ ...data, permission })),
		refresh: () => dispatch(getPlayerData()),
	};
}