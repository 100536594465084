import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { CSSTransition } from 'react-transition-group';
import { AppRouting } from '../../../AppRoutes';
import { RewardTypes } from '../../../api/enums';
import useMissions from '../../../hooks/useMissions';
import AnimatedBottleCap from '../AnimatedBottleCap';
import Button from '../Button';
import useCatalog from '../../../hooks/useCatalog';
import { ITEM_CLASSES } from '../../../Constants';
import { useTranslation } from 'react-i18next';
import { Translation } from '../../utils/Translation';
import { useEffect, useRef } from 'react';
import useTelemetry, { TelemetryEvents } from '../../../hooks/useTelemetry';
import Bubble, { BUBBLE_THEMES } from '../Bubble';

function SingleReward({ itemId }) {
	const { t } = useTranslation();
	const { items } = useCatalog();
	const item = items.find(i => i.itemId === itemId);
	const isGiftCard = item?.type.title === ITEM_CLASSES.GIFT_CARD;

	const displayName = <Translation id={item?.itemId + '/playfab/DisplayName'} fallback={item?.playfab.DisplayName} />;

	return item && <>
		<img className={item.type.title.toLowerCase()} src={item.playfab.ItemImageUrl} alt="" />
		<div className="reward-subtitle">{displayName}</div>
		<div className="btn-ctn">{isGiftCard && <Button className="thin secondary" to={AppRouting.rewards.index} label={t('rewards_panel.single_reward.button_label')} />}</div>
	</>;
}

export default function RewardsPanel({ Instance, Objective, PlayerStatus, onClose }:SendMissionInputResponse & { onClose: () => void }) {
	const { logEvent } = useTelemetry();
	const { t } = useTranslation();
	const objRef = useRef<HTMLDivElement>(null);
	const panelRef = useRef<HTMLDivElement>(null);
	const missions = useMissions();
	const mission = missions.find((m) => m.itemId === Instance?.ItemId);
	const objective = mission?.objectives?.find?.((o) => o.id === Objective.id) as IXRMissionObjectiveParsedData;
	const isMissionComplete = PlayerStatus.IsComplete;

	const capReward = parseInt(objective?.rewards?.find((r) => r.dataKey.toLowerCase().includes('xp'))?.dataVal as string || '0');
	const missionReward = parseInt(mission?.rewards?.find((r) => r.dataKey.toLowerCase().includes('xp'))?.dataVal as string || '0');

	const itemRewards = mission?.rewards?.filter(r => {
		return r.dataType === RewardTypes.ItemGrant;
	}) || [];

	itemRewards.sort((a) => {
		const aItem = useCatalog().items.find(i => i.itemId === a.dataVal);
		return aItem?.type.title === ITEM_CLASSES.GIFT_CARD ? -1 : 1;
	});

	useEffect(() => {
		logEvent(TelemetryEvents.openRewardsPanel({ missionId: mission?.itemId, objectiveId: objective?.id }));

		return () => {
			logEvent(TelemetryEvents.closeRewardsPanel({ missionId: mission?.itemId, objectiveId: objective?.id }));
		};
	}, [logEvent, mission, objective]);

	if (!isMissionComplete) {
		return (
			<CSSTransition nodeRef={objRef} in={true} timeout={1200} appear>
				<div className="rewards-panel objective" ref={objRef}>
					<div className="objective-rewards">
						<AnimatedBottleCap />
						<div className="bubble-ctn">
							<div className="content">
								<div className="title">{t('rewards_panel.subtitle')}</div>
								<div className="status">{t('rewards_panel.clues_left', { count: PlayerStatus.TotalCompleted, total: PlayerStatus.Total })}</div>
								<div className="description">{t('rewards_panel.text_clues_left_' + (PlayerStatus.Total - PlayerStatus.TotalCompleted))}</div>
							</div>
							<Bubble theme={BUBBLE_THEMES.TREASURE_HUNT} />
						</div>
						<div className="xp-bubble">
							<div className="content">+{capReward}XP</div>
							<Bubble theme={BUBBLE_THEMES.WHITE} />
						</div>
					</div>
					<Button className="thin" label={t('rewards_panel.continue_button_label')} onClick={() => onClose()} />
				</div>
			</CSSTransition>
		);
	}

	return mission && (
		<CSSTransition nodeRef={panelRef} in={true} timeout={2400} appear>
			<div className="rewards-panel" ref={panelRef}>
				<div className="panel rewards-slider">
					<div className="title">{t('rewards_panel.completed.title')}</div>
					<div className="subtitle">{t('rewards_panel.completed.reward_subtitle')}</div>
					<Swiper
						modules={[Navigation, Pagination]}
						navigation
						pagination={{ clickable: true }}
						onSlideChange={(swiper) => {
							logEvent(TelemetryEvents.swipeRewardsPanel({ missionId: mission?.itemId, objectiveId: objective?.id, slide: swiper.activeIndex }));
						}}
					>
						{isMissionComplete && <>
							{itemRewards.map((r, i) => <SwiperSlide key={i}>
								<SingleReward itemId={r.dataVal} />
							</SwiperSlide>)}
						</>}
					</Swiper>
					
					<div className="rewards">
						<div className="title">{t('rewards_panel.rewards.title')}</div>
						<div className="items">
							<div className="item">
								<div className="name">{t('rewards_panel.rewards.cap_found')}</div>
								<div className="stat">+{capReward}XP</div>
							</div>
							{isMissionComplete && missionReward && (
								<div className="item">
									<div className="name">{t('rewards_panel.rewards.mission_completion')}</div>
									<div className="stat">+{missionReward}XP</div>
								</div>
							)}
							{isMissionComplete && <>
								<div className="total item">
									<div className="name"><strong>{t('rewards_panel.rewards.total')}</strong></div>
									<div className="stat">{capReward + (isMissionComplete ? missionReward : 0)}XP</div>
								</div>
							</>}
						</div>
					</div>
				</div>
				<div className="btn-ctn">
					{!mission?.PlayerStatus.IsComplete && <Button className="thin full-width" label={t('rewards_panel.continue_button_label')} onClick={() => onClose()} />}
					{mission?.PlayerStatus.IsComplete && <Button className="thin full-width" label={t('rewards_panel.close_button_label')} to={AppRouting.missions.index} />}
				</div>
			</div>
		</CSSTransition>);
}