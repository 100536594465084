import slugify from 'slugify';
import { CDN_BASE, ITEM_CLASSES, MISSIONS_TYPES } from '../../../Constants';
import Button from '../../ui/Button';
import { createPortal } from 'react-dom';
import BarcodeScannerModalNew from '../../ui/BarcodeScannerModalNew';
import { AppRouting } from '../../../AppRoutes';
import { CSSProperties, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTranslatedMission from '../../../hooks/useTranslatedMission';
import useTelemetry, { TelemetryEvents } from '../../../hooks/useTelemetry';
import useMissions from '../../../hooks/useMissions';
import useCatalog from '../../../hooks/useCatalog';
import GiftCardIcon from '../../icons/GiftCardIcon';

type MissionProps = {
	mission: IXRMissionItem & { isActiveMission: boolean },
	activeMissionId: string,
	setActiveMission: (missionId: string) => void,
};

function findItemByClass(itemClass:string, rewards: IXRMissionItem['rewards'], items: IXRInventoryItemParsedData[]) {
	const grants = rewards.filter(r => r.dataType === 'item_grant');
	if (grants.length === 0) return null;

	for (const grant of grants) {
		const item = items.find(i => i.itemId === grant.dataVal);
		if (item?.type?.title === itemClass) return item;
	}

	return null;
}

function getRewards(rewards: IXRMissionItem['rewards']) {
	const { items } = useCatalog();
	const { t } = useTranslation();
	const xp = rewards.find(r => r.dataKey.toLowerCase().includes('xp'));
	const badge = findItemByClass(ITEM_CLASSES.BADGE, rewards, items);
	const giftCard = findItemByClass(ITEM_CLASSES.GIFT_CARD, rewards, items);
	let i = 0;
	return <div className="rewards">
		<div className="reward-element xp" style={{ '--i': ++i } as CSSProperties}>{xp?.dataVal}XP</div>
		{badge && <>
			<div className="reward-element plus" style={{ '--i': ++i } as CSSProperties}>+</div>
			<img className="reward-element badge" style={{ '--i': ++i } as CSSProperties} src={badge.playfab.ItemImageUrl} />
		</>}
		{giftCard && <>
			<div className="reward-element plus" style={{ '--i': ++i } as CSSProperties}>+</div>
			<div className="reward-element"><GiftCardIcon><div className="currency" style={{ '--i': ++i } as CSSProperties}>{t('common.currency')}</div></GiftCardIcon></div>
		</>}
	</div>;
}

export default function Mission({ mission, activeMissionId, setActiveMission }: MissionProps) {
	const { logEvent } = useTelemetry();
	const { t } = useTranslation();
	const missions = useMissions();
	const tMission = useTranslatedMission(mission);
	const inInventory = Boolean(mission.PlayerStatus);
	const isCompleted = mission.PlayerStatus?.IsComplete;
	const isActiveSelected = Boolean(activeMissionId) && missions.find(m => m.itemId === activeMissionId && m.PlayerStatus && !m.PlayerStatus.IsComplete);
	const isActive = isActiveSelected && mission.isActiveMission;
	const isProductScan = mission.type.title === MISSIONS_TYPES.PRODUCT_SCAN;
	const isTreasureHunt = mission.type.title === MISSIONS_TYPES.TREASURE_HUNT;

	const className = slugify(mission.type.title, { lower: true });

	const [selectedProductScan, setSelectedProductScan] = useState<string>(null);

	const showMissionCompleted = inInventory && isCompleted;
	const showJoin = isTreasureHunt && inInventory && !isCompleted && !isActiveSelected;
	const showGoTo = isTreasureHunt && !isCompleted && isActive;
	const showClues = true;
	const missionLocked = (
		(isTreasureHunt && !isActive && !isCompleted) ||
		(isProductScan && !inInventory)
	);

	let typeText = t('missions.types.' + slugify(mission.type.title, { lower: true }).replaceAll('-', '_'));
	if (isTreasureHunt && isActive) typeText = t('missions.status.active');
	else if (isCompleted) typeText = t('missions.status.completed');
	else if (isTreasureHunt && !showJoin) typeText = t('missions.status.locked');

	const completedObjectives = mission.objectives.filter(o => o.PlayerStatus?.IsComplete).length;

	const typeClass = slugify(mission.type.title, { lower: true });

	const onClickJoinMission = useCallback(() => {
		setActiveMission(mission.itemId);
		logEvent(TelemetryEvents.clickJoinMission({ missionId: mission.itemId }));
	}, [logEvent, setActiveMission, mission.itemId]);

	const onClickProductScan = useCallback(() => {
		setSelectedProductScan(mission.itemId);
		logEvent(TelemetryEvents.clickScanProduct({ missionId: mission.itemId }));
	}, [logEvent, mission.itemId]);

	const onClickCloseProductScan = useCallback(() => {
		setSelectedProductScan(null);
		logEvent(TelemetryEvents.clickCloseScanProduct({ missionId: mission.itemId }));
	}, [logEvent, mission.itemId]);

	return (
		<>
			<div className={`content ${typeClass}`}>
				<div className="scrollable">
					<div className={`type ${isTreasureHunt && missionLocked ? 'locked' : ''}`}>{typeText}</div>
					<div className="title">{tMission.displayName}</div>
					<p>{tMission.description}</p>
					{getRewards(mission.rewards)}
					<div className="additional-infos">{mission.data.additionalInfos as string || t(`missions.content.${isTreasureHunt ? 'treasure_hunt' : 'product_scan'}_additional_infos`)}</div>
				</div>
				<div className="bottom">
					{showClues && (
						<>
							<div className="clues">
								<div className="list">
									{mission.objectives.map((clue:IXRMissionObjectiveParsedData, index) => <div key={index} className={`clue ${clue?.PlayerStatus?.IsComplete ? 'completed' : ''} ${clue.data.productImage ? 'product' : ''}`} style={{ '--i': index } as CSSProperties}>
										{clue.data.productImage && <img src={clue.data.productImage} alt="" width="160" height="248" />}
										{!clue.data.productImage && <img src={CDN_BASE + 'bottle-cap.png'} width="100" height="100" alt="" />}
									</div>)}
								</div>
								{isTreasureHunt && <div className="clues-count">
									{t('missions.caps', { count: completedObjectives })}
								</div>}
							</div>
						</>
					)}
					{showJoin && <Button className='thin full-width' onClick={onClickJoinMission} label={t('missions.button_labels.join_mission')} />}
					{showGoTo && <Button className='thin full-width secondary' onClick={() => logEvent(TelemetryEvents.clickOpenMap({}))} to={AppRouting.worldMap.index} label={t('missions.button_labels.open_map_explore')} />}
					{showMissionCompleted && <Button className={'thin full-width ' + className} disabled label={t('missions.button_labels.mission_complete')} />}
					{isTreasureHunt && !showGoTo && !showMissionCompleted && !showJoin && <Button className='thin full-width locked' disabled label={t('missions.button_labels.mission_locked')} />}
					{isProductScan && !isCompleted && !missionLocked && <Button className='thin full-width' label={t('missions.button_labels.scan_product')} onClick={onClickProductScan} />}
					{isProductScan && missionLocked && <Button className='thin full-width locked' disabled label={t('missions.button_labels.mission_locked')} />}
				</div>
			</div>
			{selectedProductScan && createPortal(<BarcodeScannerModalNew missionId={selectedProductScan} onClickClose={onClickCloseProductScan} />, document.body)}
		</>
	);
}