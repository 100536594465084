import { useCallback } from 'react';
import { getXrApi } from '../api/apiBridge';
import usePlayer from './usePlayer';

type TelemetryEvent = [name: string, properties?: Record<string, any>, dataLayerProperties?: Record<string, any>];

export const TelemetryEvents = {
	pageView: (props:{ location: string }):TelemetryEvent => ['page_view', props, { event : 'cgp_event', event_name : 'page_view', event_detail : 'page_view', param1_key : 'location', param1_val : props.location }],
	answerSurvey: (props:{ surveyId: string, answers: Record<string, string> }):TelemetryEvent => ['answer_survey', props, { event : 'cgp_event', event_name : 'conversion', event_detail : 'answer_survey', param1_key : 'survey_id', param1_val : props.surveyId }],
	answerSurveyError: (props:{ surveyId: string, answers, error: string }):TelemetryEvent => ['answer_survey_error', props, { event : 'cgp_event', event_name : 'error', event_detail : 'answer_survey_error', param1_key : 'survey_id', param1_val : props.surveyId, param2_key : 'error', param2_val : props.error }],
	appStateChanged: (props:{ appState: string }):TelemetryEvent => ['app_state_changed', props, { event : 'cgp_event', event_name : 'metadata_event', event_detail : 'app_state_changed', param1_key : 'app_state', param1_val : props.appState }],
	avatarSelected: (props:{ avatar: string }):TelemetryEvent => ['avatar_selected', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'avatar_selected', param1_key : 'avatar', param1_val : props.avatar }],
	claimMarker: (props:{ poi: string, missionId: string, objectiveId: number }):TelemetryEvent => ['claim_marker', props, { event : 'cgp_event', event_name : 'conversion', event_detail : 'claim_marker', param1_key : 'mission_id', param1_val : props.missionId, param2_key : 'objective_id', param2_val : props.objectiveId, param3_key : 'poi', param3_val : props.poi }],
	clickAcceptAgeGate: (props?:object):TelemetryEvent => ['click_accept_age_gate', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_accept_age_gate' }],
	clickCenterMap: (props?:object):TelemetryEvent => ['click_center_map', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_center_map' }],
	clickCloseMissions: (props?:object):TelemetryEvent => ['click_close_missions', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_close_missions' }],
	clickCloseSurveys: (props?:object):TelemetryEvent => ['click_close_surveys', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_close_surveys' }],
	clickCloseProductScan: (props?:object):TelemetryEvent => ['click_close_product_scan', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_close_product_scan' }],
	clickCloseScanProduct: (props:{ missionId: string }):TelemetryEvent => ['click_close_scan_product', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_close_scan_product', param1_key : 'mission_id', param1_val : props.missionId }],
	clickCreateAccount: (props?:object):TelemetryEvent => ['click_create_account', props, { event : 'cgp_event', event_name : 'conversion', event_detail : 'click_create_account' }],
	clickJoinMission: (props:{ missionId: string }):TelemetryEvent => ['click_join_mission', props, { event : 'cgp_event', event_name : 'conversion', event_detail : 'click_join_mission', param1_key : 'mission_id', param1_val : props.missionId }],
	clickLogIn: (props?:object):TelemetryEvent => ['click_log_in', props, { event : 'cgp_event', event_name : 'conversion', event_detail : 'click_log_in' }],
	clickLogout: (props?:object):TelemetryEvent => ['click_logout', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_logout' }],
	clickMarker: (props:{ poi: string, missionId: string, objectiveId: number }):TelemetryEvent => ['click_marker', props, { event : 'cgp_event', event_name : 'conversion', event_detail : 'click_marker', param1_key : 'mission_id', param1_val : props.missionId, param2_key : 'objective_id', param2_val : props.objectiveId, param3_key : 'poi', param3_val : props.poi }],
	clickNextFtueSlide: (props:{ slide: number }):TelemetryEvent => ['click_next_ftue_slide', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_next_ftue_slide', param1_key : 'slide', param1_val : props.slide }],
	clickNotification: (props:{ notificationId: string, notification?: string }):TelemetryEvent => ['click_notification', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_notification', param1_key : 'notification', param1_val : props.notification, param2_key : 'notification_id', param2_val : props.notificationId }],
	clickNotificationSurvey: (props:{ notificationId: string, surveyId?: string }):TelemetryEvent => ['click_notification_survey', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_notification_survey', param1_key : 'survey_id', param1_val : props.surveyId, param2_key : 'notification_id', param2_val : props.notificationId }],
	clickOpenMap: (props?:object):TelemetryEvent => ['click_open_map', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_open_map' }],
	clickOpenNotifications: (props:{ pendingSurveys: number }):TelemetryEvent => ['click_open_notifications', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_open_notifications', param1_key : 'pending_surveys', param1_val : props.pendingSurveys }],
	clickSurveyChoice: (props:{ surveyId: string, questionId: string, answer: string }):TelemetryEvent => ['click_survey_choice', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_survey_choice', param1_key : 'survey_id', param1_val : props.surveyId, param2_key : 'question_id', param2_val : props.questionId, param3_key : 'answer', param3_val : props.answer }],
	clickSurveys: (props?:object):TelemetryEvent => ['click_surveys', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_surveys' }],
	clickProceedFtueSlide: (props?:object):TelemetryEvent => ['click_proceed_ftue_slide', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_proceed_ftue_slide' }],
	clickProductScan: (props?:object):TelemetryEvent => ['click_product_scan', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_product_scan' }],
	clickRedeemReward: (props:{ itemId: string, itemInstanceId: string }):TelemetryEvent => ['click_redeem_reward', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_redeem_reward', param1_key : 'item_id', param1_val : props.itemId, param2_key : 'item_instance_id', param2_val : props.itemInstanceId }],
	clickRejectAgeGate: (props?:object):TelemetryEvent => ['click_reject_age_gate', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_reject_age_gate' }],
	clickScanProduct: (props:{ missionId: string }):TelemetryEvent => ['click_scan_product', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_scan_product', param1_key : 'mission_id', param1_val : props.missionId }],
	clickShowMissions: (props?:object):TelemetryEvent => ['click_show_missions', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_show_missions' }],
	clickSkipFtueSlide: (props?:object):TelemetryEvent => ['click_skip_ftue_slide', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_skip_ftue_slide' }],
	clickViewClues: (props:{ missionId: string }):TelemetryEvent => ['click_view_clues', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'click_view_clues', param1_key : 'mission_id', param1_val : props.missionId }],
	closeBarcodeTutorial: (props?:object):TelemetryEvent => ['close_barcode_tutorial', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'close_barcode_tutorial' }],
	closeSurvey: (props:{ surveyId: string }):TelemetryEvent => ['close_survey', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'close_survey', param1_key : 'survey_id', param1_val : props.surveyId }],
	closeSurveys: (props?:object):TelemetryEvent => ['close_surveys', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'close_surveys' }],
	closeRewardsPanel: (props:{ missionId: string, objectiveId: number }):TelemetryEvent => ['close_rewards_panel', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'close_rewards_panel', param1_key : 'mission_id', param1_val : props.missionId, param2_key : 'objective_id', param2_val : props.objectiveId }],
	closeScanner: (props?:object):TelemetryEvent => ['close_scanner', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'close_scanner' }],
	completeMission: (props:{ missionId: string }):TelemetryEvent => ['complete_mission', props, { event : 'cgp_event', event_name : 'conversion', event_detail : 'complete_mission', param1_key : 'mission_id', param1_val : props.missionId }],
	copyRewardCode: (props:{ item: string, instanceId: string }):TelemetryEvent => ['copy_reward_code', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'copy_reward_code', param1_key : 'item_id', param1_val : props.item, param2_key : 'item_instance_id', param2_val : props.instanceId }],
	couponError: (props:{ error: string }):TelemetryEvent => ['coupon_giveaway_error', props, { event : 'cgp_event', event_name : 'error', event_detail : 'coupon_error', param1_key : 'error', param1_val : props.error }],
	earnBadge: (props:{ badgeId: string }):TelemetryEvent => ['earn_badge', props, { event : 'cgp_event', event_name : 'conversion', event_detail : 'earn_badge', param1_key : 'badge_id', param1_val : props.badgeId }],
	geolocationError: (props:{ error: string }):TelemetryEvent => ['geolocation_error', props, { event : 'cgp_event', event_name : 'error', event_detail : 'geolocation_error', param1_key : 'error', param1_val : props.error }],
	geolocationWatchError: (props:{ error: string }):TelemetryEvent => ['geolocation_watch_error', props, { event : 'cgp_event', event_name : 'error', event_detail : 'geolocation_watch_error', param1_key : 'error', param1_val : props.error }],
	introModalClose: (props:{ missionId: string, type: string }):TelemetryEvent => ['intro_modal_close', props, { event : 'cgp_event', event_name : 'metadata_event', event_detail : 'intro_modal_close', param1_key : 'mission_id', param1_val : props.missionId, param2_key : 'mission_type', param2_val : props.type }],
	introModalOpen: (props:{ missionId: string, type: string }):TelemetryEvent => ['intro_modal_open', props, { event : 'cgp_event', event_name : 'metadata_event', event_detail : 'intro_modal_open', param1_key : 'mission_id', param1_val : props.missionId, param2_key : 'mission_type', param2_val : props.type }],
	nextSurveyQuestion: (props:{ surveyId: string, questionId: string }):TelemetryEvent => ['next_survey_question', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'next_survey_question', param1_key : 'survey_id', param1_val : props.surveyId, param2_key : 'question_id', param2_val : props.questionId }],
	openSurvey: (props:{ surveyId: string }):TelemetryEvent => ['open_survey', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'open_survey', param1_key : 'survey_id', param1_val : props.surveyId }],
	openRewardsPanel: (props:{ missionId: string, objectiveId: number }):TelemetryEvent => ['open_rewards_panel', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'open_rewards_panel', param1_key : 'mission_id', param1_val : props.missionId, param2_key : 'objective_id', param2_val : props.objectiveId }],
	surveyAvailable: (props:{ surveyId: string }):TelemetryEvent => ['survey_available', props, { event : 'cgp_event', event_name : 'metadata_event', event_detail : 'survey_available', param1_key : 'survey_id', param1_val : props.surveyId }],
	scanDailyCodeError: (props:{ error: string }):TelemetryEvent => ['scan_daily_code_error', props, { event : 'cgp_event', event_name : 'scan', event_detail : 'scan_daily_code_error', param1_key : 'error', param1_val : props.error }],
	scanDailyCodeSuccess: (props:{ code: string }):TelemetryEvent => ['scan_daily_code_success', props, { event : 'cgp_event', event_name : 'conversion', event_detail : 'scan_daily_code_success', param1_key : 'code', param1_val : props.code }],
	scanMissionCodeError: (props:{ error: string }):TelemetryEvent => ['scan_mission_code_error', props, { event : 'cgp_event', event_name : 'error', event_detail : 'scan_mission_code_error', param1_key : 'error', param1_val : props.error }],
	scanMissionCodeSuccess: (props:{ code: string, missionId: string }):TelemetryEvent => ['scan_mission_code_success', props, { event : 'cgp_event', event_name : 'conversion', event_detail : 'scan_mission_code_success', param1_key : 'code', param1_val : props.code,  param2_key : 'mission_id', param2_val : props.missionId }],
	scannedCode: (props:{ code: string }):TelemetryEvent => ['scanned_code', props, { event : 'cgp_event', event_name : 'scan', event_detail : 'scanned_code', param1_key : 'code', param1_val : props.code }],
	setAvailableCameras: (props:{ cameras: string[] }):TelemetryEvent => ['set_available_cameras', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'set_available_cameras' }],
	showScanner: (props?:object):TelemetryEvent => ['show_scanner', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'show_scanner' }],
	swipeNextFtueSlide: (props:{ slide: number }):TelemetryEvent => ['swipe_next_ftue_slide', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'swipe_next_ftue_slide', param1_key : 'slide', param1_val : props.slide }],
	swipePrevFtueSlide: (props:{ slide: number }):TelemetryEvent => ['swipe_prev_ftue_slide', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'swipe_prev_ftue_slide', param1_key : 'slide', param1_val : props.slide	 }],
	swipeRewardsPanel: (props:{ missionId: string, objectiveId: number, slide: number }):TelemetryEvent => ['swipe_rewards_panel', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'swipe_rewards_panel', param1_key : 'mission_id', param1_val : props.missionId, param2_key : 'objective_id', param2_val : props.objectiveId, param3_key : 'slide', param3_val : props.slide }],
	timeSpentFtueSlider: (props:{ duration: number }):TelemetryEvent => ['time_spent_ftue_slider', props, { event : 'cgp_event', event_name : 'metadata_event', event_detail : 'time_spent_ftue_slider', param1_key : 'duration', param1_val : props.duration }],
	useDailyInstead: (props:{ code: string }):TelemetryEvent => ['use_daily_instead', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'use_daily_instead', param1_key : 'code', param1_val : props.code }],
	useMissionInstead: (props:{ code: string, mission: string }):TelemetryEvent => ['use_mission_instead', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'use_mission_instead', param1_key : 'code', param1_val : props.code, param2_key : 'mission', param2_val : props.mission }],
	xpEarned: (props:{ amount: number, name: string, previousAmount: number }):TelemetryEvent => ['xp_earned', props, { event : 'cgp_event', event_name : 'conversion', event_detail : 'xp_earned', param1_key : 'xp_name', param1_val : props.name, param2_key : 'xp_amount', param2_val : props.amount, param3_key : 'xp_previous_amt', param3_val : props.previousAmount }],
	playerMoved: (props:{ amount: number }):TelemetryEvent => ['player_moved', props, { event : 'cgp_event', event_name : 'interaction', event_detail : 'player_moved', param1_key : 'amount', param1_val : props.amount }],
};

declare global {
	interface Window {
		dataLayer?: object[];
	}
}

window.dataLayer = window.dataLayer || [];

export default function useTelemetry() {
	const { playerId } = usePlayer();

	const logEvent = useCallback(([name, properties, dataLayerProperties]:TelemetryEvent) => {
		
		const event = {
			Namespace: 'custom.tccc.analytics',
			Name: name,
			Payload: JSON.stringify({
				...properties,
				playFabId: playerId,
				contextLocation: window.location.pathname,
			}),
		};
		// console.log('logEvent', event);
		console.log(`analytics %c${event.Name}`, 'color: teal;', event.Payload);

		window.dataLayer.push(dataLayerProperties);

		if (!playerId) return;

		try {
			getXrApi().Client.WriteTelemetryEvent(event);
		} catch (e) {
			// console.error('Failed to log event', e);
		}
	}, [playerId]);

	return {
		logEvent,
	};
}