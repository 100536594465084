function LockIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="14" height="20" viewBox="0 0 14 20">
			<path
				fill="currentColor"
				d="M.25 8.889H14v11.11H.25V8.89zm1.146-1.111h2.292V5.556c0-1.834 1.547-3.334 3.437-3.334 1.891 0 3.438 1.5 3.438 3.334v2.222h2.291V5.556C12.854 2.489 10.288 0 7.125 0 3.963 0 1.396 2.489 1.396 5.556v2.222z"
			></path>
		</svg>
	);
}

export default LockIcon;
