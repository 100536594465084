import { useTranslation } from 'react-i18next';
import { LOCALIZATION_BASE, STORE_NAME } from '../Constants';

const key = STORE_NAME + '/localization';

type TranslationFile = {
	string: string,
	history: string[],
};

async function load(lang:string, id: string, fallback: string):Promise<string> {
	const cacheKey = key + '/' + lang + '/' + id;
	const cache = sessionStorage.getItem(cacheKey);

	if (cache !== null) {
		return Promise.resolve(cache);
	}

	try {
		const resp = await fetch(LOCALIZATION_BASE + lang + '/' + id + '.json');
		const data:TranslationFile = await resp.json();
		
		if (data.string) {
			sessionStorage.setItem(cacheKey, data.string);
			return data.string;
		}

		return fallback;
	} catch (e) {
		sessionStorage.setItem(cacheKey, '');
		return Promise.resolve(fallback);
	}
}


export default function useLocalization() {
	const { i18n } = useTranslation();
	const lang = i18n.language;

	return {
		string: (id: string, fallback: string) => {
			return load(lang, id, fallback);
		},
	};
}