import { useIsAuthenticated } from '@azure/msal-react';
import Button from '../../ui/Button';
import useGAM from '../../../hooks/useGAM';
import { CDN_BASE, STORE_NAME } from '../../../Constants';
import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useRef, useState } from 'react';
import FTUESlider from './FTUESlider';
import AgeGate from './AgeGate';
import useTelemetry, { TelemetryEvents } from '../../../hooks/useTelemetry';
import { CSSTransition } from 'react-transition-group';


const SEEN_FTUE_LS_KEY = STORE_NAME + '_seen_ftue';
function setLSSeenFTUE(page: string) {
	localStorage.setItem(SEEN_FTUE_LS_KEY, page);
}

function getLSSeenFTUE() {
	return localStorage.getItem(SEEN_FTUE_LS_KEY) || '';
}

const AGE_GATE_LS_KEY = STORE_NAME + '_over_18';
function setLSAgeGate(page: string) {
	localStorage.setItem(AGE_GATE_LS_KEY, page);
}

function getLSAgeGate() {
	return localStorage.getItem(AGE_GATE_LS_KEY) || '';
}

export default function AuthHub() {
	const [seenFTUE, setSeenFTUE] = useState(getLSSeenFTUE());
	const [seenAgeGate, setSeenAgeGate] = useState(getLSAgeGate());
	const { logEvent } = useTelemetry();
	const ref = useRef<HTMLDivElement>(null);
	
	const {
		login,
		create,
	} = useGAM();

	const onClickCreateAccount = useCallback(() => {
		create();
		logEvent(TelemetryEvents.clickCreateAccount({}));
	}, [login, logEvent]);

	const onClickLogin = useCallback(() => {
		login();
		logEvent(TelemetryEvents.clickLogIn({}));
	}, [login, logEvent]);

	const { t } = useTranslation();

	const isAuthenticated = useIsAuthenticated();

	if (!seenAgeGate) {
		return <AgeGate setSeen={() => { setSeenAgeGate('true'); setLSAgeGate('true'); }} />;
	}

	if (!seenFTUE) {
		return <FTUESlider setSeen={() => { setSeenFTUE('true'); setLSSeenFTUE('true'); }} />;
	}
	
	return !isAuthenticated && <CSSTransition nodeRef={ref} in={true} timeout={1400} appear mountOnEnter unmountOnExit>
		<div ref={ref} className="auth-hub">
			<div className="logo">
				<img src={CDN_BASE + 'tccc-mission-universe-logo.svg'} width="271" height="111" alt="" />
			</div>
			<div className="panel">
				<div className="title">{t('auth_hub.title')}</div>
				<Button className="" label={t('auth_hub.create_account_button_label')} onClick={onClickCreateAccount} />
				<div className="text">
					<Trans i18nKey={'auth_hub.login_prompt'}>or <span className="login" onClick={onClickLogin}>login</span></Trans>
				</div>
				<div className="t-and-c">
					<Trans i18nKey="auth_hub.legal">
						By logging in or creating an account, you agree to our <a href={t('common.terms_conditions_link')} target="_blank" rel="noreferrer">{t('common.terms_conditions')}</a> and <a href={t('common.privacy_policy_link')} target="_blank" rel="noreferrer">{t('common.privacy_policy')}</a>.
					</Trans>
				</div>
			</div>
		</div>
	</CSSTransition>;
}