import { MouseEventHandler } from 'react';
import { NavLink } from 'react-router-dom';

type ButtonProps = {
	onClick?: MouseEventHandler,
	label: string,
	url?: string,
	isBlank?: boolean,
	className?: string,
	disabled?: boolean,
	to?:string,
	style?: React.CSSProperties,
	icon?: React.ReactNode,
};

function Button({ className = '', onClick, label, url, isBlank, to, style = undefined, disabled = false, icon = null }: ButtonProps) {
	const props = {
		className: 'button' + (className ? ' ' + className : '') + (disabled ? ' disabled' : ''),
		onClick: null,
		disabled: false,
	} as Record<string, any>;

	if (onClick) {
		props.onClick = onClick;
	}

	if (url) {
		props.href = url;

		if (isBlank) props.target = '_blank';
	} else {
		if (disabled) props.disabled = true;
	}

	props.style = style;
	const content = (<>
		{label} {icon}
	</>);
	
	let element = <button {...props}>{content}</button>;
	
	if (url) {
		element = <a target='_blank' {...props}>{content}</a>;
	} else if (to) {
		element = <NavLink to={to} {...props}>{content}</NavLink>;
	}

	return element;
}

export default Button;