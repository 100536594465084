function HeaderBG() {
	return (
		<svg
			className="header-bg"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			fill="none"
			width="354"
			height="56"
			viewBox="0 0 354 56"
		>
			<g filter="url(#header-a)">
				<path
					fill="#fff"
					fillOpacity="0.5"
					d="M354 10v36c0 5.523-4.477 10-10 10h-46.4a5.6 5.6 0 01-5.6-5.6v-4.6a1 1 0 00-2 0v4.6a5.6 5.6 0 01-5.6 5.6H10C4.477 56 0 51.523 0 46V10C0 4.477 4.477 0 10 0h274.4a5.6 5.6 0 015.6 5.6v4.6a1 1 0 002 0V5.6a5.6 5.6 0 015.6-5.6H344c5.523 0 10 4.477 10 10z"
				></path>
				<path
					stroke="url(#header-b)"
					strokeOpacity="0.5"
					strokeWidth="3"
					d="M352.5 46V10a8.5 8.5 0 00-8.5-8.5h-46.4a4.1 4.1 0 00-4.1 4.1v4.6a2.5 2.5 0 11-5 0V5.6a4.1 4.1 0 00-4.1-4.1H10A8.5 8.5 0 001.5 10v36a8.5 8.5 0 008.5 8.5h274.4a4.1 4.1 0 004.1-4.1v-4.6a2.5 2.5 0 115 0v4.6a4.1 4.1 0 004.1 4.1H344a8.5 8.5 0 008.5-8.5z"
				></path>
			</g>
			<path
				fill="url(#header-c)"
				fillOpacity="0.2"
				d="M354 10v36c0 5.523-4.477 10-10 10h-46.4a5.6 5.6 0 01-5.6-5.6v-4.6a1 1 0 00-2 0v4.6a5.6 5.6 0 01-5.6 5.6H10C4.477 56 0 51.523 0 46V10C0 4.477 4.477 0 10 0h274.4a5.6 5.6 0 015.6 5.6v4.6a1 1 0 002 0V5.6a5.6 5.6 0 015.6-5.6H344c5.523 0 10 4.477 10 10z"
				style={{ mixBlendMode: 'screen' }}
			></path>
			<path
				stroke="url(#header-d)"
				d="M353.5 46V10A9.5 9.5 0 00344 .5h-46.4a5.1 5.1 0 00-5.1 5.1v4.6a1.5 1.5 0 01-3 0V5.6a5.1 5.1 0 00-5.1-5.1H10A9.5 9.5 0 00.5 10v36a9.5 9.5 0 009.5 9.5h274.4a5.1 5.1 0 005.1-5.1v-4.6a1.5 1.5 0 013 0v4.6a5.1 5.1 0 005.1 5.1H344a9.5 9.5 0 009.5-9.5z"
				opacity="0.75"
			></path>
			<defs>
				<linearGradient
					id="header-b"
					x1="0"
					x2="354"
					y1="28"
					y2="28"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F40009"></stop>
					<stop offset="0.498" stopColor="#F79900"></stop>
					<stop offset="1" stopColor="#6AC9CE"></stop>
				</linearGradient>
				<linearGradient
					id="header-d"
					x1="253.5"
					x2="254"
					y1="56"
					y2="0"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopOpacity="0.1"></stop>
					<stop offset="1" stopColor="#fff"></stop>
				</linearGradient>
				<pattern
					id="header-c"
					width="1"
					height="1"
					patternContentUnits="objectBoundingBox"
				>
					<use
						transform="matrix(.00088 0 0 .00558 -.295 -.035)"
						xlinkHref="#e"
					></use>
				</pattern>
				<filter
					id="header-a"
					width="374"
					height="76"
					x="-10"
					y="-10"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
					<feGaussianBlur
						in="BackgroundImageFix"
						stdDeviation="5"
					></feGaussianBlur>
					<feComposite
						in2="SourceAlpha"
						operator="in"
						result="effect1_backgroundBlur_6_80"
					></feComposite>
					<feBlend
						in="SourceGraphic"
						in2="effect1_backgroundBlur_6_80"
						result="shape"
					></feBlend>
				</filter>
			</defs>
		</svg>
	);
}

export default HeaderBG;