function TargetIcon() {
	return (
		<svg className="target-icon" xmlns="http://www.w3.org/2000/svg" fill="none" width="30" height="30" viewBox="0 0 30 30">
			<path
				fill="url(#target-icon-a)"
				fillOpacity="0.5"
				d="M28.929 13.929h-1.117a12.876 12.876 0 00-11.74-11.74V1.07a1.072 1.072 0 00-2.143 0v1.117a12.876 12.876 0 00-11.74 11.74H1.07a1.072 1.072 0 000 2.143h1.117a12.876 12.876 0 0011.74 11.74v1.118a1.072 1.072 0 002.143 0v-1.117a12.876 12.876 0 0011.74-11.74h1.118a1.072 1.072 0 000-2.143zM16.07 25.66v-1.018a1.072 1.072 0 00-2.142 0v1.018a10.734 10.734 0 01-9.59-9.59h1.018a1.072 1.072 0 000-2.142H4.34a10.734 10.734 0 019.59-9.59v1.018a1.072 1.072 0 002.142 0V4.34a10.734 10.734 0 019.59 9.59h-1.018a1.072 1.072 0 000 2.142h1.018a10.734 10.734 0 01-9.59 9.59zM15 9.643a5.357 5.357 0 100 10.715 5.357 5.357 0 000-10.715zm0 8.571a3.214 3.214 0 110-6.429 3.214 3.214 0 010 6.43z"
			></path>
			<defs>
				<linearGradient
					id="target-icon-a"
					x1="15"
					x2="15"
					y1="0"
					y2="31.938"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#91B8B6"></stop>
					<stop offset="1" stopColor="#765E78"></stop>
				</linearGradient>
			</defs>
		</svg>
	);
}

export default TargetIcon;
