import i18n from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.use(I18NextHttpBackend)
	.init({
		debug: true,
		// lng: 'es-MX',
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;