import circle from '@turf/circle';
import { Point } from '@turf/turf';
import { FillPaint, LinePaint } from 'mapbox-gl';
import { GeoJSONLayer } from 'react-mapbox-gl';

const lineStyle:(opacity: number) => LinePaint = (opacity = 0.5) => ({
	'line-color': `rgba(255, 0, 0, ${opacity})`,
	'line-opacity': 1,
	'line-width': 3,
});

const fillStyle:(opacity:number) => FillPaint = (opacity = 0.5) => ({
	'fill-color': `rgba(255, 0, 0, ${opacity})`,
});

function HelperRadius({ center, radius, opacity = 0.5 }:{ center: Point | [number, number], radius: number, opacity?: number }) {
	if (!center) {
		return null;
	}

	return (
		<GeoJSONLayer
			linePaint={lineStyle(opacity)}
			fillPaint={fillStyle(opacity)}
			data={circle(center, radius, { units: 'meters' })}
		/>
	);
}

export default HelperRadius;
