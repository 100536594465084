import { useCallback } from 'react';
import useTelemetry, { TelemetryEvents } from './useTelemetry';

const options:PositionOptions = {
	enableHighAccuracy: true,
	timeout: 10000,
	maximumAge: 1000,
};

export default function useGeolocation() {
	const { logEvent } = useTelemetry();

	return {
		getLocation: useCallback(() => {
			return new Promise<GeolocationPosition>((resolve, reject) => {
				navigator.geolocation.getCurrentPosition((position) => resolve(position), (error) => reject(error), options);
			});
		}, []),
		watchLocation: useCallback((callback:(position:GeolocationPosition) => void, onError?: (e:GeolocationPositionError) => void) => {
			const watchId = navigator.geolocation.watchPosition(callback, (error) => {
				if (error.code === error.PERMISSION_DENIED) {
					onError?.(error);
					
					logEvent(TelemetryEvents.geolocationWatchError({ error: error?.message }));
				}
			}, options);
			return () => navigator.geolocation.clearWatch(watchId);
		}, [logEvent]),
	};
}