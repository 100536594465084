import { useTranslation } from 'react-i18next';
import { CDN_BASE } from '../../../Constants';
import useBadges from '../../../hooks/useBadges';
import CheckmarkIcon from '../../icons/CheckmarkIcon';
import { Translation } from '../../utils/Translation';
import { CSSTransition } from 'react-transition-group';
import { CSSProperties, useRef } from 'react';

function BadgeElem({ badge, index }: { badge:Badge, index:number }) {
	const displayName = <Translation id={badge?.itemId + '/playfab/DisplayName'} fallback={badge?.playfab.DisplayName} />;
	const description = <Translation id={badge?.itemId + '/playfab/Description'} fallback={badge?.playfab.Description} />;

	return <div className={`badge ${badge.owned ? 'owned' : ''}`} style={{ '--i': index } as CSSProperties}>
		<div className="image">
			{badge.owned && <div className="check"><CheckmarkIcon /></div>}
			<img className="static-bubble" width="115" height="115" src={CDN_BASE + `static-bubble-${badge.owned ? 'rainbow' : 'white'}.png`} alt="" />
			{badge.playfab.ItemImageUrl && <img className="icon" width="115" height="115" src={badge.playfab.ItemImageUrl} alt="" />}
		</div>
		<div className="name">{displayName}</div>
		<div className="description">{description}</div>
	</div>;
}

export default function BadgesTab() {
	const { t } = useTranslation();
	const { badges } = useBadges();
	const ref = useRef<HTMLDivElement>(null);

	return <CSSTransition nodeRef={ref} in={true} timeout={2400} appear>
		<div className="tab badges-tab" ref={ref}>
			<div className="title">{t('you.badges_tab.title')}</div>
			<div className="subtext">{t('you.badges_tab.subtext')}</div>
			<div className="badges-grid">
				{badges.map((badge, i) => <BadgeElem key={badge.id} badge={badge} index={i} />)}
			</div>
		</div>
	</CSSTransition>;
}