import { useState, useCallback, useRef, CSSProperties } from 'react';
import { useSurvey } from '../../hooks/useSurveys';
import ThumbsUpIcon from '../icons/ThumbsUpIcon';
import Button from './Button';
import CircleClose from './CircleClose';
import { useAppDispatch } from '../../redux/config/store';
import { setActiveInstanceId } from '../../redux/surveys';
import { useTranslation } from 'react-i18next';
import slugify from 'slugify';
import { Translation } from '../utils/Translation';
import useTelemetry, { TelemetryEvents } from '../../hooks/useTelemetry';
import { CSSTransition } from 'react-transition-group';

export function SinglePoll({ instanceId }: { instanceId: string }) {
	const { logEvent } = useTelemetry();
	const { t } = useTranslation();
	const surveyRef = useRef<HTMLDivElement>(null);
	const questionRef = useRef<HTMLDivElement>(null);
	const survey = useSurvey(instanceId);
	const xp = survey?.customData?.xp;
	const [selectedAnswer, setSelectedAnswer] = useState<string>(null);

	const [answers, setAnswers] = useState<string[]>([]);
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const question = survey?.questions?.[currentQuestion];
	const dispatch = useAppDispatch();

	const [showSubmitting, setShowSubmitting] = useState(false);

	const onClickSubmit = useCallback(() => {
		const nextAnswers = [...answers, selectedAnswer];
		if (currentQuestion < survey.questions.length - 1) {
			setAnswers(nextAnswers);
			setCurrentQuestion(currentQuestion + 1);
			setSelectedAnswer(null);
			logEvent(TelemetryEvents.nextSurveyQuestion({ surveyId: survey.id, questionId: survey.questions[currentQuestion + 1]?.id }));
		} else {
			setShowSubmitting(true);
			const a = nextAnswers.reduce((carry, answer, questionId) => {
				const key = survey.questions[questionId].id;
				carry[key] = answer;
				return carry;
			}, {} as Record<string, string>);
			survey.answer(a).then(() => {
				dispatch(setActiveInstanceId(null));
			});
			setSelectedAnswer(null);
			setAnswers([]);
		}
	}, [survey, selectedAnswer, answers, currentQuestion, logEvent]);

	const isOneToFive = survey?.customData?.isOneToFive === '1';
	const isMultiQuestion = survey?.questions?.length > 1;
	const classes = ['single-poll'];
	if (survey?.isAnswered) classes.push('completed');
	if (isMultiQuestion) {
		classes.push('multi-question');
	}
	if (showSubmitting) classes.push('submitting');

	const onClickChoice = useCallback((answer:string) => {
		logEvent(TelemetryEvents.clickSurveyChoice({ surveyId: survey.id, questionId: question.id, answer }));
		setSelectedAnswer(answer);
	}, [logEvent]);

	const title = <Translation id={survey?.customData?.translation_id + '/displayName'} fallback={survey.displayName} />;
	const questionText = <Translation id={survey?.customData?.translation_id + '/question_' + question.id + '/question'} fallback={question.question} />;

	const [finishedAnimIn, setFinishedAnimIn] = useState(false);

	let optionIndex = 0;
	return survey && question && <CSSTransition nodeRef={surveyRef} in={Boolean(survey)} onEntered={() => setFinishedAnimIn(true)} timeout={{ enter: 2400, appear: 2400, exit: 300 }} unmountOnExit mountOnEnter appear>
		<div ref={surveyRef} key={survey.instanceId} className={classes.join(' ')}>
			<div className="top">
				<div className="text">{title}</div>
				{!isMultiQuestion && <div className="reward">{t(survey.isAnswered ? 'single_poll.reward.answered' : 'single_poll.reward.not_answered', { xp })}</div>}
				{isMultiQuestion && <div className="reward">{t('single_poll.reward.multi_question', { count: currentQuestion + 1, total: survey.questions.length })}</div>}
				{isMultiQuestion && <div className="question-tabs">
					{survey.questions.map((q, i) => <div key={i} className={`tab ${i === currentQuestion ? 'active' : ''}`} />)}
				</div>}
				<CircleClose onClick={survey.close} />
			</div>
			<div className="poll-explanation">{t('single_poll.explanation')}</div>
			<CSSTransition nodeRef={questionRef} key={question.id} in={true} classNames={finishedAnimIn ? 'quick' : ''} timeout={1200} appear>
				<div className="content" ref={questionRef}>
					<div className="submitting-msg">{t('single_poll.submitting')}</div>
					<div className="poll-description">{questionText}</div>
					<div className={`poll-options ${isOneToFive ? 'one-to-five' : 'default'}`}>
						{question.choices.map((answer, i) => {
							const isSelectedAnswer = selectedAnswer === answer.code;
							const classNames = ['option'];
							const slug = slugify(answer.label, { lower: true });
							const translatedLabel = <Translation id={survey?.customData?.translation_id + '/question_' + question.id + '/answer_' + answer.id + '/choice'} fallback={t('single_poll.choices.' + slug.replace(/-/g, '_'), answer.label)} />;
							if (isSelectedAnswer) classNames.push('selected');
							if (isOneToFive) classNames.push(`option-${slug}`);
							return <div key={i} className={classNames.join(' ')} style={{ '--i': ++optionIndex } as CSSProperties} onClick={() => onClickChoice(isSelectedAnswer ? null : answer.code)}>
								{isOneToFive ? <div className="shadow"><ThumbsUpIcon /></div> : translatedLabel}
							</div>;
						})}
					</div>
					<div className="btn-ctn" style={{ '--i': optionIndex } as CSSProperties}><Button label={currentQuestion === survey.questions.length - 1 ? t('single_poll.submit_button_label') : t('single_poll.next_button_label')} disabled={!Boolean(selectedAnswer)} className='thin full-width' onClick={onClickSubmit} /></div>
				</div>
			</CSSTransition>
		</div>
	</CSSTransition>;
}