import { createSlice } from '@reduxjs/toolkit';
import { getXrApi } from '../api/apiBridge';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';
import xrAction from './async/xrAction';
import PlayFabXR from '../api/PlayFabXR';

export const getSurveyInstances = createDebouncedAsyncAction(
	'playfab-xr/getSurveyInstances',
	xrAction(getXrApi().Client.GetSurveyInstances),
);

export const getSurveyInstanceData = createDebouncedAsyncAction(
	'playfab-xr/getSurveyInstanceData',
	(url:string) => {
		return fetch(url).then((r) => r.json());
	},
);

export const answerSurvey = createDebouncedAsyncAction(
	'surveys/answerQuiz',
	xrAction((...args:Parameters<PlayFabXR['Client']['AnswerSurvey']>) => {
		return getXrApi().Client.AnswerSurvey(...args);
	}),
);

const surveys = createSlice({
	name: 'surveys',
	initialState: {
		loaded: false,
		list: [] as XRQuizInstanceItem[],
		data: {} as Record<string, XRQuizInstanceData>,

		activeInstanceId: null as string | null,
	},
	reducers: {
		setActiveInstanceId(state, action) {
			state.activeInstanceId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getSurveyInstances.actions.fulfilled, (state, action) => {
			state.list = action.payload.data.QuizInstances;
			state.loaded = true;
		});
		builder.addCase(getSurveyInstanceData.actions.fulfilled, (state, action) => {
			// console.log(action);
			state.data[action.payload.id] = action.payload;
		});
		builder.addCase(answerSurvey.actions.fulfilled, (state, action) => {
			const instanceId = action.meta.arg.InstanceId;
			const instance = state.list.find((i) => i.instanceId === instanceId);
			if (instance) {
				instance.isAnswered = true;
			}
		});
	},
});

export default surveys;

export const { setActiveInstanceId } = surveys.actions;