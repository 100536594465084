import { useCallback, useState } from 'react';
import { useAppDispatch } from '../redux/config/store';
import { executeCloudScript, sendMissionInput, writePlayerEvent } from '../redux/playfab';
import { PLAYER_EVENTS } from '../Constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { setProductScanVisible } from '../redux/game';
import { getMissionInventory } from '../redux/missions';
import useTelemetry, { TelemetryEvents } from './useTelemetry';
import useGlobalVariables from './useGlobalVariables';

type UseProductScanProps = {
	defaultMission?: IXRMissionItem;
};

export const ERROR_TYPES = {
	ALREADY_SCANNED: 'already_scanned',
	WRONG_INPUT: 'wrong_input',
	UNKNOWN: 'unknown',
} as const;
export type ErrorType = typeof ERROR_TYPES[keyof typeof ERROR_TYPES];

export default function useProductScan({ defaultMission = null }:UseProductScanProps) {
	const [showScanner, setShowScanner] = useState(false);
	const [errorMessage, setErrorMessage] = useState<{ type: ErrorType, message?: string }>(null);
	const [showScanReward, setShowScanReward] = useState<{ missionData: SendMissionInputResponse, code: string }>(null);
	const [showDailyScanReward, setShowDailyScanReward] = useState<{ product: string, Rewards: SendMissionInputResponse['Rewards'] }>(null);
	const [codeBeingSent, setCodeBeingSent] = useState<string>(null);
	const dispatch = useAppDispatch();
	const { logEvent } = useTelemetry();

	const {
		productSkus,
	} = useGlobalVariables();

	const [mission, setMission] = useState<IXRMissionItem>(defaultMission);

	const onSubmitDailyCode = useCallback((barcode:string) => {
		setErrorMessage(null);
		const product = Object.entries(productSkus).find(([, skus]) => skus.includes(barcode))?.[0] || '';

		if (!product) {
			setErrorMessage({ type: ERROR_TYPES.WRONG_INPUT });
			return;
		}

		setCodeBeingSent(barcode);

		
		dispatch(writePlayerEvent({
			EventName: PLAYER_EVENTS.PLAYER_SCANNED_CODE,
			Body: {
				code: barcode,
			},
		}));
		
		dispatch(executeCloudScript({
			functionName: 'ScanDailyCode',
			data: {
				barcode,
			},
		})).then(resp => {
			const data = resp.payload?.data?.FunctionResult;
			if (!data.error) {
				setShowDailyScanReward(data);
				logEvent(TelemetryEvents.scanDailyCodeSuccess({ code: barcode }));
			} else {
				if (data.error === 'User has already scanned a product today') {
					setErrorMessage({ type: ERROR_TYPES.ALREADY_SCANNED });
					logEvent(TelemetryEvents.scanDailyCodeError({ error: ERROR_TYPES.ALREADY_SCANNED }));
				} else {
					setErrorMessage({ type: ERROR_TYPES.UNKNOWN, message: data.error });
					logEvent(TelemetryEvents.scanDailyCodeError({ error: data.error }));
				}
			}
			setCodeBeingSent(null);
		});
	}, [dispatch, logEvent, productSkus]);

	const onSubmitMissionCode = useCallback((barcode:string, missionOverride:IXRMissionItem = null) => {
		setErrorMessage(null);
		setCodeBeingSent(barcode);

		const m = missionOverride || mission;
		
		dispatch(writePlayerEvent({
			EventName: PLAYER_EVENTS.PLAYER_SCANNED_CODE,
			Body: {
				code: barcode,
			},
		}));

		if (!m) return;

		dispatch(sendMissionInput({
			ItemId: m.itemId,
			Input: barcode,
		})).then((resp:PayloadAction<GenericApiCallResponse<SendMissionInputResponse>>) => {
			const error = resp.payload?.data?.error;
			setCodeBeingSent(null);

			if (error) {
				if (error === 'Objective already completed') {
					setErrorMessage({ type: ERROR_TYPES.ALREADY_SCANNED });
					logEvent(TelemetryEvents.scanMissionCodeError({ error: ERROR_TYPES.ALREADY_SCANNED }));
				} else if (error === 'Wrong Input Value') {
					setErrorMessage({ type: ERROR_TYPES.WRONG_INPUT });
					logEvent(TelemetryEvents.scanMissionCodeError({ error: ERROR_TYPES.WRONG_INPUT }));
				} else {
					setErrorMessage({ type: ERROR_TYPES.UNKNOWN, message: 'An error occurred, please try again.' });
					logEvent(TelemetryEvents.scanMissionCodeError({ error: error }));
				}
			} else {
				setShowScanner(false);
				setShowScanReward({
					code: barcode,
					missionData: resp.payload.data,
				});
				dispatch(getMissionInventory());
				logEvent(TelemetryEvents.scanMissionCodeSuccess({ code: barcode, missionId: mission?.itemId }));
			}
		});
	}, [dispatch, mission, logEvent]);

	const onClickCloseScanner = useCallback(() => {
		dispatch(setProductScanVisible(false));
		setErrorMessage(null);
		setShowScanner(false);
		logEvent(TelemetryEvents.closeScanner({}));
	}, [dispatch, logEvent]);

	return {
		codeBeingSent,
		showScanner,
		errorMessage,
		showScanReward,
		showDailyScanReward,
		setMission,
		setShowScanner: (state:boolean) => {
			dispatch(setProductScanVisible(state));
			setShowScanner(state);
			logEvent(TelemetryEvents.showScanner({}));
		},
		setShowDailyScanReward,
		onSubmitDailyCode,
		onSubmitMissionCode,
		onClickCloseScanner,
		setShowScanReward,
		closeScanReward: () => { setShowScanReward(null); },
		closeDailyScanReward: () => { setShowDailyScanReward(null); setShowScanner(false); },
	};
}