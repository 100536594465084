import useGlobalVariables from './useGlobalVariables';
import useStats from './useStats';

export default function useRegion() {
	const { subregion, region } = useStats();
	const { regions } = useGlobalVariables();

	return {
		currentRegion: regions[region],
		currentSubRegion: regions[region]?.subRegions?.[subregion],
	};
}