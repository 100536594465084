/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';
import { realtimeApi } from '../api/apiBridge';
import { PLAYFAB_CONFIG } from '../Constants';
import { guid } from '../utils/guid';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';

type INotificationsState = typeof initialState;

const initialState = {
	list: [] as IXRRealtimeNotification[],
	filteredOut: localStorage.getItem('filteredOutNotifications') ? JSON.parse(localStorage.getItem('filteredOutNotifications') || '[]') : [],
};

export const getRealtimeNotifications = createDebouncedAsyncAction(
	'realtime/getRealtimeNotifications',
	(playerId:string) => {
		return realtimeApi<IXRRealtimeNotification[]>('client/GetNotifications', {
			AppId: PLAYFAB_CONFIG.appId,
			PlayFabId: playerId,
		});
	},
);

export function notificationGenerator({ title = '', icon = '', message = '', link = '', eventName = 'local_notification' }) {
	return {
		NotificationId: guid(),
		Title: title,
		Icon: icon,
		Message: message,
		Link: link,
		EventName: eventName,
		Timestamp: new Date().toISOString(),
		TitleId: PLAYFAB_CONFIG.appId,
		EventId: guid(),
		EntityId: '',
		EntityType: 'player',
		Source: 'local',
		SourceType: 'local',
	};
}

const notifications = createSlice({
	name: 'notifications',
	reducers: {
		addNotification: (state:INotificationsState, action: { payload: IXRRealtimeNotification }) => {
			state.list.push({
				...action.payload,
				Message: action.payload?.Message,
				Timestamp: new Date().toISOString(),
			});
		},
		removeNotification: (state:INotificationsState, action) => {
			localStorage.setItem('filteredOutNotifications', JSON.stringify([...state.filteredOut, action.payload]));
			return {
				...state,
				filteredOut: [...state.filteredOut, action.payload],
				list: state.list.filter(notification => notification.NotificationId !== action.payload),
			};
		},
		clearNotifications: (state:INotificationsState) => {
			return {
				...state,
				list: [],
			};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getRealtimeNotifications.actions.fulfilled, (state, action) => {
			return {
				...state,
				list: action.payload.map(n => ({
					...n,
				})).filter(n => !state.filteredOut.includes(n.NotificationId)),
			};
		});
	},
	initialState,
});

export default notifications;

export const { addNotification, removeNotification, clearNotifications } = notifications.actions;