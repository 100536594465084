import { CSSTransition } from 'react-transition-group';
import useSurveys from '../../hooks/useSurveys';
import { PollPreview } from '../ui/PollPreview';
import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/config/store';
import BackIcon from '../icons/BackIcon';
import { setPollsTabOpened } from '../../redux/game';
import { raw } from '../../utils/textUtils';
import { useTranslation } from 'react-i18next';
import useTelemetry, { TelemetryEvents } from '../../hooks/useTelemetry';
import { clearNotifications } from '../../redux/notifications';

export default function Polls() {
	const { t } = useTranslation();
	const { list } = useSurveys();
	const ref = useRef<HTMLDivElement>(null);

	const isVisible = useAppSelector(state => state.game.pollsTabOpened);
	const dispatch = useAppDispatch();

	const { logEvent } = useTelemetry();

	const onClickClose = () => {
		dispatch(setPollsTabOpened(false));
		logEvent(TelemetryEvents.clickCloseSurveys());
	};

	useEffect(() => {
		if (isVisible) {
			dispatch(clearNotifications());
		}
	}, [dispatch, isVisible]);

	return <CSSTransition nodeRef={ref} in={isVisible} timeout={{ enter: 1200, appear: 1200, exit: 300 }} unmountOnExit mountOnEnter appear>
		<div className="polls-tab" ref={ref}>
			<div className="wrapper">
				<span onClick={onClickClose}><BackIcon /></span>
				<div className="title">{t('polls.title')}</div>
				<div className="subtext" {...raw(t('polls.subtext'))} />

				<div className="list">
					{list.map((survey, index) => <PollPreview key={survey.instanceId} instanceId={survey.instanceId} index={index} />)}
				</div>
			</div>
		</div>
	</CSSTransition>;
}