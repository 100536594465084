import { CSSProperties, memo, useCallback, useEffect, useRef, useState } from 'react';
import { BarcodeDetectorPolyfill } from '@undecaf/barcode-detector-polyfill';
import Button from './Button';
import CloseIcon from '../icons/CloseIcon';
import Select from './Select';
import { createPortal } from 'react-dom';
import { useAppDispatch } from '../../redux/config/store';
import { setProductScanVisible } from '../../redux/game';
import { CDN_BASE, MISSIONS_TYPES, STORE_NAME } from '../../Constants';
import { getLSFlag, setLSFlag } from '../../utils/localStorage';
import useMissions from '../../hooks/useMissions';
import useProductScan, { ERROR_TYPES } from '../../hooks/useProductScan';
import CodeSubmission from './CodeSubmission';
import DailyScanRewards from './DailyScanRewards';
import ScanRewards from './ScanRewards';
import useGlobalVariables from '../../hooks/useGlobalVariables';
import { AppRouting } from '../../AppRoutes';
import { useNavigate } from 'react-router-dom';
import slugify from 'slugify';
import { useTranslation } from 'react-i18next';
import useTelemetry, { TelemetryEvents } from '../../hooks/useTelemetry';
import { inAppMessages } from '../RealtimeVisualizer';
import { CSSTransition } from 'react-transition-group';
import useTranslatedMission from '../../hooks/useTranslatedMission';

type BarcodeScannerModalProps = {
	onClickClose: () => void;
	missionId?: string;
};

const SEEN_BARCODE_TUTORIAL_LS_KEY = STORE_NAME + '_seen_barcode_tutorial';

const MODES = {
	MISSION: 'mission',
	DAILY: 'daily',
} as const;
export type Mode = typeof MODES[keyof typeof MODES];

function BarcodeScannerModalNew({ onClickClose, missionId }: BarcodeScannerModalProps) {
	const { logEvent } = useTelemetry();
	const { t } = useTranslation();
	const ref = useRef<HTMLDivElement>(null);
	const errorRef = useRef<HTMLDivElement>(null);
	const contentRef = useRef<HTMLDivElement>(null);
	const introRef = useRef<HTMLDivElement>(null);
	const tipRef = useRef<HTMLDivElement>(null);
	const allowCameraRef = useRef<HTMLDivElement>(null);
	const reader = useRef<BarcodeDetectorPolyfill>(null);
	const videoElement = useRef<HTMLVideoElement>(null);
	const [availableCameraDevices, setAvailableCameraDevices] = useState<MediaDeviceInfo[]>([]);
	const [selectedCameraDevice, setSelectedCameraDevice] = useState<string>(null);
	const [showIntro, setShowIntro] = useState(!getLSFlag(SEEN_BARCODE_TUTORIAL_LS_KEY));
	const canScan = useRef(true);
	const detection = useRef<number>(null);
	const dispatch = useAppDispatch();
	const missions = useMissions(MISSIONS_TYPES.PRODUCT_SCAN);
	const mission = missions.find(m => m.itemId === missionId);
	const tMission = useTranslatedMission(mission);
	const [matchingMission, setMatchingMission] = useState<IXRMissionItem | null>(null);
	const tMatchingMission = useTranslatedMission(matchingMission);
	const navigate = useNavigate();

	const [showAllowCameraPrompt, setShowAllowCameraPrompt] = useState(false);

	const [mode, setMode] = useState<Mode>(missionId ? MODES.MISSION : MODES.DAILY);
	const currentStream = useRef<MediaStream>(null);

	const {
		codeBeingSent,
		showDailyScanReward,
		showScanReward,
		errorMessage,
		setMission,
		onSubmitDailyCode,
		onSubmitMissionCode,
		closeScanReward,
		closeDailyScanReward,
	} = useProductScan({ defaultMission: mission });

	const hideUI = Boolean(showDailyScanReward) || Boolean(codeBeingSent) || showAllowCameraPrompt || showIntro || errorMessage;

	const [code, setCode] = useState<string | null>(null);

	const onSubmitCode = useCallback((c) => {
		if (mode === MODES.MISSION) {
			onSubmitMissionCode(c);
		} else {
			const mm = missions.find(m => m.PlayerStatus && (m.objectives as IXRMissionObjectiveParsedData[]).find(o => !o?.PlayerStatus?.IsComplete && (o.data.skus || []).includes(c)));

			if (mm) {
				setMatchingMission(mm);
				return;
			} else {
				onSubmitDailyCode(c);
			}
		}
	}, [code, mode, logEvent]);

	const startScanner = useCallback(async (deviceId?: string) => {
		if (!reader.current) return;
		if (deviceId) {
			setSelectedCameraDevice(deviceId);
			inAppMessages.trigger('camera_change:' + deviceId, {});
		}

		if (currentStream.current) {
			currentStream.current.getTracks().forEach(track => track.stop());
		}

		await new Promise(resolve => requestAnimationFrame(resolve));

		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: deviceId ? { deviceId } : { facingMode: 'environment' } });
			
			const devices = (await navigator.mediaDevices.enumerateDevices()).filter(d => d.kind === 'videoinput');

			if (devices && devices.length) {
				setAvailableCameraDevices(devices);
			}

			currentStream.current = stream;
			videoElement.current.srcObject = stream;
			
			const currentDeviceId = stream?.getVideoTracks?.()?.[0]?.getSettings?.()?.deviceId;
			inAppMessages.trigger('Accessing Camera: ', { data: currentDeviceId });
			
			setSelectedCameraDevice(currentDeviceId);
	
			function detect() {
				if (!canScan.current && videoElement.current) return;
	
				reader.current.detect(videoElement.current).then((symbols) => {
					if (symbols.length >= 1) {
						const { rawValue, quality } = symbols[0];
	
						if (quality > 30) {
							logEvent(TelemetryEvents.scannedCode({ code: rawValue }));
							setCode(rawValue);
							onSubmitCode(rawValue);
							canScan.current = false;
						}
					}
				});
	
				detection.current = requestAnimationFrame(detect);
			}
	
			requestAnimationFrame(detect);
		} catch (error) {
			inAppMessages.trigger('Error accessing camera: ' + error, {});
			setShowAllowCameraPrompt(true);
			console.error(error);
		}
	}, [reader, onSubmitCode, videoElement]);

	async function setup() {
		try {
			const scanner = new BarcodeDetectorPolyfill({
				formats: [
					'ean_13',
					'ean_8',
					'upc_a',
					'upc_e',
				],
			});
			reader.current = scanner;

			startScanner();
		} catch (e) {
			setShowAllowCameraPrompt(true);
		}
	}

	useEffect(() => {
		setup();

		return () => {
			dispatch(setProductScanVisible(false));
			if (detection.current) cancelAnimationFrame(detection.current);
			if (currentStream.current) currentStream.current.getTracks().forEach(track => track.stop());
		};
	}, [detection, reader]);

	const dailyScanInstead = useCallback(() => {
		setMode(MODES.DAILY);
		onSubmitDailyCode(code);
		logEvent(TelemetryEvents.useDailyInstead({ code }));
	}, [code, logEvent]);

	const missionScanInstead = useCallback(() => {
		setMission(matchingMission);
		setMode(MODES.MISSION);
		onSubmitMissionCode(code, matchingMission);
		setMatchingMission(null);
		logEvent(TelemetryEvents.useMissionInstead({ code, mission: matchingMission?.itemId }));
	}, [code, matchingMission]);

	const {
		productSkus,
	} = useGlobalVariables();

	const product = Object.entries(productSkus).find(([, skus]) => skus.includes(code))?.[0] || '';

	const onClickCloseIntro = useCallback(() => {
		setShowIntro(false);
		setLSFlag(SEEN_BARCODE_TUTORIAL_LS_KEY);
		logEvent(TelemetryEvents.closeBarcodeTutorial({}));
	}, [logEvent]);

	const [visible, setVisible] = useState(true);

	const onClickCloseButton = () => {
		setVisible(false);
	};

	return (
		<CSSTransition in={visible} nodeRef={ref} timeout={{ appear: 1200, enter: 1200, exit: 600 }} onExited={onClickClose} appear mountOnEnter unmountOnExit>
			<div ref={ref} className="barcode-scanner-modal">
				<CSSTransition nodeRef={introRef} in={showIntro && !codeBeingSent && mode === MODES.DAILY} timeout={{ appear: 1200, enter: 1200, exit: 0 }} appear mountOnEnter unmountOnExit>
					<div ref={introRef} className="intro">
						<CloseIcon onClick={onClickCloseIntro} />
						<div className="title" style={{ '--i': 0 } as CSSProperties}>{t('barcode_scanner.instructions.title')}</div>
						<div className="subtitle" style={{ '--i': 1 } as CSSProperties}>{t('barcode_scanner.instructions.subtitle')}</div>
						<ol className="steps">
							<li style={{ '--i': 2 } as CSSProperties}>{t('barcode_scanner.instructions.steps.step_1')}</li>
							<li style={{ '--i': 3 } as CSSProperties}>{t('barcode_scanner.instructions.steps.step_2')}</li>
							<li style={{ '--i': 4 } as CSSProperties}>{t('barcode_scanner.instructions.steps.step_3')}</li>
							<li style={{ '--i': 5 } as CSSProperties}>{t('barcode_scanner.instructions.steps.step_4')}</li>
						</ol>
						<Button style={{ '--i': 6 } as CSSProperties} label={t('barcode_scanner.got_it')} className='thin full-width' onClick={onClickCloseIntro} />
					</div>
				</CSSTransition>
				<CSSTransition nodeRef={allowCameraRef} in={!showIntro && showAllowCameraPrompt} timeout={{ appear: 1200, enter: 1200, exit: 0 }} appear mountOnEnter unmountOnExit>
					<div ref={allowCameraRef} className="intro allow-camera-access">
						<CloseIcon onClick={onClickCloseButton} />
						<div className="title">{t('barcode_scanner.allow_camera_prompt.title')}</div>
						<div className="subtitle">{t('barcode_scanner.allow_camera_prompt.subtitle')}</div>
						<Button label={t('barcode_scanner.got_it')} className='thin full-width' onClick={onClickClose} />
					</div>
				</CSSTransition>
				<CSSTransition nodeRef={tipRef} in={!showIntro && !showAllowCameraPrompt} timeout={600} appear>
					<div className="tip" ref={tipRef}><strong>{t('barcode_scanner.scan.notice')}</strong></div>
				</CSSTransition>
				{createPortal(<CSSTransition nodeRef={errorRef} in={Boolean(errorMessage)} timeout={300} appear mountOnEnter unmountOnExit>
					<div ref={errorRef} className="barcode-scanner-error-message">
						{errorMessage && <div className="content">
							<div className="title">{t('barcode_scanner.errors.title')}</div>
							{product && <div className="subtitle">{product}</div>}
							{mission && errorMessage.type === ERROR_TYPES.WRONG_INPUT && <>
								<div className="description">{t('barcode_scanner.errors.mission_wrong_input')}</div>
								<div className="btn-ctn"><Button label={t('barcode_scanner.buttons.use_daily')} className='thin full-width' onClick={dailyScanInstead} /></div>
								<div className='link' onClick={onClickClose}>{t('barcode_scanner.buttons.back')}</div>
							</>}
							{mode === MODES.DAILY && errorMessage.type === ERROR_TYPES.WRONG_INPUT && <>
								<div className="description">{t('barcode_scanner.errors.daily_wrong_input')}</div>
								<div className='link' onClick={onClickClose}>{t('barcode_scanner.buttons.back')}</div>
							</>}
							{mode === MODES.MISSION && errorMessage.type === ERROR_TYPES.ALREADY_SCANNED && <>
								<div className="description">{t('barcode_scanner.errors.mission_already_scanned')}</div>
								<div className="btn-ctn"><Button label={t('barcode_scanner.buttons.use_daily')} className='thin full-width' onClick={dailyScanInstead} /></div>
								<div className='link' onClick={onClickClose}>{t('barcode_scanner.buttons.back')}</div>
							</>}
							{mode === MODES.DAILY && errorMessage.type === ERROR_TYPES.ALREADY_SCANNED && <>
								<div className="subtitle">{t('barcode_scanner.errors.daily_already_scanned_title')}</div>
								<div className="description">{t('barcode_scanner.errors.daily_already_scanned')}</div>
								<div className="btn-ctn"><Button label={t('barcode_scanner.buttons.close')} className='thin full-width' onClick={onClickClose} /></div>
							</>}
							{errorMessage.type === ERROR_TYPES.UNKNOWN && <>
								<div className="subtitle">{t('barcode_scanner.errors.unknown')}</div>
								<div className="description">{errorMessage.message}</div>
								<div className="btn-ctn"><Button label={t('barcode_scanner.buttons.close')} className='thin full-width' onClick={onClickClose} /></div>
							</>}
						</div>}
					</div>
				</CSSTransition>, document.body)}
				<div className='barcode-ctn'>
					<CSSTransition in={!hideUI} nodeRef={videoElement} timeout={{ appear: 1200, enter: 1200, exit: 0 }} appear>
						<video ref={videoElement} muted autoPlay playsInline />
					</CSSTransition>
				</div>
				<CSSTransition in={!hideUI} nodeRef={contentRef} timeout={{ appear: 1200, enter: 1200, exit: 0 }} appear>
					<div ref={contentRef} className="content">
						{!code && <div className="scan-region">
							<div className="corner top-left"></div>
							<div className="corner top-right"></div>
							<div className="corner bottom-left"></div>
							<div className="corner bottom-right"></div>
							<img className='barcode' src={CDN_BASE + 'barcode.svg'} alt="" />
						</div>}
						{!code && mode === MODES.MISSION && tMission && <div className="panel mission-panel">
							<div className="title">{t('barcode_scanner.scan.scanning')}</div>
							<div className="subtitle">{t('barcode_scanner.scan.active_mission')}</div>
							<div className="description">{tMission?.description as string}</div>
							<div className="clues">
								<div className="list">
									{mission.objectives.map((clue: IXRMissionObjectiveParsedData, index) => <div key={index} className={`clue ${clue?.PlayerStatus?.IsComplete ? 'completed' : ''} ${clue.data.productImage ? 'product' : ''}`}>
										{clue.data.productImage && <img src={clue.data.productImage} alt="" width="160" height="248" />}
									</div>)}
								</div>
							</div>
						</div>}
						{!code && <Select placeholder={t('barcode_scanner.scan.change_camera')} onChange={value => startScanner(value)} value={selectedCameraDevice || 'none'}>
							<option value="none" disabled>{t('barcode_scanner.scan.select_camera')}</option>
							<>
								{availableCameraDevices.map((device, i) => (
									<option key={i} value={device.deviceId}>{device.label}</option>
								))}
							</>
						</Select>}
						{matchingMission && <div className="panel matching-panel">
							<div className="subtitle">{t('barcode_scanner.matching_mission.subtitle')}</div>
							{<div className="title">{product}</div>}
							<div className="description">{t('barcode_scanner.matching_mission.description', { name: matchingMission.playfab.DisplayName })}</div>
							<div className="sub-panel">
								<div className="title">{tMatchingMission.displayName}</div>
								<div className="description">{tMatchingMission.description as string}</div>
								<Button label={t('barcode_scanner.matching_mission.sub_panel_mission.button_label')} className='secondary thin full-width' onClick={missionScanInstead} />
							</div>
							<div className="sub-panel">
								<div className="title">{t('barcode_scanner.matching_mission.sub_panel_daily.title')}</div>
								<div className="description">{t('barcode_scanner.matching_mission.sub_panel_daily.description')}</div>
								<Button label={t('barcode_scanner.matching_mission.sub_panel_daily.button_label')} className='secondary thin full-width' onClick={dailyScanInstead} />
							</div>
						</div>}
						<CloseIcon onClick={onClickCloseButton} />
					</div>
				</CSSTransition>

				{codeBeingSent && <CodeSubmission code={codeBeingSent} />}
				{showScanReward && createPortal(<ScanRewards code={showScanReward.code} missionData={showScanReward.missionData} onClose={() => { closeScanReward(); onClickClose(); navigate(AppRouting.missions.type(slugify(MISSIONS_TYPES.PRODUCT_SCAN, { lower: true }))); }} />, document.body)}
				{showDailyScanReward && <DailyScanRewards data={showDailyScanReward} onClose={() => { closeDailyScanReward(); onClickClose(); }} />}
			</div>
		</CSSTransition>
	);
}

export default memo(BarcodeScannerModalNew);