import { NavLink, useMatch } from 'react-router-dom';
import { AppRouting } from '../../AppRoutes';
import useAppState from '../../hooks/useAppState';
import { APP_STATES } from '../../redux/env';
import { useTranslation } from 'react-i18next';
import MenuIcon from '../icons/MenuIcon';
import AnimatedBottleCap from '../ui/AnimatedBottleCap';
import { CDN_BASE } from '../../Constants';

export default function Menu() {
	const { t } = useTranslation();
	const { appState } = useAppState();
	const isWorldMap = useMatch(AppRouting.worldMap.index);
	const isAvatarSelection = useMatch(AppRouting.avatarSelection.index);
	const isAuth = appState === APP_STATES.AUTH;

	const showMenu = !isWorldMap && !isAvatarSelection && !isAuth;

	return showMenu && (
		<div className="menu">
			<nav className="nav">
				<NavLink className="menu-item" to={AppRouting.missions.type('treasure-hunt')}>{t('menu.missions')}</NavLink>
				<NavLink className="menu-item" to={AppRouting.missions.type('product-scan')}>{t('menu.scan')}</NavLink>
				<NavLink to={AppRouting.root} className="center-btn">
					<MenuIcon />
					<img src={CDN_BASE + 'bottle-cap.png'} alt="" className="cap" />
					<div className="shadow"></div>
				</NavLink>
				<NavLink className="menu-item" to={AppRouting.rewards.index}>{t('menu.rewards')}</NavLink>
				<NavLink className="menu-item" to={AppRouting.you.leaderboard}>{t('menu.rank')}</NavLink>
			</nav>
		</div>
	);
}