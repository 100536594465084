
export const GLOBAL_NAMESPACE = 'coke-cgp-poc';

export const APP_VERSION = '2025-05-30.1';

export const STORE_NAME = 'coke-cgp-poc';

declare global {
	interface Window {
		APP_CONFIG: {
			BASE_PATH: string;
			AS_STACK_APP_ID: string;
			PLAYFAB_APP_ID: string;
			REALTIME_API_KEY: string;
		}
	}
}

export const LOCALIZATION_BASE = 'https://salocalestccccgppocapp.blob.core.windows.net/locales/';

export const XR_CONFIG = {
	apiUrl: 'https://tccc.xrserver.com',
	appId: window.APP_CONFIG.AS_STACK_APP_ID,
} as IXRConfig;

export const PLAYFAB_CONFIG = {
	appId: window.APP_CONFIG.PLAYFAB_APP_ID,
} as IPlayFabConfig;


export const REALTIME_CONFIG = {
	apiKey: window.APP_CONFIG.REALTIME_API_KEY,
	apiUrl: 'https://realtime.tccc.xrserver.com/api',
} as IRealtimeConfig;

export const API_CONFIG = {
	xr: XR_CONFIG,
	playfab: PLAYFAB_CONFIG,
	realtime: REALTIME_CONFIG,
} as IApiConfig;

export const DEFAULT_LANG = 'en';

export const CDN_BASE = 'https://tccccgppocappdata.blob.core.windows.net/assets/lkwd-poc/';

export const DEFAULT_ERROR_MESSAGE = 'An error has occurred';

export const BASE_PATH = window.APP_CONFIG.BASE_PATH;

export const EXCLUDED_LOGGER_ACTIONS = [] as string[];

declare global {
	interface Window {
		tccc_analytics: {
			country: string;
			site_country: string;
		};
	}
}

export const POLL_RATES = {
	SEND_HEARTBEAT: 1000 * 60,
};

export const ITEM_CLASSES = {
	OVERRIDE: 'Override',
	BADGE: 'Badge',
	SURVEY: 'Survey',
	GIFT_CARD: 'Gift Card',
};

export const DISPLAY_NAME_DIGITS = 0;

export const LEADERBOARD_LENGTH = 20;

export const MISSIONS_TYPES = {
	TREASURE_HUNT: 'Treasure Hunt',
	PRODUCT_SCAN: 'Product Scan',
};

export const OBJECTIVE_TYPES = {
	GEOFENCE: 'Geofence Objective',
	PRODUCT_SCAN: 'Product Scan Objective',
};

export enum QuestionTypes {
	Multiple = 'multiple',
	MultipleImage = 'multiple-image',
	Inputs = 'inputs',
}

export const PLAYER_EVENTS = {
	PLAYER_SCANNED_CODE: 'player_scanned_code',
	PLAYER_TRAVELED_THRESHOLD: 'player_traveled_threshold',
	PLAYER_VISITED_LOCATION: 'player_visited_location',
	PLAYER_SURVEY_AVAILABLE: 'player_survey_available',
	PLAYER_VIEWED_LEADERBOARD: 'player_viewed_leaderboard',
	PLAYER_SET_SUBREGION: 'player_set_subregion',
};

export const PREDICTION_MATCH_ID = 'match-id';

export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoibWF4aW1lZGVzcm9jaGVzIiwiYSI6ImNsd3RvdGNiNDA1engyanBzdXJrZmgyMDEifQ.CRBgJ892onoEiwbw_5-lrg';