import { NavLink, Outlet } from 'react-router-dom';
import { AppRouting } from '../../AppRoutes';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { useRef } from 'react';

export default function You() {
	const { t } = useTranslation();
	const ref = useRef<HTMLDivElement>(null);

	return <CSSTransition nodeRef={ref} in={true} timeout={600} appear>
		<div className="you" ref={ref}>
			<div className="tab-btns">
				<NavLink to={AppRouting.you.index} end className="tab-btn">{t('you.tabs.profile')}</NavLink>
				<NavLink to={AppRouting.you.leaderboard} className="tab-btn">{t('you.tabs.leaderboard')}</NavLink>
				<NavLink to={AppRouting.you.badges} className="tab-btn">{t('you.tabs.badges')}</NavLink>
			</div>
			<div className="tab-content">
				<Outlet />
			</div>
		</div>
	</CSSTransition>;
}