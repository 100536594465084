import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { reportApiError } from '../../utils/reportApiError';
import { IAppDispatch } from '../config/store';
import { incrementErrors } from '../env';
import { getXrApi } from '../../api/apiBridge';

type Config = { dispatch: IAppDispatch, state: unknown, rejectValue: unknown };

type StackActionPromise<Returned, ThunkArgs> = (args: ThunkArgs) => Promise<Returned>;

export default function xrAction<ActionType extends GenericApiCallResponse<ActionType['data']>, ThunkArgs = unknown>(
	promise: StackActionPromise<ActionType, ThunkArgs>,
): AsyncThunkPayloadCreator<ActionType, ThunkArgs, Config> {
	return async (data: ThunkArgs, { dispatch, rejectWithValue }) => {
		const resp = await promise(data);
		if (resp.code === 401 || resp.code === 409) {
			getXrApi().resetAuthCookies();
			window.location.reload();
			console.error('LOGGED OUT');
		} else if (!resp.success) {
			dispatch(incrementErrors());
			reportApiError('PlayfabAPI', resp);
			return rejectWithValue(resp);
		}

		return resp as ActionType;
	};
}
