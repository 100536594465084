/* eslint-disable no-console */
import { point, distance, circle, FeatureCollection } from '@turf/turf';
import { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { OBJECTIVE_TYPES, PLAYER_EVENTS, STORE_NAME } from '../Constants';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { writePlayerEvent } from '../redux/playfab';
import useGeolocation from './useGeolocation';
import useMissions from './useMissions';
import { MapMouseEvent } from 'mapbox-gl';
import last from '../utils/last';
import useGlobalVariables from './useGlobalVariables';
import useStats from './useStats';
import useTelemetry, { TelemetryEvents } from './useTelemetry';
import usePlayerData from './usePlayerData';
import usePlayerStatistics from './usePlayerStatistics';
import { inAppMessages } from '../components/RealtimeVisualizer';
import useRegion from './useRegion';

const DISTANCE_TRAVELED_THRESHOLD_M = 100;
const TRACK_LENGTH = 100;

function getPlayerPositionsInLS() {
	return (JSON.parse(localStorage.getItem(STORE_NAME + '_player_positions') || '[]') as [number, number][]).slice(-TRACK_LENGTH);
}

function setPlayerPositionsInLS(positions: [number, number][]) {
	// inAppMessages.trigger('Setting player positions', { positions });
	localStorage.setItem(STORE_NAME + '_player_positions', JSON.stringify(positions));
}

function addPlayerPositionInLS(position: [number, number]) {
	// inAppMessages.trigger('addPlayerPositionInLS', { position });
	const positions = [...getPlayerPositionsInLS(), position];
	setPlayerPositionsInLS(positions);
	return positions;
}

function getLastCenter(): [number, number] | null {
	const lastLocation = localStorage.getItem(STORE_NAME + '_last_map_center');
	if (lastLocation) {
		return JSON.parse(lastLocation);
	}
	return null;
}

function setLastCenter(location: [number, number]) {
	localStorage.setItem(STORE_NAME + '_last_map_center', JSON.stringify(location));
}

function getSeenModalLS(missionId:string):boolean {
	const modals = JSON.parse(localStorage.getItem(STORE_NAME + '_seen_modals') || '[]');
	return modals.includes(missionId);
}

function setSeenModalLS(missionId:string) {
	const modals = JSON.parse(localStorage.getItem(STORE_NAME + '_seen_modals') || '[]');
	localStorage.setItem(STORE_NAME + '_seen_modals', JSON.stringify([...modals, missionId]));
}

export default function useWorldMap() {
	const { logEvent } = useTelemetry();
	const [skipGeoloc, setSkipGeoloc] = useState(false);
	const mapRef = useRef<mapboxgl.Map>(null);
	const missionsListRef = useRef<HTMLDivElement>(null);
	const dispatch = useAppDispatch();
	const { userType } = usePlayerStatistics();

	const {
		pointsOfSale,
	} = useGlobalVariables();
	const missions = useMissions();
	const isMissionsLoaded = useAppSelector(state => state.missions.isLoaded);
	const activeMission = missions.find(m => m.isActiveMission);

	const { currentRegion, currentSubRegion } = useRegion();

	const regionData = currentSubRegion || currentRegion;
	
	const { playerData } = usePlayerData();

	const [rewardsPanelData, setRewardsPanelData] = useState<SendMissionInputResponse>(null);
	const [seenModal, setSeenModal] = useState(getSeenModalLS(activeMission?.itemId || ''));
	const [center, setCenter] = useState<[number, number]>(getLastCenter() || regionData?.center);
	const previousPlayerPosition = useRef<[number, number]>(null);
	const [playerPosition, setPlayerPosition] = useState<[number, number]>(last(getPlayerPositionsInLS()) || [0, 0]);
	const [linePoints, setLinePoints] = useState<[number, number][]>(getPlayerPositionsInLS());
	const [followPlayer, setFollowPlayer] = useState(!skipGeoloc);

	const [showGeolocationError, setShowGeolocationError] = useState(false);

	const {
		watchLocation,
	} = useGeolocation();

	const places = useMemo(() => {
		const list = missions.reduce((carry, mission) => {
			const lastUnCompletedObjective = mission.objectives.find((objective) => !objective.PlayerStatus?.IsComplete);
			mission.objectives.forEach((objective) => {
				if (objective.type.title === OBJECTIVE_TYPES.GEOFENCE) {
					carry.push({
						type: 'poi',
						placeName: objective.title,
						proximityRadius: objective.publicData.distance,
						coordinates: objective.publicData.coordinates,
						visited: objective?.PlayerStatus?.IsComplete,
						icon: mission.data.icon as string || null,
						mission: mission,
						objective: objective,
						canBeClaimed: lastUnCompletedObjective === objective && mission.isActiveMission,
					});
				}
			});
			return carry;
		}, [] as Place[]);

		pointsOfSale.forEach((pointOfSale) => {
			list.push({
				...pointOfSale,
				visited: false,
				mission: null,
				objective: null,
				canBeClaimed: false,
			});
		});

		list.sort((a, b) => a.coordinates.lon - b.coordinates.lon);

		return list;
	}, [missions, pointsOfSale]);

	const placeInProximity = useMemo(() => {
		if (!playerPosition) return { distance: Infinity, place: null as typeof places[0] };
		const playerPoint = point(playerPosition);
		const closest = places.reduce((carry, place) => {
			const dist = distance([place.coordinates.lon, place.coordinates.lat], playerPoint.geometry.coordinates, { units: 'meters' });
			if (dist <= place.proximityRadius && dist < carry.distance) {
				return { distance: dist, place: place };
			}
			return carry;
		}, { distance: Infinity, place: null as typeof places[0] });

		return closest;
	}, [playerPosition, places]);
	// console.log('Distance to place in proximity', placeInProximity.distance + 'm');

	const distanceFromNextPlace = useMemo(() => {
		if (!playerPosition) return 0;
		const playerPoint = point(playerPosition);
		const nextPlace = places.find((place) => place.canBeClaimed);
		if (!nextPlace) return 0;
		const placePoint = point([nextPlace.coordinates.lon, nextPlace.coordinates.lat]);

		const d = distance(placePoint, playerPoint.geometry.coordinates, { units: 'meters' });
		return d;
	}, [playerPosition, places]);

	const [mapViewport, setMapViewport] = useState({
		zoom: [15] as [number],
	});

	const placeInProximityElem = placeInProximity.place && (placeInProximity.distance <= placeInProximity.place.proximityRadius && {
		type: 'FeatureCollection',
		features: [circle(point([placeInProximity.place.coordinates.lon, placeInProximity.place.coordinates.lat]), placeInProximity.place.proximityRadius, { units: 'meters' })],
	} as FeatureCollection);

	const isOutOfBounds = regionData && distance(point(playerPosition), point(regionData.center), { units: 'meters' }) > (regionData.radius || 800);
	
	const onClickCenter = useCallback(() => {
		if (showGeolocationError) {
			window.alert('Geolocation error, please allow geolocation in your browser settings');
			return;
		}
		
		setCenter(playerPosition);
		setFollowPlayer(true);
		setSkipGeoloc(false);
		mapRef.current.panTo(playerPosition);

		logEvent(TelemetryEvents.clickCenterMap({}));
	}, [playerPosition, center, mapRef, showGeolocationError]);

	const [currentPlace, setCurrentPlace] = useState<Place | null>(null);
	const [showMissions, setShowMissions] = useState(false);

	const onMove = (e:mapboxgl.Map) => {
		const { lng, lat } = e.getCenter();
		const [lastLng, lastLat] = getLastCenter() || [0, 0];

		setFollowPlayer(false);

		if (lng !== lastLng || lat !== lastLat) {
			setLastCenter([lng, lat]);
		}
	};
	
	const distanceTraveled = useRef(0);
	const changePlayerPosition = useCallback((newPosition:[number, number]) => {
		setPlayerPosition((currentPlayerPosition) => {
			// inAppMessages.trigger('null position', { status: newPosition[0] === 0 && newPosition[1] === 0 });
			if (newPosition[0] === 0 && newPosition[1] === 0) return currentPlayerPosition;
			
			// inAppMessages.trigger('playerPosition', { playerPosition, newPosition, previousPlayerPosition: previousPlayerPosition.current });
			if (!currentPlayerPosition || (currentPlayerPosition[0] === 0 && currentPlayerPosition[1] === 0) || !previousPlayerPosition.current) {
				// inAppMessages.trigger('First player position', { position: newPosition });
				previousPlayerPosition.current = newPosition;
			}
			
			// inAppMessages.trigger('next player position', { newPosition, previous: previousPlayerPosition.current[0] !== 0 || previousPlayerPosition.current[1] !== 0 });
			if (previousPlayerPosition.current[0] !== 0 || previousPlayerPosition.current[1] !== 0) {
				// inAppMessages.trigger('moved', { newPosition, oldPosition: previousPlayerPosition.current });
				const dist = distance(point(newPosition), point(previousPlayerPosition.current), { units: 'meters' });
				// inAppMessages.trigger('Distance', { distance: dist });
				if (dist !== 0) {
					distanceTraveled.current += dist;
					// inAppMessages.trigger('player_moved', { distance: dist, totalDistance: distanceTraveled.current });
					
					setLinePoints(addPlayerPositionInLS(newPosition));
					
					if (distanceTraveled.current > DISTANCE_TRAVELED_THRESHOLD_M) {
						distanceTraveled.current = 0;
						dispatch(writePlayerEvent({
							EventName: PLAYER_EVENTS.PLAYER_TRAVELED_THRESHOLD,
							Body: {
								distance: Math.round(DISTANCE_TRAVELED_THRESHOLD_M),
							},
						}));
					}
				}
			}

			previousPlayerPosition.current = newPosition;

			if (followPlayer) {
				setCenter([...newPosition]);
			}

			return [...newPosition];
		});
	}, [setPlayerPosition, followPlayer, previousPlayerPosition]);

	useEffect(() => {
		if (skipGeoloc) {
			changePlayerPosition(last(getPlayerPositionsInLS()) || [0, 0]);
			return () => {};
		}

		let once = false;
		const unwatchLocation = watchLocation((position) => {
			inAppMessages.trigger('geoloc_change', { position });
			if (skipGeoloc) return;
			changePlayerPosition([position.coords.longitude, position.coords.latitude]);

			if (!once) {
				setCenter([position.coords.longitude, position.coords.latitude]);
				once = true;
			}
		}, (e) => {
			setShowGeolocationError(true);
			console.error('Geolocation error', e);
			
			logEvent(TelemetryEvents.geolocationError({ error: e.message }));
		});

		return () => {
			unwatchLocation();
		};
	}, [skipGeoloc, changePlayerPosition, watchLocation]);

	useEffect(() => {
		if (mapRef.current && userType === 99) {
			function detectDoubleTap(doubleTapMs) {
				let timeout, lastTap = 0;
				return (event) => {
					const currentTime = new Date().getTime();
					const tapLength = currentTime - lastTap;
					if (0 < tapLength && tapLength < doubleTapMs) {
						const evt = event as any as MapMouseEvent;
						setSkipGeoloc(true);
						changePlayerPosition([evt.lngLat.lng, evt.lngLat.lat]);
						evt.preventDefault();
					} else {
						timeout = setTimeout(() => clearTimeout(timeout), doubleTapMs);
					}
					lastTap = currentTime;
				};
			}

			const fn = detectDoubleTap(300);

			mapRef.current.on('click', fn);
			mapRef.current.doubleClickZoom.disable();
			
			return () => {
				mapRef.current?.off?.('click', fn);
				mapRef.current?.doubleClickZoom?.enable?.();
			};
		}
		return () => {};
	}, [mapRef, changePlayerPosition, userType]);

	useEffect(() => {
		setSeenModal(getSeenModalLS(activeMission?.itemId || ''));
	}, [activeMission]);

	return {
		isMissionsLoaded,
		currentPlace,
		activeMission,
		seenModal,
		mapRef,
		mapViewport,
		center,
		playerPosition,
		places,
		placeInProximity,
		placeInProximityElem,
		linePoints,
		showMissions,
		missionsListRef,
		rewardsPanelData,
		showGeolocationError,
		regionData,
		seenFirstMission: Boolean(playerData?.seenFirstMission),
		isOutOfBounds,
		distanceFromNextPlace,
		nextPlace: places.find((place) => place.canBeClaimed),
		debugMovePlayer: () => {
			changePlayerPosition(mapRef.current.getCenter().toArray() as [number, number]);
		},
		setSeenModal: (seen:boolean) => {
			setSeenModalLS(activeMission?.itemId || '');
			setSeenModal(seen);
		},
		onClickShowMissions: () => {
			setShowMissions(true);
			logEvent(TelemetryEvents.clickShowMissions({}));
		},
		onClickCloseMissions: () => {
			setShowMissions(false);
			logEvent(TelemetryEvents.clickCloseMissions({}));
		},
		onClickOutOfBounds: () => {
			if (regionData) {
				setCenter(regionData.center);
				setFollowPlayer(false);
			}
		},
		setCurrentPlace,
		onMove,
		setMapViewport,
		changePlayerPosition,
		setShowMissions,
		onClickCenter,
		setRewardsPanelData,
	};
}