import { createSlice } from '@reduxjs/toolkit';

const game = createSlice({
	name: 'game',
	initialState: {
		pageTitle: '',
		productScanVisible: false,
		pollsTabOpened: false,
	},
	reducers: {
		setPageTitle: (state, action) => {
			state.pageTitle = action.payload;
		},
		setProductScanVisible: (state, action) => {
			state.productScanVisible = action.payload;
		},
		setPollsTabOpened: (state, action) => {
			state.pollsTabOpened = action.payload;
		},
	},
});

export default game;

export const { setPageTitle, setProductScanVisible, setPollsTabOpened } = game.actions;