import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '../redux/config/store';

function missionByRegion(mission:IXRMissionItem, region: number, subregion: number = null) {
	const matchRegion = Boolean(mission.playfab?.Tags?.find?.(t => t === 'region' + region));
	if (!matchRegion) return false;
	if (subregion === 0 || subregion === null) return matchRegion;
	return Boolean(mission.playfab?.Tags?.find?.(t => t === 'subregion' + subregion));
}

export const missionsSelector = createSelector(
	[
		(state:IRootState) => state.missions.list,
		(state:IRootState) => state.statistics.region,
		(state:IRootState) => state.statistics.subregion,
		(state:IRootState) => state.playerData?.data?.activeMissionId,
	],
	(missions, region, subregion, activeMissionId) => {
		return missions.filter(m => missionByRegion(m, region, subregion)).map((m) => ({
			...m,
			isActiveMission: m.PlayerStatus && !m.PlayerStatus?.IsComplete && m.itemId === activeMissionId,
		})) as (typeof missions[0] & { isActiveMission:boolean })[];
	},
);