import { useEffect, useRef, useState } from 'react';
import useMissions from '../../hooks/useMissions';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { useAppDispatch, useAppSelector } from '../../redux/config/store';
import { updatePlayerData } from '../../redux/playerData';
import usePlayerData from '../../hooks/usePlayerData';
import { MISSIONS_TYPES } from '../../Constants';
import { NavLink, useParams } from 'react-router-dom';
import Mission from './Missions/Mission';
import { AppRouting } from '../../AppRoutes';
import slug from '../../utils/slug';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { getMissionInventory } from '../../redux/missions';

const SPACING = 15;

export default function Missions() {
	const { t }  = useTranslation();
	const missionsLoaded = useAppSelector(state => state.missions.isLoaded);
	const type = useParams().type === slug(MISSIONS_TYPES.PRODUCT_SCAN) ? slug(MISSIONS_TYPES.PRODUCT_SCAN) : slug(MISSIONS_TYPES.TREASURE_HUNT);
	const missions = useMissions().filter(m => slug(m.type.title) === type);
	const tabsRef = useRef<HTMLDivElement>(null);
	const missionListRef = useRef<HTMLDivElement>(null);
	const dispatch = useAppDispatch();

	const sliderRef = useRef<SwiperRef>(null);
	const resetSlide = useRef<string | null>(null);

	useEffect(() => {
		dispatch(getMissionInventory());
	}, []);

	const sortedMissions = missions.sort((a, b) => {
		if (a.isActiveMission) return -1;
		if (b.isActiveMission) return 1;
		if (a.PlayerStatus?.IsComplete) return 1;
		if (b.PlayerStatus?.IsComplete) return -1;
		return 0;
	});

	const [space, setSpace] = useState(SPACING);
	const {
		playerData: {
			activeMissionId,
		},
	} = usePlayerData();

	useEffect(() => {
		const resize = () => {
			setSpace(SPACING * window.innerWidth / 395);
		};

		window.addEventListener('resize', resize);
		resize();

		return () => window.removeEventListener('resize', resize);
	}, []);

	useEffect(() => {
		if (resetSlide.current) {
			const index = sortedMissions.findIndex(m => m.itemId === resetSlide.current);
			sliderRef.current.swiper.slideTo(index, 0, false);
			resetSlide.current = null;
		}
	}, [resetSlide, missions, sortedMissions]);
	
	const setActiveMission = async (missionId:string) => {
		resetSlide.current = missionId;
		await dispatch(updatePlayerData({ activeMissionId: missionId }));
	};

	return <div className="missions-page">
		<CSSTransition nodeRef={tabsRef} in={missionsLoaded} timeout={1200} appear mountOnEnter unmountOnExit>
			<div ref={tabsRef} className="tabs">
				<NavLink to={AppRouting.missions.type(slug(MISSIONS_TYPES.TREASURE_HUNT))} className={type === slug(MISSIONS_TYPES.TREASURE_HUNT) ? 'active' : ''}>{t('missions.types.treasure_hunt')}</NavLink>
				<NavLink to={AppRouting.missions.type(slug(MISSIONS_TYPES.PRODUCT_SCAN))} className={type === slug(MISSIONS_TYPES.PRODUCT_SCAN) ? 'active' : ''}>{t('missions.types.product_scan')}</NavLink>
			</div>
		</CSSTransition>
		<CSSTransition nodeRef={missionListRef} key={type} in={missionsLoaded} timeout={1200} appear mountOnEnter unmountOnExit>
			<div ref={missionListRef} className="missions-list">
				<Swiper
					ref={sliderRef}
					spaceBetween={space}
					slidesPerView={1}
				>
					{sortedMissions.map((mission) => {
						return (
							<SwiperSlide className='mission' key={mission.itemId}>
								<Mission mission={mission} activeMissionId={activeMissionId} setActiveMission={setActiveMission} />
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</CSSTransition>
	</div>;
}