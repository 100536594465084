import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { CustomNavigationClient } from './NavClient/CustomNavigationClient';

export const ClientSideNavigation = ({ instance, children }) => {
	const navigate = useNavigate();

	const navigationClient = new CustomNavigationClient(navigate);
	instance.setNavigationClient(navigationClient);

	const [firstRender, setFirstRender] = useState(true);

	useEffect(() => { setFirstRender(false); }, []);

	if (firstRender) { return null; }

	return children;
};
