import { useTranslation } from 'react-i18next';
import { CDN_BASE, PLAYER_EVENTS } from '../../../Constants';
import useLeaderboard from '../../../hooks/useLeaderboard';
import usePlayer from '../../../hooks/usePlayer';
import useXP from '../../../hooks/useXP';
import { guid } from '../../../utils/guid';
import { CSSProperties, useEffect, useRef } from 'react';
import { useAppDispatch } from '../../../redux/config/store';
import { writePlayerEvent } from '../../../redux/playfab';
import { CSSTransition } from 'react-transition-group';
import { raw } from '../../../utils/textUtils';

export default function LeaderboardTab() {
	const { t } = useTranslation();
	const ref = useRef<HTMLDivElement>(null);
	const { statName } = useXP();
	const {
		leaderboard,
	} = useLeaderboard(statName, true);
	const { playerId, AvatarUrl } = usePlayer();
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(writePlayerEvent({
			EventName: PLAYER_EVENTS.PLAYER_VIEWED_LEADERBOARD,
		}));
	}, [dispatch]);

	const fill = Array.from({ length: 20 - leaderboard.length });

	const leaderboardFilled:LeaderboardEntry[] = [...leaderboard, ...fill.map(() => ({ PlayFabId: guid(), DisplayName: '...', StatValue: 0, Profile: {} } as LeaderboardEntry))];
	const medals = [
		{ glow: 'gold', image: CDN_BASE + 'gold-medal.png' },
		{ glow: 'silver', image: CDN_BASE + 'silver-medal.png' },
		{ glow: 'bronze', image: CDN_BASE + 'bronze-medal.png' },
	];

	return <CSSTransition nodeRef={ref} in={true} timeout={2400} appear>
		<div ref={ref} className="tab leaderboard-tab">
			<div className="title">{t('you.leaderboard_tab.title')}</div>
			<div className="subtext" {...raw(t('you.leaderboard_tab.subtext'))} />
			<div className="leaderboard">
				{leaderboardFilled.map((entry, i) => <div key={entry.PlayFabId} style={{ '--i': i } as CSSProperties} className={`leaderboard-entry ${medals[i]?.glow ? 'glow-' + medals[i]?.glow : ''} ${entry.PlayFabId === playerId ? 'you' : ''}`}>
					{i < 3 && <div className="rank"><img width="100" height="100" src={medals[i].image} alt="" /></div>}
					{i >= 3 && <div className="rank">{i + 1}</div>}
					<div className="name">{entry.Profile?.AvatarUrl && <img className="avatar" src={entry.Profile?.AvatarUrl} alt="" /> }{entry.DisplayName}</div>
					<div className="value">{entry.StatValue}xp</div>
				</div>)}
			</div>
		</div>
	</CSSTransition>;
}