import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';
import { getXrApi, playfabCloudScriptApi } from '../api/apiBridge';
import { getPlayerCombinedInfo } from './playfab';
import { playerDataToValues } from './playerData';

export type XRQuizAnswerRewards = {
	statistics: Record<string, number>,
	items: IPlayfabItemInstance[],
};

interface XRQuizChoiceStatisticsWithHighest extends XRQuizChoiceStatistics {
	isHighest: boolean,
}

interface XRQuizQuestionStatisticsWithHighest extends XRQuizQuestionStatistics {
	result: XRQuizChoiceStatisticsWithHighest[],
}

export type XRQuizInstance = Omit<XRQuizInstanceItem, 'rewards'> & {
	customData: Record<string, any>,
	questions: XRQuizInstanceQuestion[],
	rewards: XRGrantedRewards,
	answers: Record<string, string | boolean>[],
	statistics: XRQuizQuestionStatisticsWithHighest[],
	result: XRQuizAnswerResult[],
	isClaimed: boolean,
};


const defaultState = {
	instances: [] as XRQuizInstance[],
	answers: {} as Record<string, string>,
	statistics: [] as XRQuizQuestionStatistics[],
};

export function quizInstanceFromInstantiationEvent(data: Record<string, any>): XRQuizInstance {
	return {
		id: data?.QuizInstanceId as string,
		instanceId: data?.QuizInstanceId as string,
		matchId: data?.QuizMatchId as string,
		type: data?.QuizData?.type as string,
		displayName: data?.QuizData?.name as string,
		timestamp: data?.QuizData?.timestamp,
		voteExpiration: data?.QuizData?.voteExpiration,
		totalExpiration: data?.QuizData?.totalExpiration,
		customData: data?.QuizData?.customData,
		questions: data?.QuizData?.questions,
		jsonUrl: data?.QuizUrl,
		isResolved: false,
		isAnswered: false,
		isClaimed: false,
		segmentId: null,
		playerId: null,
		rewards: null,
		result: null,
		answers: [],
		statistics: [],
	};
}

export type AFAnswerQuizResponse = { data: { FunctionResult: { data: AnswerQuizResponse & { instanceId: string, PartialStatistics: XRQuizQuestionStatistics[] } } } };

export const answerQuiz = createDebouncedAsyncAction(
	'quizzes/answerQuiz',
	(data: { groupId: string, InstanceId: string, Answers: string | Record<string, string | Record<string, string | number>>, type: string, TimeToAnswer?: number }) => {
		return playfabCloudScriptApi('ExecuteFunction', {
			FunctionName: 'AnswerQuiz',
			FunctionParameter: {
				quizType: data.type,
				...data,
				instanceId: data.InstanceId,
				sessionTicket: getXrApi().GetSessionTicket(),
				groupId: data.groupId,
				timeToAnswer: data.TimeToAnswer,
				answers: data.Answers,
			},
		}) as Promise<AFAnswerQuizResponse>;
	},
);

export const claimPrediction = createDebouncedAsyncAction(
	'quizzes/claimPrediction',
	(data: { InstanceId: string }) => {
		const api = getXrApi();
		return api?.Client?.ClaimPredictionRewards(data).then(result => {
			return {
				instanceId: data.InstanceId,
				rewards: result.data.Rewards,
			};
			
		}) || Promise.reject('No API');
	},
);

const quizzes = createSlice({
	name: 'quizzes',
	initialState: defaultState,
	reducers: {
		addQuizInstance: (state, action: PayloadAction<XRQuizInstance>) => {
			const instance = action.payload;
			// console.log('addQuizInstance', instance);
			state.instances = [
				...state.instances.filter(p => p.instanceId !== instance.instanceId),
				instance,
			];
		},
		removeQuizInstance: (state, action: PayloadAction<string>) => {
			state.instances = state.instances.filter(p => p.instanceId !== action.payload);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(answerQuiz.actions.pending, (state, action) => {
			state.answers[action.meta.arg.groupId] = Object.values(action.meta.arg.Answers).join('');
		});
		builder.addCase(answerQuiz.actions.fulfilled, (state, action) => {
			const payload = action.payload.data.FunctionResult.data;
			const instance = state.instances.find(p => p.instanceId === payload.instanceId);
			const groupId = action.meta.arg.groupId;
			if (!instance) return;

			if (payload.Statistics) {
				state.statistics = [
					...state.statistics.filter(p => p.id !== groupId),
					...payload.Statistics.map(s => ({ id: groupId, result: Array.isArray(s.result) ? s.result : Object.values(s.result) as XRQuizChoiceStatistics[] })),
				];
			}

			instance.isAnswered = true;
			if (!payload.Answers) return;

			// instance.result = action.payload.data.Answers;
			instance.rewards = payload.Rewards as XRGrantedRewards;
			instance.answers = Object.values(payload.Answers).map((answer: Record<string, any>) => {

				// if there is no correct answer, we need to keep it as unresolved (null), for predictions
				if (!answer.correctAnswer) return { ...answer, isCorrect: null };

				return {
					...answer,
					isCorrect: answer.correctAnswer === answer.userAnswer,
				};
			});
		});
		
		builder.addCase(getPlayerCombinedInfo.actions.fulfilled, (state, action) => {
			const result = action.payload.data.InfoResultPayload;

			const data = {
				...playerDataToValues(result.UserData),
				...playerDataToValues(result.UserReadOnlyData),
			};
			
			state.answers = Object.entries(data).reduce((c, [key, value]) => {
				if (key.startsWith('quiz-')) {
					c[key.replace('quiz-', '')] = value;
				}

				return c;
			}, {} as Record<string, string>);
		});
	},
});

export default quizzes;

export const { addQuizInstance, removeQuizInstance } = quizzes.actions;
