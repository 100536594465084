import HeaderBG from '../icons/HeaderBg';
import useXP from '../../hooks/useXP';
import numberWithCommas from '../../utils/numberWithCommas';
import usePlayer from '../../hooks/usePlayer';
import { NavLink, useMatch } from 'react-router-dom';
import BellIcon from '../icons/BellIcon';
import { useAppDispatch, useAppSelector } from '../../redux/config/store';
import useSurveys from '../../hooks/useSurveys';
import Notification from '../ui/Notification';
import { AppRouting } from '../../AppRoutes';
import useAppState from '../../hooks/useAppState';
import { APP_STATES } from '../../redux/env';
import { setPollsTabOpened } from '../../redux/game';
import { Trans } from 'react-i18next';
import useTelemetry, { TelemetryEvents } from '../../hooks/useTelemetry';

export default function Header() {
	const {
		xp,
	} = useXP();

	const {
		list,
	} = useSurveys();
	const { logEvent } = useTelemetry();

	const { appState } = useAppState();
	const dispatch = useAppDispatch();
	const badgeNumber = list.filter(survey => survey.isAnswered === false).length;

	const onClickOpenNotifications = () => {
		dispatch(setPollsTabOpened(true));
		logEvent(TelemetryEvents.clickOpenNotifications({ pendingSurveys: badgeNumber }));
	};
	
	const isAvatarSelection = useMatch(AppRouting.avatarSelection.index);
	const isAuth = appState === APP_STATES.AUTH;
	const isProductScanVisible = useAppSelector(state => state.game.productScanVisible);

	const { AvatarUrl, DisplayName } = usePlayer();
	const showHeader = !isAvatarSelection && !isAuth && !isProductScanVisible;

	return showHeader && <header className="main-header">
		<div className="left">
			{AvatarUrl && <NavLink className='avatar' to={AppRouting.you.profile}><img width="100" height="100" src={AvatarUrl} /></NavLink>}
			<div className="text">
				<div className="greetings"><Trans i18nKey='header.greeting' name={DisplayName}>Hello,<br/><span className="name">{{ name: DisplayName } as any}!</span></Trans></div>
				<div className="xp"><span className="amount">{numberWithCommas(xp)}</span> XP</div>
			</div>
		</div>
		<div className="right">
			<div className="notification" onClick={onClickOpenNotifications}>
				<BellIcon />
				{badgeNumber > 0 && <div className="badge">{badgeNumber}</div>}
			</div>
		</div>
		<HeaderBG />
		<Notification />
	</header>;
}