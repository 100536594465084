import { CSSTransition } from 'react-transition-group';
import { useAppSelector } from '../../redux/config/store';
import { SinglePoll } from './SinglePoll';
import { useRef } from 'react';

export default function PopupPoll() {
	const ref = useRef<HTMLDivElement>(null);
	const activeInstanceId = useAppSelector((state) => state.surveys.activeInstanceId);

	return activeInstanceId && <CSSTransition key={activeInstanceId} nodeRef={ref} in={Boolean(activeInstanceId)} timeout={1200} appear>
		<div className="popup-poll" ref={ref}>
			<SinglePoll instanceId={activeInstanceId} key={activeInstanceId} />
		</div>
	</CSSTransition>;
}