/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-console */
import { useCallback, useEffect } from 'react';
import { API_CONFIG, ITEM_CLASSES, PLAYER_EVENTS } from '../Constants';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { getItemInventory } from '../redux/inventory';
import { getMissionInventory, setMissionCompleted } from '../redux/missions';
import { addNotification, notificationGenerator } from '../redux/notifications';
import { updateLocalAvatarUrl, updateLocalVirtualCurrency, updateLocalDisplayName } from '../redux/playfab';
import { updateLocalStatistic } from '../redux/statistics';
import { getStoreLoadout } from '../redux/xr_store';
import usePlayer from './usePlayer';
import useRealtimeConnection from '../api/react/hooks/useRealtimeConnection';
import { ANY_EVENT } from '../api/Realtime';
import { addQuizInstance } from '../redux/quizzes';
import { updatePlayerData } from '../redux/playerData';
import useTelemetry, { TelemetryEvents } from './useTelemetry';

function debugRealtime(msg:string) {
	const colors = (msg.match(/%c/g) || []).length;
	const d = new Date();
	const date = `%c @ ${d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2, '0') + ':' + d.getSeconds().toString().padStart(2, '0')}.${d.getMilliseconds()}`;
	switch (colors) {
		case 0:
			console.log(msg + date);
			break;
		case 1:
			console.log(msg + date, 'color: #ccc;', 'color: grey;');
			break;
		case 2:
			console.log(msg + date, 'color: #ccc;', 'color: #ffbca0;', 'color: grey;');
			break;
		case 3:
			console.log(msg + date, 'color: #ccc;', 'color: #ffbca0;', 'color: teal;', 'color: grey;');
			break;
		default:
		case 4:
			console.log(msg + date, 'color: #ccc;', 'color: #ffbca0;', 'color: teal;', 'color: yellow', 'color: grey;');
			break;
	}
}

export default function useRealtime() {
	const {
		versions,
		playerStatus,
	} = useAppSelector((state) => state.realtime);

	const { logEvent } = useTelemetry();

	const dispatch = useAppDispatch();

	const {
		playerId,
		SessionTicket,
	} = usePlayer();

	const onMessage = useCallback((data:any) => {
		const eventName = data.EventName;

		let msg = `%crealtime %c${eventName}`;

		switch (eventName) {
			case 'player_rule_executed':
				msg = `%crealtime %c${eventName} %c[${data.DisplayName}]`;
				break;
			case 'player_triggered_action_executed_cloudscript':
				msg = `%crealtime %c${eventName} %c[${data.FunctionName}]`;
				break;
			case 'player_action_executed':
				msg = `%crealtime %c${eventName} %c[${data.ActionName}]`;
				break;
			case 'player_entered_segment':
			case 'player_left_segment':
				msg = `%crealtime %c${eventName} %c[${data.SegmentName}]`;
				break;
			case 'player_statistic_changed':
				dispatch(updateLocalStatistic({
					name: data.StatisticName,
					value: data.StatisticValue,
				}));
				msg = `%crealtime %c${eventName} %c[${data.StatisticName}: ${data.StatisticPreviousValue} > ${data.StatisticValue}]`;

				if ((data.StatisticName as string).toLowerCase().includes('xp')) {
					logEvent(TelemetryEvents.xpEarned({ amount: data.StatisticValue, name: data.StatisticName, previousAmount: data.StatisticPreviousValue }));
				}

				break;
			case 'app_statistic_changed':
				msg = `%crealtime %c${eventName} %c[${data.StatisticName}: ${data.StatisticPreviousValue} > ${data.StatisticValue}]`;

				break;
			case 'player_consumed_item':
			case 'player_inventory_item_added':
				dispatch(getItemInventory());
				msg = `%crealtime %c${eventName} %c[${data.DisplayName}]`;

				if (data.ItemId.includes('m-')) {
					dispatch(getMissionInventory());
				}

				if (data.Class === ITEM_CLASSES.BADGE) {
					logEvent(TelemetryEvents.earnBadge({ badgeId: data.ItemId }));
				}

				if (data.Class === ITEM_CLASSES.SURVEY) {
					dispatch(addNotification(notificationGenerator({
						title: 'New Survey',
						message: 'A new survey is available for you!',
						link: data.InstanceId,
						eventName: PLAYER_EVENTS.PLAYER_SURVEY_AVAILABLE,
					})));
					logEvent(TelemetryEvents.surveyAvailable({ surveyId: data.InstanceId }));
				}
				break;
			case 'player_virtual_currency_balance_changed':
				dispatch(updateLocalVirtualCurrency({ currency: data.VirtualCurrencyName, amount: data.VirtualCurrencyBalance }));
				msg = `%crealtime %c${eventName} %c[${data.VirtualCurrencyName}: ${data.VirtualCurrencyPreviousBalance} > ${data.VirtualCurrencyBalance}]`;
				break;
			case 'player_displayname_changed':
				dispatch(updateLocalDisplayName(data.DisplayName));
				msg = `%crealtime %c${eventName} %c[${data.PreviousDisplayName} > ${data.DisplayName}]`;
				break;
			case 'player_changed_avatar':
				dispatch(updateLocalAvatarUrl(data.ImageUrl));
				break;
			case 'player_notification_pushed':
				dispatch(addNotification(data));
				break;
			case 'store':
			case 'player_store_cleared':
				dispatch(getStoreLoadout());
				break;
			case 'notifications':
				dispatch(addNotification(data));
				break;
			case 'player_mission_completed':
			case 'player_objective_progress':
			case 'player_objective_completed':
				dispatch(getMissionInventory());
				msg = `%crealtime %c${eventName} %c[${data.MissionId} > ${data.ObjectiveId}]`;

				if (eventName === 'player_mission_completed') {
					dispatch(updatePlayerData({ activeMissionId: null }));
					dispatch(setMissionCompleted({ ItemId: data.MissionId }));
					logEvent(TelemetryEvents.completeMission({ missionId: data.MissionId }));
				}
				break;
			case 'match_survey_instantiated':
			case 'match_trivia_instantiated':
				dispatch(addQuizInstance({
					...data.QuizData,
					totalExpiration: data.QuizTotalExpiration,
				}));
			break;
		}

		debugRealtime(msg);
	}, [dispatch, logEvent]);

	const {
		addListener,
		removeListener,
	} = useRealtimeConnection(API_CONFIG.realtime.apiUrl, API_CONFIG.playfab.appId, playerId, SessionTicket);

	useEffect(() => {
		addListener(ANY_EVENT, onMessage);

		return () => {
			removeListener(ANY_EVENT, onMessage);
		};
	}, [addListener, onMessage, removeListener]);

	return {
		newsVersion: versions.news,
		playerStatus,
	};
}