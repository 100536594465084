import { createSelector } from '@reduxjs/toolkit';
import { ITEM_CLASSES } from '../Constants';
import { IRootState } from '../redux/config/store';

export const surveyItemsSelector = createSelector([
	(state:IRootState) => state.inventory,
], (inventory) => {
	return inventory.filter((i) => i.type.title === ITEM_CLASSES.SURVEY);
});

export const activeSurveysSelector = createSelector([
	(state:IRootState) => state.surveys.list,
	surveyItemsSelector,
], (surveys, items) => {
	return surveys.filter((s) => items.find((i) => i.playfab?.InstanceData?.InstanceId === s.instanceId));
});