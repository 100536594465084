import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { getGlobalVariables } from '../redux/globalVariables';
import usePlayer from './usePlayer';

export default function useGlobalVariables() {
	const dispatch = useAppDispatch();
	const { playerId } = usePlayer();
	const globalVariables = useAppSelector((state) => state.globalVariables);

	useEffect(() => {
		if (!globalVariables.isLoaded && playerId) {
			dispatch(getGlobalVariables());
		}
	}, [dispatch, globalVariables.isLoaded, playerId]);

	return Object.entries(globalVariables).reduce((carry, gv) => {
		const [key, value] = gv;
		if (value as any === '[]') {
			carry[key] = [];
			return carry;
		}
		carry[key] = value;
		return carry;
	}, {} as typeof globalVariables);
}