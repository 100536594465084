import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { answerSurvey, getSurveyInstanceData, getSurveyInstances, setActiveInstanceId } from '../redux/surveys';
import { activeSurveysSelector } from '../selector/surveys';
import usePlayer from './usePlayer';
import useTelemetry, { TelemetryEvents } from './useTelemetry';

export function useSurvey(id:string) {
	const dispatch = useAppDispatch();
	const { playerId } = usePlayer();
	const activeInstanceId = useAppSelector((state) => state.surveys.activeInstanceId);
	const surveyInstance = useAppSelector((state) => state.surveys.list.find((s) => s.instanceId === id));
	const surveyData = useAppSelector((state) => state.surveys.data[id]);
	const { logEvent } = useTelemetry();

	const survey = {
		...surveyInstance,
		...surveyData,
	};

	const [isSubmittingAnswer, setIsSubmittingAnswer] = useState(false);

	useEffect(() => {
		if (!survey || !playerId) return;

		if (!surveyData && id) {
			dispatch(getSurveyInstanceData(survey.jsonUrl));
		}
	}, [id, survey, surveyData, dispatch, playerId]);

	return survey && {
		...survey,
		...surveyData,
		isSubmittingAnswer,
		isActiveInstance: activeInstanceId === id,

		answer: (answers: Record<string, string>) => {
			setIsSubmittingAnswer(true);

			return dispatch(answerSurvey({
				Answers: answers,
				InstanceId: survey.id,
			})).then(() => {
				setIsSubmittingAnswer(false);
				logEvent(TelemetryEvents.answerSurvey({ surveyId: survey.id, answers }));
			}).catch((e) => {
				logEvent(TelemetryEvents.answerSurveyError({ surveyId: survey.id, answers, error: e?.message }));
			});
		},
		open: () => {
			logEvent(TelemetryEvents.openSurvey({ surveyId: survey.id }));
			dispatch(setActiveInstanceId(id));
		},
		close: () => {
			logEvent(TelemetryEvents.closeSurvey({ surveyId: survey.id }));
			dispatch(setActiveInstanceId(null));
		},
	};
}

export default function useSurveys() {
	const dispatch = useAppDispatch();
	const { playerId } = usePlayer();
	const loaded = useAppSelector((state) => state.surveys.loaded);
	const surveys = useAppSelector(activeSurveysSelector);
	const { logEvent } = useTelemetry();

	useEffect(() => {
		if (!playerId || loaded) return;

		dispatch(getSurveyInstances({}));
	}, [playerId, loaded]);

	return {
		list: surveys,
		closeSurvey: () => {
			dispatch(setActiveInstanceId(null));
			logEvent(TelemetryEvents.closeSurveys({}));
		},
		openSurvey: (id:string) => {
			dispatch(setActiveInstanceId(id));
			logEvent(TelemetryEvents.openSurvey({ surveyId: id }));
		},
	};
}