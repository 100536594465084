import ReactMapboxGl, { Marker, GeoJSONLayer } from 'react-mapbox-gl';
import { LinePaint } from 'mapbox-gl';
import { lineString, point } from '@turf/turf';

import { CDN_BASE, MAPBOX_ACCESS_TOKEN } from '../../Constants';
import MapMarker from '../ui/treasure-hunt/MapMarker';
import TargetIcon from '../icons/TargetIcon';
import SmallChevronIcon from '../icons/SmallChevron';
import MissionsList from '../world-map/MissionsList';
import { CSSTransition } from 'react-transition-group';
import useWorldMap from '../../hooks/useWorldMap';
import IntroModal from '../world-map/IntroModal';
import RewardsPanel from '../ui/treasure-hunt/RewardsPanel';
import WarningIcon from '../icons/WarningIcon';
import usePlayer from '../../hooks/usePlayer';
import { useTranslation } from 'react-i18next';
import { raw } from '../../utils/textUtils';
import Radius from '../ui/treasure-hunt/Radius';
import QuestionMarkIcon from '../icons/QuestionMarkIcon';
import HelperRadius from '../ui/treasure-hunt/HelperRadius';
import { useRef } from 'react';
import { map as _map } from '../../utils/map';
import { constrain } from '../../utils/constrain';
import prand from 'pure-rand';

const lineStyle:LinePaint = {
	'line-gradient': [
		'interpolate',
		['linear'],
		['line-progress'],
		0,
		'rgba(15, 185, 164, 0)',
		1,
		'rgba(15, 185, 164, 1)',
	],
	'line-opacity': 1,
	'line-width': 10,
};

const Map = ReactMapboxGl({
	accessToken: MAPBOX_ACCESS_TOKEN,
	attributionControl: false,
	antialias: true,
});

function dynamicUnits(measure: number) {
	if (measure < 1000) return measure.toFixed(2) + 'm';
	return (measure / 1000).toFixed(2) + 'km';
}

function WorldMap() {
	const { t } = useTranslation();

	const outOfBoundsRef = useRef<HTMLDivElement>();

	const {
		isMissionsLoaded,
		currentPlace,
		seenModal,
		activeMission,
		mapRef,
		mapViewport,
		center,
		playerPosition,
		places,
		placeInProximity,
		linePoints,
		showMissions,
		missionsListRef,
		rewardsPanelData,
		showGeolocationError,
		seenFirstMission,
		regionData,
		distanceFromNextPlace,
		isOutOfBounds,
		nextPlace,
		setSeenModal,
		setCurrentPlace,
		onMove,
		setMapViewport,
		onClickShowMissions,
		onClickCloseMissions,
		onClickCenter,
		onClickOutOfBounds,
		setRewardsPanelData,
	} = useWorldMap();

	const { AvatarUrl } = usePlayer();

	// const mapStyle = 'mapbox://styles/mapbox/streets-v12';
	// const mapStyle = 'mapbox://styles/mapbox/standard';
	const mapStyle = 'mapbox://styles/maximedesroches/clxc792sn02m101qo4ny35mvv';

	let missionListLabel = null;

	const rng = prand.xoroshiro128plus(nextPlace?.coordinates.lat || 0);
	const start = nextPlace?.coordinates || { lat: 0, lon: 0 };
	const [offLat, rng2] = prand.uniformIntDistribution(0, 200, rng);
	const [offLon] = prand.uniformIntDistribution(0, 200, rng2);
	const degreeToMeters = 111139;
	
	const MAX_RANGE = 300;
	const startPt = point([
		_map(constrain(distanceFromNextPlace || 0, 0, MAX_RANGE), 0, MAX_RANGE, start.lon, start.lon + (offLon - 50) / degreeToMeters),
		_map(constrain(distanceFromNextPlace || 0, 0, MAX_RANGE), 0, MAX_RANGE, start.lat, start.lat + (offLat - 50) / degreeToMeters),
	]);


	if (activeMission && !seenFirstMission) missionListLabel = t('world_map.first_time_show_clue_label');
	if (activeMission && seenFirstMission) missionListLabel = `<small>${activeMission.playfab.DisplayName}</small><br/>` + t('world_map.show_clue_label');
	if (!activeMission) missionListLabel = t('world_map.select_mission_label');
	
	return isMissionsLoaded && <div className="world-map">
		{rewardsPanelData && <RewardsPanel {...rewardsPanelData} onClose={() => setRewardsPanelData(null)} />}
		{!seenModal && activeMission && <IntroModal onClose={() => setSeenModal(true)} />}
		<Map
			ref={m => mapRef.current = m?.state?.map as mapboxgl.Map}
			style={mapStyle}
			zoom={mapViewport.zoom} // starting zoom
			pitch={[62]} // starting pitch
			bearing={[-20]} // starting bearing
			center={center}
			onClick={() => setCurrentPlace(null)}
			onMove={onMove}
			onZoomEnd={(map) => {
				const z = map.getZoom();
				if (map && z) {
					setMapViewport({
						zoom: [z],
					});
				}
			}}
		>
			{playerPosition && <Marker
				coordinates={playerPosition}
				anchor="bottom"
				className="player-position"
			>
				<img src={AvatarUrl || CDN_BASE + 'map/default-avatar.png'} width="225" height="225" />
				{activeMission && <div className="debug-panel">{activeMission && <>{dynamicUnits(distanceFromNextPlace)} from clue</>}</div>}
			</Marker>}

			<>
				{places.map((place, i) => <MapMarker
					isPlayerInVicinity={placeInProximity?.place?.coordinates === place.coordinates}
					key={i}
					coordinates={[place.coordinates.lon, place.coordinates.lat]}
					showPopup={currentPlace === place}
					onClick={() => setCurrentPlace(place)}
					place={place}
					onClaimed={resp => {
						setRewardsPanelData(resp);
					}}
				/>)}
			</>

			{nextPlace && <HelperRadius
				key={`circle-${nextPlace.coordinates.lat}-${nextPlace.coordinates.lon}`}
				center={[startPt.geometry.coordinates[0], startPt.geometry.coordinates[1]]}
				radius={constrain(_map(distanceFromNextPlace, 0, MAX_RANGE, nextPlace.proximityRadius, MAX_RANGE), 0, MAX_RANGE)}
				opacity={constrain(_map(distanceFromNextPlace, 0, MAX_RANGE, 0.5, 0), 0, 0.5)}
			/>}

			{linePoints.length > 1 && <GeoJSONLayer
				data={lineString(linePoints)}
				sourceOptions={{ lineMetrics: true }}
				linePaint={lineStyle}
			/>}

			{regionData && <Radius center={regionData.center} radius={regionData.radius} />}
		</Map>
		{!showMissions && <div className="show-missions-cta" onClick={onClickShowMissions}>
			<SmallChevronIcon />
			<span {...raw(missionListLabel)} />
		</div>}
		<CSSTransition in={showMissions} nodeRef={missionsListRef} timeout={{ appear: 600, enter: 600, exit: 300 }} mountOnEnter unmountOnExit appear>
			<MissionsList onClose={onClickCloseMissions} nodeRef={missionsListRef} />
		</CSSTransition>
		
		<div className={`center-position ${showMissions ? 'offset' : ''}`} onClick={onClickCenter}>
			{!showGeolocationError && <TargetIcon />}
			{showGeolocationError && <WarningIcon />}
		</div>
		{activeMission && <div className='help' onClick={() => {
			setSeenModal(false);
		}}>
			<QuestionMarkIcon />
		</div>}

		<CSSTransition in={isOutOfBounds} nodeRef={outOfBoundsRef} timeout={{ appear: 600, enter: 600, exit: 300 }} mountOnEnter unmountOnExit appear>
			<div onClick={onClickOutOfBounds} className="out-of-bounds" ref={outOfBoundsRef} {...raw(t('world_map.out_of_bounds'))} />
		</CSSTransition>
	</div>;
}

export default WorldMap;