import { combineReducers } from 'redux';
import catalog from './catalog';
import env from './env';
import game from './game';
import globalVariables from './globalVariables';
import inventory from './inventory';
import leaderboard from './leaderboard';
import leaderBoardAroundPlayer from './leaderBoardAroundPlayer';
import missions from './missions';
import notifications from './notifications';
import otherPlayers from './otherPlayers';
import playerData from './playerData';
import playfab from './playfab';
import surveys from './surveys';
import realtime from './realtime';
import statistics from './statistics';
import title_data from './title_data';
import xr_store from './xr_store';
import authProcess from './authProcess';

export const rootReducer = combineReducers({
	catalog: catalog.reducer,
	env: env.reducer,
	game: game.reducer,
	globalVariables: globalVariables.reducer,
	inventory: inventory.reducer,
	leaderboard: leaderboard.reducer,
	leaderBoardAroundPlayer: leaderBoardAroundPlayer.reducer,
	missions: missions.reducer,
	notifications: notifications.reducer,
	otherPlayers: otherPlayers.reducer,
	playerData: playerData.reducer,
	playfab: playfab.reducer,
	surveys: surveys.reducer,
	realtime: realtime.reducer,
	statistics: statistics.reducer,
	titleData: title_data.reducer,
	xrStore: xr_store.reducer,
	authProcess: authProcess.reducer,
});
