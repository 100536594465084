function SmallChevronIcon() {
	return (
		<svg className="small-chevron-icon" xmlns="http://www.w3.org/2000/svg" fill="none" width="9" height="9" viewBox="0 0 9 6">
			<path
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M1.5 4.5l3-3 3 3"
			></path>
		</svg>
	);
}

export default SmallChevronIcon;