import { useTranslation } from 'react-i18next';
import { useSurvey } from '../../hooks/useSurveys';
import Button from './Button';
import { Translation } from '../utils/Translation';
import { CSSProperties } from 'react';

export function PollPreview({ instanceId, index }: { instanceId: string, index?: number }) {
	const { t } = useTranslation();
	const survey = useSurvey(instanceId);
	const question = survey?.questions?.[0];
	const xp = survey?.customData?.xp;

	const title = <Translation id={survey?.customData?.translation_id + '/displayName'} fallback={survey.displayName} />;

	return survey && question && <div key={survey.instanceId} className={`single-poll ${survey.isAnswered ? 'completed' : ''}`} style={{ '--i': index } as CSSProperties}>
		<div className="top">
			<div className="text">{survey.isAnswered ? t('polls.preview.completed') : t('polls.preview.available')}</div>
			<div className="reward">{t('polls.preview.xp_earned', { xp, count: survey.isAnswered ? 1 : 0 })}</div>
		</div>
		<div className="poll-title">{title}</div>
		{!survey.isAnswered && <Button label={t('polls.preview.button_label')} className='thin full-width' onClick={survey.open} />}
	</div>;
}