import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { AppState, setAppState } from '../redux/env';

export default function useAppState() {
	const appState = useAppSelector(state => state.env.appState);
	const dispatch = useAppDispatch();

	return {
		appState,
		setAppState: (state:AppState) => dispatch(setAppState(state)),
	};
}