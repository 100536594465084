import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../redux/config/store';
import { getMissionInventory } from '../redux/missions';
import usePlayer from './usePlayer';
import { missionsSelector } from '../selector/missions';

function getTag(m:IXRMissionItem) {
	const tag = m.playfab?.Tags?.find?.(t => t.startsWith('order'));
	if (tag) return parseInt(tag.replace('order', ''));
	return 1;
}

export default function useMissions(filter:string = null) {
	const missions = useSelector(missionsSelector);
	const { playerId } = usePlayer();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (missions.length === 0 && playerId) {
			dispatch(getMissionInventory());
		}
	}, [dispatch, missions, playerId]);

	const sortedMissions = useMemo(() => {
		return [...missions].sort((a, b) => {
			const aTag = getTag(a);
			const bTag = getTag(b);
			if (aTag < bTag) return -1;
			if (aTag > bTag) return 1;
			return 0;
		});
	}, [missions]);

	if (!filter) return sortedMissions;
	return sortedMissions.filter(mission => mission.type.title === filter);
}